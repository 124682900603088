import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import { getUsers } from '../../../store/actions/backoffice/users';
import { exportContactData, getContactsData } from '../../../store/actions/backoffice/biohazard';
import { BiohazardProps } from '../../../interfaces/Biohazard';
import ContactReportsTab from '../../../components/backoffice/Tabs/Covid/ContactReports/ContactReports';


const ContactReportsTabContainer = (props: BiohazardProps) => {

    useEffect(() => {

        if (!props.users || props.users.length === 0) {
            props.getUsers({ per_page: 10, page: 0 })
        }
    }, [])


    return (
        <div className="h-100">
            <ContactReportsTab
                onSearchUsers={
                    (filter: any) => {
                        props.getUsers({ per_page: 10, page: 0, filters: filter })
                    }
                }
                users={props.users} 
                data={props.contacts} 
                onGetData={props.getContactsData} 
                loading={props.loading} 
                onExportData={(data?: any) => props.onExportData(data)}
                />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getContactsData: (guid: string, startDate: string, endDate: string) => { dispatch(getContactsData(guid, startDate, endDate)) },
    getUsers: (data?:any) => { dispatch(getUsers(data)) },
    onExportData: (data?: any) => { dispatch(exportContactData(data)) },
})

const mapStateToProps = (state: AppState) => ({
    loading: state.biohazard.loading,
    users: state.users.items,
    contacts: state.biohazard.contacts,
    data: state.biohazard.data
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactReportsTabContainer))