import React from 'react';
import Toast from "../components/UI/Toast/Toast"
import { ToastProps } from "../interfaces/Toast"
import { connect } from "react-redux"
import { injectIntl } from 'react-intl';
import { useEffect } from "react";
import { AppState } from '../interfaces/AppState';
import { removeToast } from '../store/actions/toast';

const ToastContainer = (props: ToastProps) => {

    useEffect(() => {

    }, [])


    return (
        <Toast toasts={props.toasts} />
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    // onClose: (id:number) =>  dispatch(removeToast(id))
})

const mapStateToProps = (state: AppState) => ({
    toasts: state.toast.toasts,
    
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ToastContainer))