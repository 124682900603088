import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import './MotivationsModal.scss';
import { Modal, Header, Loader } from 'semantic-ui-react';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button as UIButton } from 'semantic-ui-react';
import { Motivation } from '../../../models/Motivation';


const MotivationsModal = (props: any) => {
    if(localStorage.getItem("DEBUG_MODE") == "true"){
        console.log("MODAL PROPS", props)
    }
    const [newMotivation, setNewMotivation] = useState("")
    const [motivations, setMotivations] = useState(props.motivations)
    const { formatMessage } = props.intl;


    useEffect(() => {
        setMotivations(props.motivations ? props.motivations : [])
    }, [props.motivations])

    const headerName = (field: string) => {
        return formatMessage({ id: "location.motivations." + field })
    }

    const addMotivation = () => {
        let array = [...motivations]
        let tmpMotivation = new Motivation()
        tmpMotivation.text = newMotivation
        // setNewMotivation("")
        props.onUpdateMotivation(tmpMotivation, Motivation.ADD)
    }

    const deleteMotivation = (motivation: Motivation) => {
        props.onUpdateMotivation(motivation, Motivation.TRASH)
    }

    const editMotivation = (motivation: Motivation) => {
        props.onUpdateMotivation(motivation, Motivation.EDIT)
    }

    const onEditorValueChange = (props: any, value: string) => {
        let array = [...motivations]
        for (var i in array) {
            if (array[i].guid === props.rowData.guid) {
                array[i].text = value
                break
            }
        }
        setMotivations(array)
    }

    const cellEditor = (props: any) => {
        return <FormattedMessage id="location.motivations.edit" defaultMessage="Insert new motivation here">
            {
                (placeholder: string) => (
                    <Input elementType="input"
                        fluid
                        placeholder={placeholder}
                        type="text"
                        elementConfig={{
                            onBlur: (e: any) => editMotivation(props.rowData),
                            onKeyPress: (e: any) => {
                                console.log(e.charCode)
                                if (e.charCode === 13) {
                                    editMotivation(props.rowData)
                                }
                            }
                        }}
                        containerClass="ui input w-100"
                        className="my-2 rounded-input w-100 text-center"
                        changed={(event: any) => { onEditorValueChange(props, event.target.value) }}
                        value={props.rowData.text} />
                )
            }
        </FormattedMessage>
    }

    const requiredValidator = (props: any) => {
        let value = props.rowData[props.field];
        return value && value.length > 0;
    }

    const actionTemplate = (rowData: Motivation) => {
        return <UIButton
            className="basic-icon-button"
            basic
            icon={<i className="icon-cv19-trash-outline"></i>}
            loading={rowData.loading}
            onClick={(e: any) => deleteMotivation(rowData)}
        ></UIButton>
    }

    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.onCloseModal()}
            basic
            size='small'
            className="MotivationsModal"
            centered={true}
            closeIcon
        >
            <Header><FormattedMessage id="location.motivations.set" defaultMessage="Set Motivations List" /></Header>
            <Modal.Content>
                {props.loading ?
                    <Loader active inline='centered' />
                    :
                    <div>
                        <FormattedMessage id="location.motivations.new" defaultMessage="Insert new motivation here">
                            {
                                (placeholder: string) => (
                                    <div className="_flex input-container">
                                        <Input elementType="input"
                                            fluid
                                            placeholder={placeholder}
                                            type="text"
                                            containerClass="ui input w-100"
                                            className="my-2 rounded-input w-100 text-center"
                                            changed={(event: any) => { setNewMotivation(event.target.value) }}
                                            value={newMotivation} />
                                        {newMotivation && newMotivation.length > 0 ? <i className="icon-cv19-add input-icon right" onClick={addMotivation}></i> : ""}
                                    </div>
                                )
                            }
                        </FormattedMessage>
                        <DataTable value={motivations} paginator={true} rows={5} autoLayout loading={props.loading} id="motivations-table" className="py-3" >
                            <Column field="text" header={headerName('motivation')} editor={cellEditor} editorValidator={requiredValidator} />
                            <Column body={actionTemplate} header="" style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                    </div>
                }
            </Modal.Content>
            {/* <Modal.Actions>
                <div className="_flex justify-content-between">
                    <a className="download-link" href={props.template} > <i className="icon-cv19-upload mr-3"></i> DOWNLOAD TEMPLATE</a>
                    <div className="_flex">
                        <Button btntype="detail-bg" clicked={props.onCloseModal} >   <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" /></Button>
                        <Button btntype="green-bg" clicked={onSave} loading={props.importLoading}>   <FormattedMessage id="app.generic.save" defaultMessage="Save" /></Button>
                    </div>
                </div>
            </Modal.Actions> */}
        </Modal>
    )

}

export default injectIntl(MotivationsModal)