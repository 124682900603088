import React from 'react';
import { Button as SemanticUIButton } from 'semantic-ui-react'
import './Button.scss';

const Button = (props: any) => (

    <SemanticUIButton
        disabled={props.disabled}
        className={["Button", props.btntype].join(' ')}
        onClick={props.clicked}
        loading={props.loading ? true : false}
    >
        {props.children}
    </SemanticUIButton>

);

export default Button;