import { BiohazardState } from "../../../interfaces/Biohazard";
import { BiohazardAction } from "../../../models/actions/BiohazardAction";


const defaultstate: BiohazardState = {
  loading: false,
  data:[],
  contacts:[]
};

export default (state = defaultstate, action: BiohazardAction) => {
  switch (action.type) {
    case BiohazardAction.GET_DATA:
      return {
        loading: false,
        data: action.response,
      };
    case BiohazardAction.GET_REPORT_DATA:
      return {
        loading: false,
        contacts: action.response,
      };
    case BiohazardAction.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BiohazardAction.REQUEST_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
