import { Action } from "./Action";
import { LOCAL_STORAGE_CASL_KEY, LOCAL_STORAGE_SWITCH_KEY } from "../../store/localStorage";

export class AppAction extends Action {
  static SWITCH_APP_REQUEST = "SWITCH_APP_REQUEST";
  static SWITCH_APP_SUCCESS = "SWITCH_APP_SUCCESS";
  static SET_CASL_STATUS_SUCCESS = "SET_CASL_STATUS_SUCCESS";
  static SWITCH_TAB_REQUEST = "SWITCH_TAB_REQUEST";
  static SWITCH_TAB_SUCCESS = "SWITCH_TAB_SUCCESS";


  constructor(type: string, response: any) {
    super(type, response);
    this.response = response;
  }

  static SwitchAppRequest = (app: string) => {
    return new AppAction(AppAction.SWITCH_APP_REQUEST, app).onRequest();
  };

  static SwitchAppSuccess = (app: string) => {
    localStorage.setItem(LOCAL_STORAGE_SWITCH_KEY, app);
    return new AppAction(AppAction.SWITCH_APP_SUCCESS, app).onRequest();
  };

  static SetCaslStatusSuccess = (initialized: boolean) => {
    // localStorage.setItem(LOCAL_STORAGE_CASL_KEY, `${initialized}`);
    return new AppAction(AppAction.SET_CASL_STATUS_SUCCESS, initialized).onRequest();
  }

  static SwitchTabRequest = () => {
    return new AppAction(AppAction.SWITCH_TAB_REQUEST, null).onRequest();
  };

  static SwitchTabSuccess = () => {
    return new AppAction(AppAction.SWITCH_TAB_SUCCESS, null).onRequest();
  };

}
