import { Action } from "./Action";

export class LogAction extends Action {
  static ON_GENERIC_LOG_REQUEST: string = "ON_GENERIC_LOG_REQUEST";
  static ON_GENERIC_LOG_SUCCESS: string = "ON_GENERIC_LOG_SUCCESS";
  static ON_GENERIC_LOG_ERROR: string = "ON_GENERIC_LOG_ERROR";
  static ON_GENERIC_EXPORT_LOG_SUCCESS: string = "ON_GENERIC_EXPORT_LOG_SUCCESS";

  constructor(type: string, response: any) {
    super(type, response);
    this.response = response;
  }

  static OnGenericRequest = () => {
    return new LogAction(LogAction.ON_GENERIC_LOG_REQUEST, {}).toJSON();
  };

  static OnGenericExportSuccess = (data?: any) => {
    
    return new LogAction(LogAction.ON_GENERIC_EXPORT_LOG_SUCCESS, data).toJSON();
  };

  static OnGenericSuccess = (data?: any) => {
    
    return new LogAction(LogAction.ON_GENERIC_LOG_SUCCESS, data).toJSON();
  };

  static OnGenericError = () => {
    return new LogAction(LogAction.ON_GENERIC_LOG_ERROR, {}).toJSON();
  };
}
