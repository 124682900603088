import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const PublicRoute = (props: any) => {
    console.log("PROPS ROUTE PUBLIC", props)
    const RouteComponent = props.component
    const RouteProps = RouteComponent.props
    return <Route
        {...props}
        {...props.rest}
        exact={props.exact}
        component={(childProps: any) =>
            props.isUserAuthenticated ? (
                <Redirect to={props.redirectTo} />
            ) : (
                    <RouteComponent {...RouteProps} />
                )
        }
    />
};

const mapStateToProps = (state: any) => ({
    isUserAuthenticated: state.authReducer.isUserAuthenticated,
});

export default connect(mapStateToProps)(PublicRoute);
