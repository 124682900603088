import React, { useState, useEffect } from 'react'
import { Tab, Loader, Button, Icon, Form } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import { User, IsModeratorOfApp } from '../../../../../models/User';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { ReservationStatusClasses, ReservationStatusNames, ReservationStatusIcons, Reservation } from '../../../../../models/Reservation';
import { GetDateAndTimeByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IntlContext } from '../../../../../utils/IntlContext';
import './Reservations.scss';
import ReservationModal from './ReservationModal/ReservationModal';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Common } from '../../../../../utils/Common';
import { filter } from 'lodash';

const ReservationsTab = (props: any) => {
    const { formatMessage } = props.intl;
    //const [perPage, setPerPage ] = useState(10)
    const { perPage } = props
    const { locale, calendar } = React.useContext(IntlContext);
    const [modalOpen, setModalOpen] = useState(false)
    const [table, setTable] = useState<DataTable | undefined>()
    const [selected, setSelected] = useState<User | any>([])
    const moreOptions: any[] = [{ label: 'app.generic.detail', value: 'detail' }, { label: 'app.generic.delete', value: 'delete' }]
    const moderatorMoreOptions: any[] = [
        { label: 'app.generic.approve', value: 'approve' },
        { label: 'app.generic.reject', value: 'reject' },
        { label: 'app.generic.delete', value: 'delete' },
    ]

    const perPageOptions: any[] = [
        { label: '2', value: 2 },
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
    ]

    // const [guid, setGuid] = useState<Reservation | any>()
    const [reservation, setReservation] = useState<Reservation | any>()

    const [selectedStatus, setSelectedStatus] = useState<any>(undefined)
    const [selectedOwner, setSelectedOwner] = useState<any>(undefined)
    const [selectedEntrance, setSelectedEntrance] = useState<any>(undefined)
    const [status, setStatus] = useState([])
    const [firstIndex, setFirstIndex] = useState(0)
    const [order, setOrder] = useState<any>()
    const [dialogVisible, setDialogVisible] = useState<any>(false)

    const [orderBy, setOrderBy] = useState<any>()
    const [filters, setFilters] = useState<any>({})
    const [startDate, setStartDate] = useState<any>(new Date(new Date().setMonth(new Date().getMonth() - 3)))
    const [endDate, setEndDate] = useState<any>(new Date())


    useEffect(() => {

        if (props.tabChange) {
            setOrderBy(undefined)
            setFilters({})
            setOrder(undefined)
        }

        if (props.closeModal === true) {
            setModalOpen(false)
        }


        if (status.length === 0) {
            let array: any = []
            for (var i in ReservationStatusNames) {
                let id = parseInt(i)
                array.push({ label: formatMessage({ id: ReservationStatusNames[i] }), value: id })
            }

            setStatus(array)
        }

        if (props.bookingState && props.bookingState.list) {
            setSelected([])
        }

    }, [props.closeModal, props.bookingState?.list])

    useEffect(() => {
        if (filters && !Object.keys(filters).length)
            props.onGetReservations({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy})

    }, [filters])

    useEffect(() => {
        if (startDate > endDate) {
            setEndDate(startDate)
        }
    }, [startDate])

    useEffect(() => {
        if (!props.loading_export)
            setDialogVisible(false)
    }, [props.loading_export])

    const onExport = () => {
        filters.startDate = { value: moment(startDate).format('YYYY-MM-DD').split("T")[0] };
        filters.endDate = { value: moment(endDate).format('YYYY-MM-DD').split("T")[0] };
        props.onExportData({ page: (firstIndex / perPage) + 1, per_page: perPage, orderBy: orderBy, filters: filters });
        //console.log("[TABLE] ", table);
        //console.log("[TABLE VALUES] ", table?.props.value);
        //table?.exportCSV();

        // console.log("[TABLE ROW] ", table?.props.rows);
        // console.log("[TABLE VALUES] ", table?.props.value);
        // console.log("[TABLE TOTAL VAL] ", table?.props.totalRecords);
    }



    const onSeeDetail = (rowData: Reservation) => {
        // setGuid(rowData.guid)
        setReservation(rowData)
        // props.onGetReservation(rowData.guid)
        setModalOpen(true)
    }


    const onConfirmReservation = (rowData: Reservation) => {
        // setGuid(rowData.guid)
        // setReservation(rowData)
        // props.onGetReservation(rowData.guid)
        // setModalOpen(true)
    }

    const setStartDateInfo = (e: any) => {

        let tmp: any = e.value
        setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])


    }

    const setEndDateInfo = (e: any) => {


        let tmp2: any = e.value
        setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])


    }



    const moreOptionsTemplate = (option: any) => {
        return (
            <FormattedMessage id={option.label} defaultMessage={option.label} />
        )
    }



    const nameTemplate = (rowData: any, column: any) => {
        if (rowData[column.field]) {
            return rowData[column.field].name + ' ' + rowData[column.field].surname
        } else if (column.field === 'owner') {
            return rowData.booker.name + ' ' + rowData.booker.surname
        }
        return ""
    }

    const statusTemplate = (rowData: any, column: any) => {
        return <div className="circle-wrapper">
            <div className={`circle ${ReservationStatusClasses[rowData[column.field]]}`}>
                <div className="inner-circle ">
                    <i className={`${ReservationStatusIcons[rowData[column.field]]}`}></i>
                </div>
            </div>
            <span>{formatMessage({ id: ReservationStatusNames[rowData[column.field]] })}</span>
        </div>

    }


    const dateTemplate = (rowData: any, column: any) => {
        if (!rowData || !rowData[column.field]) return ''
        return GetDateAndTimeByLocale(locale, rowData[column.field])

    }

    const actionTemplate = (rowData: any, column: any) => {
        return <div>
            {rowData.loading ?
                <Loader active inline size="mini" />
                :
                <FormattedMessage id="app.generic.more" defaultMessage="More">
                    {(placeholder: string) => (
                        <PrimeDropdown options={props.isModerator ? moderatorMoreOptions : moreOptions} placeholder={placeholder}
                            onChange={
                                (e: any) => {
                                    if (e.value === 'detail') {
                                        onSeeDetail(rowData)
                                    } else if (e.value === 'delete') {
                                        props.onDeleteReservation(rowData, { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })
                                    } else if (e.value === 'approve') {
                                        props.onApproveReservations([rowData], { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })
                                    } else if (e.value === 'reject') {
                                        props.onRejectReservations([rowData], { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })
                                    }
                                }
                            }
                            appendTo={document.body}
                            itemTemplate={moreOptionsTemplate}

                        />
                    )}
                </FormattedMessage>


            }
        </div>;
    }


    const getTextName = (field: string, path: string[]) => {
        let id = ""
        for (var i in path) {
            id += path[i] + "."
        }
        return formatMessage({ id: id + field })
    }

    const onStatusFilter = (event: any) => {
        setSelectedStatus(event.value)
        let _filters: any = { ...filters }
        if (!_filters) {
            _filters = {}
        }
        if (event.value !== null) {
            _filters.status_id = {
                value: event.value + ""
            }
        } else {
            delete _filters['status_id']
        }
        onFilter({ filters: _filters })
    }

    const onEntranceFilter = (event: any) => {

        setSelectedEntrance(event.value)
        let _filters: any = { ...filters }
        if (!_filters) {
            _filters = {}
        }

        if (event.value !== null) {
            let filter = moment(event.value, 'YYYY-MM-DD').format().split("T")[0]


            _filters.booking_datetime_start = {
                value: filter
            }
        } else {
            delete _filters['booking_datetime_start']
        }
        onFilter({ filters: _filters })

    }

    const statusFilter = <PrimeDropdown style={{ width: '100%' }} placeholder={getTextName('status_id', ['reservations', 'filters'])} value={selectedStatus} options={status} onChange={onStatusFilter} showClear appendTo={document.body} />;

    const calendarFilter =
        <Calendar inputStyle={{ width: '100%', borderRadius: '0px' }} selectionMode="single" placeholder={getTextName('entrance_time', ['reservations', 'filters'])} style={{ width: '100%' }} value={selectedEntrance} onChange={onEntranceFilter} appendTo={document.body} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />


    const onPage = (event: any) => {
        setFirstIndex(event.first)
        props.onGetReservations({ page: (event.first / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })

    }
    const onSort = (event: any) => {
        setOrder(event.sortOrder)
        setOrderBy(event.sortField)

        props.onGetReservations({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: filters })
    }
    const onFilter = (event: any) => {
        setFilters(event.filters)

        props.onGetReservations({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: event.filters })
    }

    const footerContent = (
        <Form.Group className='formGroupButton' widths='equal' >
            <Form.Button onClick={() => setDialogVisible(false)} className="backButton">Annulla</Form.Button>
            <Form.Button onClick={onExport} loading={props.loading_export} className="confirmButton"  >Download</Form.Button>


        </Form.Group>
    );


    return (<div className="_flex h-100">
        <Tab.Pane className="Reservations">
            <div>
                {(Common.IsMobile() || Common.CheckMobileUrl()) ?
                    <Dialog header="Scarica storico prenotazione" visible={dialogVisible} onHide={() => setDialogVisible(false)}
                        footer={footerContent}>
                        <p className="m-0">
                            Selezionare il range temporale di cui si vuole estrarre lo storico.
                        </p>
                        <Form className='formStyle'>
                            <Form.Group className='formGroup' widths='equal'>
                                <Form.Field label='Da:'
                                    control='calendar'
                                >
                                    <Calendar  value={startDate} onChange={(e) => setStartDate(e.value)} showIcon locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />
                                </Form.Field>
                                <Form.Field label='A:'
                                    control='calendar'
                                >
                                    <Calendar minDate={startDate} value={endDate} onChange={(e) => setEndDate(e.value)}  showIcon locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />

                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Dialog>
                    :
                    <Dialog header="Scarica storico prenotazione" visible={dialogVisible} onHide={() => setDialogVisible(false)}
                        style={{ width: '50vw' }} footer={footerContent}>
                        <p className="m-0">
                            Selezionare il range temporale di cui si vuole estrarre lo storico.
                        </p>
                        <Form className='formStyleDialog'>
                            <Form.Group className='formGroup' widths='equal'>
                                <Form.Field label='Da:'
                                    control='calendar'
                                >
                                    <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} showIcon locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />
                                </Form.Field>
                                <Form.Field label='A:'
                                    control='calendar'
                                >
                                    <Calendar value={endDate} minDate={startDate} onChange={(e) => setEndDate(e.value)} showIcon locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />

                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Dialog>
                }
                <div className="_flex flex-direction-row justify-content-between">

                    <h3 className="_flex"><FormattedMessage id="reservations.title" defaultMessage="Reservations" /></h3>
                    <div className="_flex justify-content-end align-items-center">
                        {
                            props.isModerator ?
                                <PrimeDropdown
                                    style={{ margin: '2px' }}
                                    placeholder="10"
                                    value={perPage}
                                    options={perPageOptions}
                                    appendTo={document.body}
                                    onChange={(e: any) => {
                                        props.updatePerPage(e.value)
                                    }}
                                />
                                :
                                ""
                        }
                        {
                            selected.length > 0 && props.isModerator ?
                                <Button
                                    onClick={
                                        (e: any) => props.onApproveReservations(selected, { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })}
                                    basic
                                    loading={props.bookingState?.loading}>
                                    <FormattedMessage id="app.generic.approve" defaultMessage="Approve" />
                                </Button>
                                :
                                ""
                        }
                        {
                            selected.length > 0 && props.isModerator ?
                                <Button
                                    onClick={(e: any) => props.onRejectReservations(selected, { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })}
                                    basic
                                    loading={props.bookingState?.loading}>
                                    <FormattedMessage id="app.generic.reject" defaultMessage="Reject" />
                                </Button>
                                :
                                ""
                        }

                        <Button onClick={(e: any) => setDialogVisible(true)} loading={props.loading_export}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></Button>

                        <Button onClick={(e: any) => props.onGetReservations({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })} basic loading={props.loading}><Icon className='iconReservation' name='redo alternate' /></Button>
                        <Button onClick={(e: any) => setFilters({})} loading={props.loading_export} basic><Icon className='iconReservation' name='window close outline' /></Button>

                    </div>
                </div>

                <DataTable
                    value={props.bookingState?.list}
                    totalRecords={props.bookingState?.total_items}
                    paginator={true}
                    rows={perPage}
                    autoLayout
                    loading={props.loading}
                    className="mt-3"
                    ref={(el: any) => { setTable(el); }}
                    selection={selected}
                    onSelectionChange={e => { console.log('selection', e); setSelected(e.value) }}
                    lazy={true}
                    first={firstIndex}
                    onPage={onPage}
                    sortField={orderBy}
                    sortOrder={order}
                    onSort={onSort}
                    filters={filters}
                    onFilter={onFilter}
                >
                    <Column selectionMode="multiple" style={{ width: '2em' }} />
                    <Column
                        field="status_id"
                        body={statusTemplate}
                        header={getTextName('status', ['reservations'])}
                        filter={true}
                        filterElement={statusFilter}
                        sortable={true}
                    />
                    <Column
                        field="booker_name"
                        header={getTextName('booker', ['reservations'])}
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        filterPlaceholder={getTextName('user', ['reservations', 'filters'])}
                    />
                    <Column
                        field="user_name"
                        header={getTextName('user', ['reservations'])}
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        filterPlaceholder={getTextName('user', ['reservations', 'filters'])}
                    />
                    <Column
                        field="location_name"
                        header={getTextName('location', ['reservations'])}
                        sortable={true}
                        filter={true}
                        filterPlaceholder={getTextName('location_name', ['reservations', 'filters'])}
                    />
                    <Column
                        field="desk_name"
                        header={getTextName('desk', ['reservations'])}
                        sortable={true}
                        filter={true}
                        filterPlaceholder={getTextName('desk_name', ['reservations', 'filters'])}
                    />
                    <Column
                        field="entrance_time"
                        body={dateTemplate}
                        header={getTextName('entrance_time', ['reservations'])}
                        filter={true}
                        filterElement={calendarFilter}
                        sortable={true}
                    />
                    <Column
                        field="created_at"
                        body={dateTemplate}
                        header={getTextName('created_at', ['app', 'generic'])}
                    // filter={true}
                    // filterElement={calendarFilter}
                    // sortable={true}
                    />
                    <Column
                        body={actionTemplate}
                        header={getTextName('actions', ['app', 'generic'])}
                        style={{ textAlign: 'center', width: '8em' }}
                    />
                </DataTable>

            </div>
            {

                <ReservationModal
                    modalOpen={modalOpen}
                    reservation={reservation}
                    authState={props.authState}
                    bookingState={props.bookingState}
                    usersState={props.usersState}
                    settings={props.settings}
                    isModerator={props.isModerator}
                    selectedNode={{ guid: reservation?.location_guid }}
                    onGetEntrance={props.onGetEntrance}
                    onGetMotivations={props.onGetMotivations}
                    onGetReservation={props.onGetReservation}
                    onGetUsers={props.onGetUsers}
                    onGetTimetables={props.onGetTimetables}
                    onBooking={(reservation: Reservation) => { props.onBookingUpdate(reservation, { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters }) }}
                    onCloseModal={() => { setModalOpen(false) }}
                    onError={props.onError}

                />
            }

        </Tab.Pane >
    </div>
    )
}

export default injectIntl(ReservationsTab)