export class Import {
  static StatusNames: any = {
    12: "import.status.preparation",
    13: "import.status.active",
    14: "import.status.success",
    15: "import.status.error",
  };

  static StatusCodes: any = {
    IN_PREPARATION: 12,
    ACTIVE: 13,
    TASK_SUCCESS: 14,
    TASK_FAILED: 15,
  };
}
