import { LocationState } from "../../../interfaces/Location";
import {
  REQUEST_LOCATION,
  GET_LOCATION,
  SET_TREE_DATA,
  ON_NODE_CHANGE,
  REQUEST_FAILED,
  ON_NODE_CHANGE_ERROR,
  ON_GET_TIMEZONES_REQUEST,
  ON_GET_TIMEZONES_SUCCESS,
  ON_GENERIC_ERROR,
  GET_AVAILABLE_LOCATION,
  GET_PARENT_LOCATION,
  REQUEST_PARENT_LOCATION,
  REQUEST_AVAILABLE_LOCATION

} from "../../actions/backoffice/location";
import { LocationAction } from "../../../models/actions/LocationAction";

const defaultstate: LocationState = {
  timezones: [],
  loadingTimezones: false,
  loading: false,
  treeData: [],
  list: [],
  closeModal: false,

};

  export default (state = defaultstate, action: LocationAction) => {
  switch (action.type) {
    case REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        closeModal: false,
      };
    case REQUEST_LOCATION:
      return {
        ...state,
        closeModal: "loadingLocations",
        loading: true,
      };
    case ON_NODE_CHANGE_ERROR:
      return {
        loading: false,
        treeData: action.response,
      };
    case ON_NODE_CHANGE:
      return {
        loading: false,
        treeData: action.response,
      };
    case GET_LOCATION:
      return {
        loading: false,
        treeData: action.response.treeData,
        list: action.response.list,
        closeModal: true,
      };
    case SET_TREE_DATA:
      return {
        loading: false,
        treeData: action.response,
        closeModal: true,
      };
    case ON_GET_TIMEZONES_REQUEST:
      return {
        ...state,
        loadingTimezones: true,
      };
    case ON_GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.response,
        loadingTimezones: false,
      };
    case ON_GENERIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
