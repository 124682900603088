import { isTokenAlive } from "../store/actions/auth"

export const HTTP_STATUS: any = {
    UNAUTHORIZED: 401,
    OK: 200
}



export const Api = {

    appKey: `?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
    headers: {
        'Accept': 'text/plain, application/json',
        'Content-Type': 'application/json',
        'Authorization': '',
        'Origin': '*'
    },

    call: async (method: string, path: string, params?: any, queryParams?: any) => {

        let queryString: string = "&"

        if (path !== '/login') { // TODO Add in or /reset
            let token = ""
            if(await isTokenAlive()){
                let auth: any = localStorage.getItem("auth")
                if (auth) {
                    auth = JSON.parse(auth)
                    token = auth.access_token
                }            
                if(localStorage.getItem("DEBUG_MODE") == "true"){
                    console.log("[TOKEN]", token)
                }
            }
            Api.headers['Authorization'] = 'Bearer ' + token;
        };

        let request: any = {
            method: method,
            headers: Api.headers,
        }

        if (params) {
            request.body = JSON.stringify(params)
        }

        if (queryParams) {
            queryString = Object.keys(queryParams).map((key: any) => key + '=' + queryParams[key]).join('&');
        } else {
            queryString = ""
        }

        return fetch(
            `${process.env.REACT_APP_API_ROOTURL}${path}${Api.appKey}${queryString}`,
            request
        )
            .then(res => {
                return res
            })
            .catch(err => {
                throw err
            })
    },
    Post: (path: string, params: any) => {
        return Api.call('POST', path, params)
    },
    Put: (path: string, params: any) => {
        return Api.call('PUT', path, params)
    },
    Delete: (path: string, params: any) => {
        return Api.call('DELETE', path, params)
    },
    Get: (path: string, params: any) => {
        return Api.call('GET', path, params)
    },
    IsAuthorized: (response: any) => {
        return new Promise((resolve: any, reject: any) => {
            if (response.status === HTTP_STATUS.UNAUTHORIZED) {
                response.text()
                    .then((text: string) => {
                        if(text.toLowerCase().includes('jwt')){
                            text = 'Session expired'
                        }
                        reject(text)
                    })
            } else {
                response.text()
                    .then((text: string) => {
                        resolve(text)
                    })
            }
        })

    },
    UploadFile: (path: string, formData: FormData) => {
        console.log("[API - UploadFile] formData: ", formData);
        let auth: any = localStorage.getItem("auth")
        let token = ""
        if (auth) {
            auth = JSON.parse(auth)
            token = auth.access_token
        }
        console.log("TOKEN", token)
        //Api.headers['Authorization'] = 'Bearer ' + token;
        let uploadFileHeaders = {
            'Authorization': 'Bearer ' + token
            // content-type header should not be specified!
        }
        let request: any = {
            method: "POST",
            headers: uploadFileHeaders, //Api.headers,
            body: formData
        }

        return fetch(
            `${process.env.REACT_APP_API_ROOTURL}${path}${Api.appKey}`,
            request
        )

    }

}