import { Action } from "./Action";

export class CheckInAction extends Action {
    static ON_GENERIC_CHECKIN_REQUEST: string = "ON_GENERIC_CHECKIN_REQUEST";
    static ON_GENERIC_SUCCESS: string = "ON_GENERIC_SUCCESS";
    static ON_GENERIC_CHECKIN_SUCCESS: string = "ON_GENERIC_CHECKIN_SUCCESS";
    static ON_GENERIC_CHECKIN_ERROR: string = "ON_GENERIC_CHECKIN_ERROR";


    constructor(type: string, response: any) {
        super(type, response);
        this.response = response;
      }

      static OnGenericRequest = () => {
        return new CheckInAction(
          CheckInAction.ON_GENERIC_CHECKIN_REQUEST,
          {}
        ).toJSON();
      };


      static OnGetCheckInSuccess = (data: any) => {
        return new CheckInAction(
          CheckInAction.ON_GENERIC_CHECKIN_SUCCESS,
          data
        ).toJSON();
      };

      static OnGenericSuccess = (data?: any) => {
        return new CheckInAction(
          CheckInAction.ON_GENERIC_SUCCESS,
          data
        ).toJSON();
      };

      static OnGenericError = () => {
        return new CheckInAction(
          CheckInAction.ON_GENERIC_CHECKIN_ERROR,
          {}
        ).toJSON();
      };

}