import * as SortableTreeUtils from "react-sortable-tree/";
import { Location } from "../models/Location";
import { Item } from "semantic-ui-react";

export class LocationUtils {
  static AddChild = (newNode: any, treeData: any, parentKey: any) => {
    console.log("TREE", treeData);
    return SortableTreeUtils.addNodeUnderParent({
      treeData: treeData,
      newNode: newNode,
      parentKey: parentKey,
      getNodeKey: (item: any) => {
        return item.node.guid;
      },
      ignoreCollapsed: false,
      expandParent: true,
      addAsFirstChild: true,
    });
  };

  static AddItem = (
    newNode: any,
    treeData: any,
    depth: number,
    minimumTreeIndex: number
  ) => {
    console.log("TREE", treeData);
    return SortableTreeUtils.insertNode({
      treeData: treeData,
      depth: depth,
      newNode: newNode,
      minimumTreeIndex: minimumTreeIndex,
      ignoreCollapsed: false,
      expandParent: true,
      getNodeKey: (item: any) => {
        return item.node.guid;
      },
    });
  };

  static GetTreeDataFromFlatData = (flatData: Location[]) => {
    let data: Location[] = [];
    for (var i in flatData) {
      data.push(new Location(flatData[i]));
    }
    
    let treeData = SortableTreeUtils.getTreeFromFlatData({
      flatData: data,
      getKey: (item) => {
        return item.guid;
      },
      getParentKey: (item) => {
        return item.parent_location_guid;
      },
      rootKey: "0",
    });
   

    if(localStorage.getItem("DEBUG_MODE") == "true"){
      console.log(treeData);
    }
    return treeData;
  };

  static GetPrintableQrData = (treeData: any) => {
    console.log("PRINT", treeData)
    let data = SortableTreeUtils.getFlatDataFromTree({
      treeData: treeData,
      ignoreCollapsed: false,
      getNodeKey: (item: any) => {
        return item.node.guid;
      },
    });

    let locations: any[] = [];
    for (var i in data) {
      let node = data[i].node;
      locations.push({guid: node.guid, name: node.name});
    }
    console.log("DATA", locations);
    return locations;
  };

  static SetNode = (
    treeData: any[],
    node: Location,
    loading?: boolean,
    path?: any
  ) => {
    let newData: any[] = [];
    // let realPath = []
    // if (!path) {
    //   path = [0];
    // } else {
    //   realPath = path
    // }
    if (node) {
      node.loading = loading ? loading : false;
    }

    // node.subtitle  = "<span><b>Risk: </b></span>" + node.risk_percentage + "%";
    // newData = JSON.parse(JSON.stringify(treeData));
    
    newData = changeNodeAtPath({
      treeData: treeData,
      newNode: node,
      getNodeKey: ({ node, treeIndex }: any) => {
        console.log("ITEM", node.guid);
        if (typeof path[path.length - 1] === typeof "") {
          return node.guid;
        } else {
          return treeIndex;
        }
      },
      path: path,
      ignoreCollapsed: true,
    });
    
    return newData; 
  };

  static RemoveNode = (treeData: any[], path: any) => {
    let newData: any = [];
    newData = SortableTreeUtils.removeNodeAtPath({
      treeData: treeData,
      path: path,
      getNodeKey: ({ node, treeIndex }: any) => {
        // console.log("ITEM", item)
        return treeIndex;
      },
      ignoreCollapsed: true,
    });
    return newData;
  };

  static FindNodes = (treeData: any[], query: string, field: string) => {
    SortableTreeUtils.find({
      getNodeKey: (item: any) => {
        return item.node.guid;
      },
      treeData: treeData,
      searchMethod: (data: SortableTreeUtils.SearchData) => {
        // console.log("DATAT", data)
        return true;
      },
    });
  };
  static FindNodesForUpdate = (treeData: any[]) => {
    let result = SortableTreeUtils.find({
      getNodeKey: (item: any) => {
        return item.node.guid;
      },
      treeData: treeData,
      searchMethod: (data: SortableTreeUtils.SearchData) => {
        // console.log("DATAT", data)
        let bool: boolean =
          data.node.guid.includes("TEMPORARY_LOCATION_") && !data.node.loading;

        return bool;
      },
    });
    // console.log("SEARCH RES", result)
    return result;
  };
}

export class LUActions {
  static PRINT_ALL: string = "PRINT_ALL";
  static PRINT: string = "PRINT";
  static ADD: string = "ADD";
  static ADD_CHILD: string = "ADD_CHILD";
  static DELETE: string = "DELETE";
  static UPDATE: string = "UPDATE";
  static DISABLE: string = "DISABLE";
  static ENABLE: string = "ENABLE";
}

export function changeNodeAtPath({
  treeData,
  path,
  newNode,
  getNodeKey,
  ignoreCollapsed = true,
}: any) {
  const RESULT_MISS = "RESULT_MISS";
  const traverse = ({
    isPseudoRoot = false,
    node,
    currentTreeIndex,
    pathIndex,
  }: any) => {
    console.log(path);
    console.log(
      "CONDITION",
      getNodeKey({ node, treeIndex: currentTreeIndex }),
      path[pathIndex]
    );
    if (
      !isPseudoRoot &&
      getNodeKey({ node, treeIndex: currentTreeIndex }) !== path[pathIndex]
    ) {
      return RESULT_MISS;
    }

    if (pathIndex >= path.length - 1) {
      // If this is the final location in the path, return its changed form
      return typeof newNode === "function"
        ? newNode({ node, treeIndex: currentTreeIndex })
        : newNode;
    }
    if (!node.children) {
      // If this node is part of the path, but has no children, return the unchanged node
      throw new Error("Path referenced children of node with no children.");
    }

    let nextTreeIndex = currentTreeIndex + 1;
    for (let i = 0; i < node.children.length; i += 1) {
      const result: any = traverse({
        node: node.children[i],
        currentTreeIndex: nextTreeIndex,
        pathIndex: pathIndex + 1,
      });

      // If the result went down the correct path
      if (result !== RESULT_MISS) {
        if (result) {
          // If the result was truthy (in this case, an object),
          //  pass it to the next level of recursion up
          return {
            ...node,
            children: [
              ...node.children.slice(0, i),
              result,
              ...node.children.slice(i + 1),
            ],
          };
        }
        // If the result was falsy (returned from the newNode function), then
        //  delete the node from the array.
        return {
          ...node,
          children: [
            ...node.children.slice(0, i),
            ...node.children.slice(i + 1),
          ],
        };
      }

      nextTreeIndex +=
        1 +
        SortableTreeUtils.getDescendantCount({
          node: node.children[i],
          ignoreCollapsed,
        });
    }

    return RESULT_MISS;
  };

  // Use a pseudo-root node in the beginning traversal
  const result = traverse({
    node: { children: treeData },
    currentTreeIndex: -1,
    pathIndex: -1,
    isPseudoRoot: true,
  });

  if (result === RESULT_MISS) {
    throw new Error("No node found at the given path.");
  }

  return result.children;
}
