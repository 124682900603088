import { CheckInState } from "../../../interfaces/CheckInState";
import { CheckInAction } from "../../../models/actions/CheckInAction";

const defaultstate: CheckInState = {
    loading: false,
    list: [],
    total_items: 0,
  };

  export default (state = defaultstate, action: CheckInAction) => {
    switch(action.type){
      case CheckInAction.ON_GENERIC_CHECKIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
        case CheckInAction.ON_GENERIC_CHECKIN_SUCCESS:
          return {
            ...state,
            loading: false,
            list: action.response.items,
            total_items: action.response.total_items,
          };
          case CheckInAction.ON_GENERIC_CHECKIN_ERROR:
            return {
              ...state,
              loading: false,
            };
          case CheckInAction.ON_GENERIC_SUCCESS:
            return {
              ...state,
              loading: false,
            };
        default:
            return state;
    }
  }