import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Home from '../../components/backoffice/Tabs/Common/Home/Home';
import { AppState } from '../../interfaces/AppState';
import { switchTabRequest } from '../../store/actions/backoffice/app';

 

const HomeContainer = (props: any) => {



  return (
    <div
      className="Home _flex flex-1 flex-direction-column h-100 justify-content-center align-items-center"
    >
      <Home activeApp={props.activeApp} caslInitialized={props.caslInitialized} onSwitchTab={() => props.switchTab()} auth={props.auth}/>

    </div>
  );
}


const mapStateToProps = (state: AppState) => ({
  activeApp: state.app.activeApp,
  caslInitialized: state.app.caslInitialized,
  auth: state.authReducer.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  switchTab: () => dispatch(switchTabRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HomeContainer));
