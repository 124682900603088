import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import { getUsers } from '../../../store/actions/backoffice/users';
import BiohazardTab from '../../../components/backoffice/Tabs/Covid/Biohazard/Biohazard';
import { getBiohazardData, exportData } from '../../../store/actions/backoffice/biohazard';
import { BiohazardProps } from '../../../interfaces/Biohazard';
 

const BiohazardTabContainer = (props: BiohazardProps) => {

    useEffect(() => {

        if (!props.users || props.users.length === 0) {
            props.getUsers({ per_page: 10, page: 0 })
        }
    }, [])

    const onExport = () => {
    }

    const onReload = () => {

    }

    return (
        <div className="h-100">
            <BiohazardTab
                onSearchUsers={
                    (filter: any) => {
                        props.getUsers({ per_page: 10, page: 0, filters: filter })
                    }
                }
                users={props.users} 
                data={props.data} 
                onExportData={(data?: any) => props.onExportData(data)}
                onGetData={props.getBiohazardData} 
                loading={props.loading} />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getBiohazardData: (guid: string, startDate: string, endDate: string) => { dispatch(getBiohazardData(guid, startDate, endDate)) },
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    onExportData: (data?: any) => { dispatch(exportData(data)) },

})

const mapStateToProps = (state: AppState) => ({
    loading: state.biohazard.loading,
    users: state.users.items,
    data: state.biohazard.data
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BiohazardTabContainer))