import { BaseModel } from "./Base";

export let id = 0;

export class Toast extends BaseModel {
  text: string;
  type: "success" | "info" | "warn" | "error" | undefined;
  title: string;
  id: number;
  toBeLocalized: boolean;
  sticky: boolean;

  constructor(text: string) {
    super();
    this.type = "success";
    this.text = text;
    this.title = "";
    this.id = id++;
    this.toBeLocalized = true;
    this.sticky = false
  }
  info = () => {
    this.type = "info";
    this.title = "app.generic.info";
    return this;
  };

  success = () => {
    this.type = "success";
    this.title = "app.generic.success";
    return this;
  };

  error = () => {
    this.type = "error";
    this.title = "app.generic.error";
    return this;
  };

  notLocalized = () => {
    this.toBeLocalized = false;
    return this;
  };
}

export class ToastType {
  static ERROR: string = "error";
  static SUCCESS: string = "success";
}
