import { HTTP_STATUS, Api } from "../../../api/Api";
import { AddError } from "../toast";
import { CommonApi } from "../../../api/CommonApi";
import { AppState } from "../../../interfaces/AppState";
import { startLogout } from "../auth";
import { LogAction } from "../../../models/actions/LogAction";
import { BookingAction } from "../../../models/actions/BookingAction";



export const exportData = (params:any) => {
  
  console.log("[EXPORT LOG]" , params );
  //let [isModerator, data] = params;
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnExportRequest());
    return CommonApi.GetLogsAuditReport(params)
      .then((response:any) => {
        console.log("[RES]", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res) {
          console.log("[EXPORT LOG SUCCESS]", res);
          dispatch(BookingAction.OnExportSuccess());
          dispatch(LogAction.OnGenericExportSuccess(res));
        }
      })
      .catch((err:any) => {
        console.log(err);
        dispatch(LogAction.OnGenericError());
        dispatch(BookingAction.OnExportSuccess());
        dispatch(AddError(err));
      }); 
  }
}

export function getLogs(...params: any) {
  return (dispatch: any, getState: any) => {
    let [created_at_start, created_at_end, page, perPage] = params
    let appState = (getState() as AppState).app;
    let authState = (getState() as AppState).authReducer;
    dispatch(LogAction.OnGenericRequest());
    
    return CommonApi.GetLogs({
      created_at_start: created_at_start,
      created_at_end: created_at_end,
      page: page,
      per_page: perPage,
      company_code: authState.auth?.selectedCompany
    })
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res) {
          console.log(res);
          // dispatch(AddSuccess("app.generic.successful"));
          dispatch(LogAction.OnGenericSuccess(res));
        }
      })
      .catch((err:any) => {
        console.log(err);
        dispatch(LogAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
}
