import { Api } from "./Api";
import { UserApi } from "./UserApi";
import { LocationApi } from "./LocationsApi";
import { User } from "../models/User";
import { Storage } from "@google-cloud/storage";

export class CommonApi {
  static LOG_ENDPOINT = "/audit/list";
  static EV_REPORTS_ENDPOINT = "/event/report";
  static LOC_REPORTS_ENDPOINT = "/location/report";
  static USERS_ENDPOINT = UserApi.BASE_ENDP + "/upload_full";
  static LOCATIONS_ENDPOINT = LocationApi.BASE_ENDP + "/upload_full";

  static LOG_REPORTS_ENDPOINT = "/audit/export";
  static CONTACT_REPORTS_ENDPOINT = "/contagion/contact/export";
  static CONTAGION_REPORTS_ENDPOINT = "/contagion/report/export";
  static USAGE_REPORT_COVID_TRACKER_ENDPOINT = "/event/report/export";
  static USAGE_REPORT_WORKSPACE_ENDPOINT = "/location/report/export";

  static USER_LOG_ACTION_CREATE = "/user_log/create";
  static USER_LOG_ACTION_LIST = "/user_log/list";

  static CreateUserLog = (params: any) => {
    return Api.Post(CommonApi.USER_LOG_ACTION_CREATE, params);
  };

  static GetUserLog = (params?: any) => {
    return Api.Post(CommonApi.USER_LOG_ACTION_LIST, params);
  };

  static GetReports = (params: any) => {
    return Api.Post(CommonApi.EV_REPORTS_ENDPOINT, params);
  };

  static GetLocationReports = (params: any) => {
    return Api.Post(CommonApi.LOC_REPORTS_ENDPOINT, params);
  };
  
  static GetLogs = (params: any) => {
    return Api.Post(CommonApi.LOG_ENDPOINT, params);
  };

  static GetLogsAuditReport = (params: any) => {
    return Api.Post(CommonApi.LOG_REPORTS_ENDPOINT, params);
  };

  static GetContagionContactReport = (params: any) => {
    return Api.Post(CommonApi.CONTACT_REPORTS_ENDPOINT, params);
  };

  static GetContagionReport = (params: any) => {
    return Api.Post(CommonApi.CONTAGION_REPORTS_ENDPOINT, params);
  };

  static GetCovidTrackerUsageReport = (params: any) => {
    if(localStorage.getItem("DEBUG_MODE") == "true"){
      console.log("[COVID TRACKER USAGE REPORT]")
    }
    return Api.Post(CommonApi.USAGE_REPORT_COVID_TRACKER_ENDPOINT, params);
  };

  static GetWorkspaceUsageReport = (params: any) => {
    if(localStorage.getItem("DEBUG_MODE") == "true"){
      console.log("[WORKSPACE USAGE REPORT]")
    }
    return Api.Post(CommonApi.USAGE_REPORT_WORKSPACE_ENDPOINT, params);
  };


}
