import { Action } from "./Action";
import { Motivation } from "../Motivation";
import { BaseReservation, Reservation } from "../Reservation";
import { User } from "../User";

export class BookingAction extends Action {
  static ON_GENERIC_BOOKING_REQUEST: string = "ON_GENERIC_BOOKING_REQUEST";
  static ON_GENERIC_BOOKING_SUCCESS: string = "ON_GENERIC_BOOKING_SUCCESS";
  static ON_GETRESERVATIONS_SUCCESS: string = "ON_GETRESERVATIONS_SUCCESS";
  static ON_GETRESERVATION_SUCCESS: string = "ON_GETRESERVATION_SUCCESS";
  static ON_GENERIC_BOOKING_ERROR: string = "ON_GENERIC_BOOKING_ERROR";
  static ON_SLOTS_SUCCESS: string = "ON_SLOTS_SUCCESS";
  static ON_EXPORT_BOOKING_REQUEST: string = "ON_EXPORT_BOOKING_REQUEST";
  static ON_EXPORT_BOOKING_SUCCESS: string = "ON_EXPORT_BOOKING_SUCCESS";

  constructor(type: string, response: any) {
    super(type, response);
    this.response = response;
  }

  static OnExportRequest = () => {
    return new BookingAction(
      BookingAction.ON_EXPORT_BOOKING_REQUEST,
      {}
    ).toJSON();
  };

  static OnExportSuccess = () => {
    return new BookingAction(
      BookingAction.ON_EXPORT_BOOKING_SUCCESS,
      {}
    ).toJSON();
  };

  static OnGenericRequest = () => {
    return new BookingAction(
      BookingAction.ON_GENERIC_BOOKING_REQUEST,
      {}
    ).toJSON();
  };

  static OnGenericSuccess = () => {
    return new BookingAction(
      BookingAction.ON_GENERIC_BOOKING_SUCCESS,
      {}
    ).toJSON();
  };

  static OnGetReservationSuccess = (data: any) => {
    return new BookingAction(
      BookingAction.ON_GETRESERVATION_SUCCESS,
      data
    ).toJSON();
  };

  static OnGetReservationsSuccess = (data: any) => {
    for (var i in data.items) {
      data.items[i].booker = new User(data.items[i].booker).setFullname();
      data.items[i].booker_name = data.items[i].booker.fullname;
      data.items[i].user = new User(data.items[i].user).setFullname();
      data.items[i].user_name = data.items[i].user.fullname;

      let tags = [];
      for (var x in data.items[i].tags) {
        if (data.items[i].tags[x].guid !== data.items[i].booker_guid) {
          tags.push(new User(data.items[i].tags[x]).setFullname());
        }
      }
      data.items[i].tags = tags;
    }
    console.log(data);

    return new BookingAction(
      BookingAction.ON_GETRESERVATIONS_SUCCESS,
      data
    ).toJSON();
  };

  static OnSlotsSuccess = (data: any[]) => {
    return new BookingAction(BookingAction.ON_SLOTS_SUCCESS, data).toJSON();
  };

  static OnGenericError = () => {
    return new BookingAction(
      BookingAction.ON_GENERIC_BOOKING_ERROR,
      {}
    ).toJSON();
  };

  static OnApproveReject = (guids: any[], reservations: Reservation[]) => {
    let data = BookingAction.setMultipleReservationsLoading(
      guids,
      reservations,
      true
    );

    return new BookingAction(
      BookingAction.ON_GETRESERVATION_SUCCESS,
      data
    ).toJSON();
  };

  static OnApproveRejectError = (guids: any[], reservations: Reservation[]) => {
    let data = BookingAction.setMultipleReservationsLoading(
      guids,
      reservations,
      false
    );

    return new BookingAction(
      BookingAction.ON_GETRESERVATION_SUCCESS,
      data
    ).toJSON();
  };

  static OnApproveSuccess = (
    successGuids: any[],
    reservations: Reservation[]
  ) => {
    for (var i in reservations) {
      for (var x in successGuids) {
        if (reservations[i].guid === successGuids[x]) {
          reservations.splice(parseInt(i), 1);
        } else {
          reservations[i].loading = false;
        }
      }
    }
    let data = [...reservations];
    return new BookingAction(
      BookingAction.ON_GETRESERVATION_SUCCESS,
      data
    ).toJSON();
  };

  static OnRejectSuccess = (
    rejectedGuids: any[],
    reservations: Reservation[]
  ) => {
    for (var i in reservations) {
      for (var x in rejectedGuids) {
        if (reservations[i].guid === rejectedGuids[x]) {
          reservations.splice(parseInt(i), 1);
        } else {
          reservations[i].loading = false;
        }
      }
    }
    let data = [...reservations];
    return new BookingAction(
      BookingAction.ON_GETRESERVATION_SUCCESS,
      data
    ).toJSON();
  };

  static setMultipleReservationsLoading(
    guids: any[],
    reservations: Reservation[],
    loading: boolean
  ) {
    for (var i in reservations) {
      for (var x in guids) {
        if (reservations[i].guid === guids[x].guid) {
          reservations[i].loading = loading;
        }
      }
    }
    return reservations;
  }

  static setReservationLoading = (
    reservation: Reservation,
    list: Reservation[],
    loading: boolean
  ) => {
    for (var i in list) {
      if (list[i].guid === reservation.guid) {
        reservation.loading = loading;
        list[i] = reservation;
        break;
      }
    }
    return list;
  };
}
