import React, { CSSProperties, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './ForgotPasswordForm.scss';
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button';
import { Button as UIButton } from 'semantic-ui-react';
import logo from '../../assets/imgs/backoffice/logo-no-claim.png';
import { APP } from '../../interfaces/SwitchState';
import { ProgressSpinner } from 'primereact/progressspinner';
import { history } from './../../routing/AppRouter';
import { Link } from 'react-router-dom';


const ForgotPasswordForm = (props: any) => {
    const [email, setEmail] = useState('');

    const block: CSSProperties = {
        display: 'block'
      }

      const none: CSSProperties = {
        display: 'none'
      }


    const onSubmit = (event: any) => {
        event.preventDefault();
        props.onSubmit(email);
    };

    const changeEmail = (event: any) => {
        setEmail(event.target.value.toLowerCase());
    };

    const onSetApp = (event: any) => {
        let buttons = document.getElementsByClassName("switch-button")
        for (var i in buttons) {
            buttons[i].classList?.remove("active");
        }
        props.onSetApp(event.target.id)
    }

    const backToLogin = () => {
        history.push('/login');
      }

    //console.log("[URL PATH] ", window.location.href)

    return (
    
        <div className="Forgot _flex flex-1 flex-direction-column h-100 justify-content-center align-items-center">
            { (props.loading || props.googleLoading) ? (
            <div className="row justify-content-center container-opaque">
                <div className="loader">
                </div>
            </div> 
            
            )
            : (
            <div >
                <div className="forgot-img"></div>
                <div className="_flex forgot-form flex-direction-column justify-content-between align-items-center px-4">
                    {/* <h1 className="_flex login-title"><FormattedMessage id="login.title" defaultMessage="Covid-19" /></h1> */}
                    <div className="_flex col-md-6 col-xs-12 flex-direction-column">
                        <img src={logo} className="w-100" />
                        <h2 className="_flex forgot-subtitle"><FormattedMessage id="forgot.title" defaultMessage="Covid-19" /> <FormattedMessage id="forgot.subtitle" defaultMessage="Tracker" /></h2>
                    </div>
                    
                    <form className="col-md-6 col-xs-12  _flex flex-direction-column justify-content-center">
                        <div className="_flex w-100">
                            <FormattedMessage id="login.placeholder.email" defaultMessage="email">
                                {(placeholder: string) => (
                                    <Input elementType="input"
                                        fluid
                                        icon="icon icon-cv19-user"
                                        placeholder={placeholder}
                                        changed={changeEmail}
                                        type="email"
                                        containerClass="ui left icon input w-100"
                                        className="my-2 rounded-input w-100 fat"
                                        value={email}></Input>
                                )}
                            </FormattedMessage>
                        </div>

                        <div className="_flex w-100 justify-content-center">

                                <Button
                                    //className="button"
                                    btntype="OK green-bg px-4 rounded-input w-100 my-2"
                                    clicked={onSubmit}
                                    loading={props.loading}
                                    disabled={(!email || email === "")}>
                                    <FormattedMessage id="forgot.button" defaultMessage="Reset" />
                                </Button>

                        </div>
                        <div className="_flex w-100 justify-content-center">
                            <Link className="forgot-link" to="/login" replace ><FormattedMessage id="login.forgot.back_to_login" defaultMessage="Back to Login" /></Link>
                        </div>
                    </form> 


                </div>
            </div > )}
        </div>
    );
};

export default ForgotPasswordForm;
