import React, { useState, useEffect, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Panel } from 'primereact/panel';
import './Settings.scss';
import { Modal, Header, Loader, Label, Tab } from 'semantic-ui-react';
import Button from '../../../../UI/Button/Button';
import Input from '../../../../UI/Input/Input';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button as UIButton } from 'semantic-ui-react';
import { Motivation } from '../../../../../models/Motivation';
import { Entrance } from '../../../../../models/Entrance';
import { Row, Col } from 'react-bootstrap';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { error } from 'console';
import { IsWsManager } from '../../../../../interfaces/SwitchState';



const SettingsTab = (props: any) => {
    if (localStorage.getItem("DEBUG_MODE") == "true") {
        console.log("SETTINGS PROPS", props)
    }
    const [entrance, setEntrance] = useState<any>(props.entrance ? props.entrance : new Entrance)
    const { formatMessage } = props.intl;

    // Timezones
    const [timezoneSuggestion, setTimezoneSuggestion] = useState("") //useState(props.selectedNode.timezone)
    const [suggested, setSuggested] = useState<any>()
    const [timezone, setTimezone] = useState("")

    //Search User
    const [userSearch, setUserSearch] = useState<any>()
    const [userGuid, setUserGuid] = useState("")
    const [user, setUser] = useState(undefined)
    const [suggestionUserTech, setSuggestionUserTech] = useState<any[]>([])

    const [userReportSearch, setUserReportSearch] = useState<any>()
    const [userReportGuid, setUserReportGuid] = useState("")
    const [userReport, setUserReport] = useState(undefined)
    const [suggestionUserReport, setSuggestionUserReport] = useState<any[]>([])

    //User Type
    const [userType, setUserType] = useState(undefined)
    const userTypeItems = [
        { label: formatMessage({ id: "user.type.internal" }), value: 'internal' },
        { label: formatMessage({ id: "user.type.external" }), value: 'external' },
        { label: formatMessage({ id: "user.type.all" }), value: 'all' },
    ];

    //Login Type
    const [loginType, setLoginType] = useState<any[]>([])
    const [loginOptions, setLoginOptions] = useState<any[]>([])

    //Booking Window Limits
    const [bookingLimitMin, setBookingLimitMin] = useState(1)
    const [bookingLimitMax, setBookingLimitMax] = useState(365)

    //Flags
    const [userTypeError, setUserTypeError] = useState<boolean>(false)

    //Regex Types
    const [regexActive, setRegexActive] = useState<any[]>([])
    const [pwdMin, setPwdMin] = useState(1)
    const [pwdMax, setPwdMax] = useState()
    const [pwdMaxFlag, setPwdMaxFlag] = useState(true)
    //Regex Requirements
    const LENGTH: string = "LENGTH"


    useEffect(() => {
        setEntrance(props.entrance ? props.entrance : {})
        console.log("[++++ CONFIGURATIONS ++++] ", props.entrance);

        props.onGetLoginTypes()

        console.log("[CONFIGURATED TIMEZONE] ", props.entrance.timezone)
        if (props.entrance.timezone) {
            //setSuggested(props.entrance.timezone);
            setTimezone(props.entrance.timezone)
            setTimezoneSuggestion(props.entrance.timezone)
        }

        if (!isNullOrUndefined(props.login_types))
            setLoginOptions(props.login_types)

        //SetUp Configurated Logins
        let types: any[] = []
        if (props.logins) {
            //console.log("[+++ LOGIN TYPES +++] ", props.logins)
            props.logins.forEach((type: any) => {
                types.push(type.id)
            })
            setLoginType(types)
        }

        setUserTypeError(false)

        setUserType(props.entrance?.tagging_mode)

        regexInit()

    }, [props.entrance])

    /*************************************/
    /*****     SECURITY SETTINGS     *****/
    /*************************************/

    const regexInit = () => {
        let activeRegexTmp: any[] = []
        let regexArray: any[] = []

        //console.log("Password Regex ", props.regexes)
        props.regexes?.forEach((re: any) => {

            if (re.description == LENGTH) {
                let arr = re.text.replace(/[\.\{\}]/g, "").split(",").map((item: any) => item.trim());
                setPwdMin(arr[0]);
                if (arr[1] != "") {
                    setPwdMax(arr[1]);
                    setPwdMaxFlag(false)
                }
                //console.log("--- Min: ", pwdMin, " - Max: ", pwdMax);
            }


            //console.log("[-- Regex --] ", re)
            let currentRe: any = {};
            currentRe.id = re.id
            currentRe.active = true
            activeRegexTmp.push(currentRe)
            regexArray[re.id] = true;
        })

        props.regex_types.forEach((re: any) => {
            let currentRe: any = {};
            if (!activeRegexTmp.find(x => x.id == re.id)) {
                currentRe.id = re.id
                currentRe.active = false
                activeRegexTmp.push(currentRe)
                regexArray[re.id] = false;
            }
        })

        setRegexActive(activeRegexTmp)
    }

    const changeRegexCheck = (value: any, id: any) => {
        //console.log("[Change Regex] value: ", value, "  -- id: ", id)

        let regexes: any[] = Object.assign([], regexActive);
        regexes.find(x => x.id == id).active = value
        setRegexActive(regexes)

        //console.log("[-- Active Regex --] ", regexActive)
    }

    const regexOption = () => {
        let regexCheckbox: any = []

        props.regex_types.forEach((type: any) => {
            if (type.description != LENGTH) {

                let id = "settings.security." + type.description.toLowerCase()
                let regex = (
                    <div>
                        <Row className="px-4">
                            <Col md="9" className="label"><FormattedMessage id={id} defaultMessage={type.description.toLowerCase} /></Col>
                            <Col md="3" className="justify-content-center _flex">
                                <Checkbox className="my-3"
                                    onChange={e => changeRegexCheck(e.checked, type.id)}
                                    checked={regexActive.find(x => x.id == type.id)?.active}
                                >
                                </Checkbox>
                            </Col>
                        </Row>
                        <hr />
                    </div>);
                regexCheckbox.push(regex);
            }
        })


        return (
            regexCheckbox
        )

    }


    /*************************************/
    /*****      BOOKING SETTINGS     *****/
    /*************************************/
    const formatDateToTime = (date: string) => {
        if (date) {
            return moment(date).format('HH:mm')
        }
        return "00:00"
    }

    const formatTimeToDate = (time: string) => {
        let date = moment().startOf('day')
        let timesplit = time.split(":")
        date.hour(parseInt(timesplit[0]))
        date.minutes(parseInt(timesplit[1]))
        return date.format('YYYY-MM-DD HH:mm')

    }

    const formatTimeToMins = (time: string) => {
        return moment.duration(time).asMinutes().toString()
    }

    const formatMinutesToTime = (minutes: any) => {
        if (minutes) {
            let hours: any = Math.floor(minutes / 60)
            let mins: any = minutes % 60
            if (hours < 10) {
                hours = '0' + hours
            }

            if (mins < 10) {
                mins = '0' + mins
            }

            return hours + ":" + mins
        }
        return "00:00"
    }

    const set = (value: any, prop: string) => {
        let newEntrance: any = { ...entrance }
        switch (prop) {
            case 'first_entrance':
            case 'last_entrance':
                newEntrance[prop] = formatTimeToDate(value)
                break
            case 'slot_duration':
                newEntrance[prop] = formatTimeToMins(value)
                break
            case 'token_expiration_limit':
            case 'no_show_timeout':
            case 'min_day_booking':
            case 'max_day_booking':
                newEntrance[prop] = Number(value)
                break
            case 'tagging_mode':
                newEntrance[prop] = value
                break
            case 'timezone':
                newEntrance[prop] = value.timezone
                newEntrance['timezone_code'] = value.guid
                break
            case 'report_contact':
                newEntrance[prop] = value.email
                newEntrance['report_contact_code'] = value.guid
                break
            case 'alert_contact':
                newEntrance[prop] = value.email
                newEntrance['alert_contact_code'] = value.guid
                break
            default:
                newEntrance[prop] = value
                break
        }

        setEntrance(newEntrance)
    }


    const onSetBookingMin = (value: any) => {
        setBookingLimitMin(value)
        //console.log("[BOOKING LIMIT MIN] ", bookingLimitMin)

    }

    const onSetBookingMax = (value: any) => {
        setBookingLimitMax(value)
        //console.log("[BOOKING LIMIT MAX] ", bookingLimitMax)
    }


    /*************************************/
    /*    BOOKING SETTINGS ->  USERS     */
    /*************************************/
    const onUsersTechChange = (e: any) => {

        if (e && e.value) {
            setUserSearch(e.value)
            setUser(e.value)
            setUserGuid(e.value.guid)
        } else {
            setUser(undefined)
            setUserGuid("")
            setUserSearch("")
        }
        console.log("[USER TECH] ", user)
    }

    const onUsersReportChange = (e: any) => {

        if (e && e.value) {
            setUserReportSearch(e.value)
            setUserReport(e.value)
            setUserReportGuid(e.value.guid)
        } else {
            setUserReport(undefined)
            setUserReportGuid("")
            setUserReportSearch("")
        }
        console.log("[USER REPORT] ", userReport)
    }

    const onUserTechSelection = (e: any) => {
        setUser(e.value)
        if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log("[USER TECH SELECTED] ", e.value)
        }
    }

    const onUserReportSelection = (e: any) => {
        setUserReport(e.value)
        if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log("[USER TECH REPORT] ", e.value)
        }
    }

    const suggestUsersTech = (event: any) => {
        props.onSearchUsers({ 'fullname': { value: event.query } })
        setSuggestionUserTech(props.users)
    }

    const suggestUsersReport = (event: any) => {
        props.onSearchUsers({ 'fullname': { value: event.query } })
        setSuggestionUserReport(props.users)
    }

    function itemTemplate(item: any) {
        return (
            <div >
                <h6>{item.fullname}</h6>
                <p>{item.email}</p>
            </div>
        );
    }

    const onSetUserType = (event: any) => {
        setUserType(event.value)
        //console.log("[USER TYPE] ", event.value)
    }

    const onSetLoginType = (event: any) => {
        setLoginType([])
        setLoginType(event.value)
        console.log("[LOGIN TYPE] ", event.value)
    }


    /*************************************/
    /*  LOCATION SETTINGS ->  TIMEZONES  */
    /*************************************/
    const onSelectChange = (e: any) => {

        if (e && e.value) {
            setSuggested(e.value)
        } else {
            setSuggested("")
        }
    }

    const suggestTimezones = (event: any) => {
        if (event.query.length > 0) {
            console.log("[TIMEZONE VALUE SENT] ", event.query)
            props.onSearchTimezones({ filters: { timezone: { value: event.query } } })
        }
    }

    const onSuggestionSelection = (e: any) => {
        setTimezone(e.value)
        setTimezoneSuggestion(e.value.timezone)
        if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log("[TIMEZONE SELECTED] ", e.value)
        }
    }

    const isNullOrUndefined = (string: any) => {
        if (string == undefined || string == null || string == "")
            return true;
        return false
    }

    /*************************************/
    /*               SUBMIT              */
    /*************************************/
    const onSave = () => {

        //Handle Security Settings
        let newRegex: any[] = []
        let length = ".{" + pwdMin + ",";
        if (!pwdMaxFlag && !isNullOrUndefined(pwdMax) && pwdMax != "undefined")
            length = length + pwdMax + "}";
        else
            length = length + "}";
        //let length = ".{" + pwdMin + "," + ( !pwdMaxFlag ? pwdMax : "" ) + "}";
        console.log("--- Password lenght --- ", length)

        regexActive.forEach((re: any) => {
            if (re.active) {
                let re_type = props.regex_types.find((x: any) => x.id == re.id)
                if (re_type.description == LENGTH)
                    re_type.text = length

                //console.log("Regex Active -----> ", re_type)
                newRegex.push(re_type)
            }
        })
        entrance.password_regex = newRegex

        if (loginType.length == 0) {
            //alert( props.intl.formatMessage({ id: "settings.login.type.alert" }) )
            setUserTypeError(true)
        } else {
            setUserTypeError(false)
            //Took Login Choice selected
            entrance.logins = loginType;

            if (isNullOrUndefined(entrance.tagging_mode))
                entrance.tagging_mode = 'all'; //all by defaults

            if(entrance['max_day_booking'] === null){
                entrance['max_day_booking'] = bookingLimitMax
            }
            if(entrance['min_day_booking'] === null){
                entrance['max_day_booking'] = bookingLimitMin
            }
            if(entrance['no_show_timeout'] === null){
                entrance['no_show_timeout'] = 60
            }
            if(entrance['token_expiration_limit'] === null){
                entrance['token_expiration_limit'] = 720
            }

            console.log("[+++ ENTRANCE +++]", entrance)
            props.onUpdateEntrance(entrance)
        }

    }


    return (
    <div className="_flex h-100">
    <Tab.Pane className="Settings">

        <div className="m-custom">
            <Header><FormattedMessage id="location.entrance.set" defaultMessage="Entrance Settings" /></Header>
        </div>
        {props.loading ?
            <Loader active inline='centered' />
            :
            <div>
                <Panel header={props.intl.formatMessage({ id: "settings.title.security" })} className="m-panel panel-custom" toggleable collapsed>
                    <div className="Settings" >

                        {regexOption()}

                        <Row className="px-4 m-custom">
                            <Col md="6" className="label"><FormattedMessage id="settings.security.length" defaultMessage="Select Password Length" /></Col>

                          
                            <Col md="1" className="justify-content-center _flex">
                                <div>
                                    <div className="label-checkbox">
                                        <span>No Max</span>
                                    </div>
                                    <div className="label-checkbox">
                                        <Checkbox className="my-3 p-chkbox-label"
                                            onChange={e => setPwdMaxFlag(e.checked)}
                                            checked={pwdMaxFlag}>
                                        </Checkbox>
                                    </div>
                                </div>
                            </Col>


                            <Col md="2" className="justify-content-center _flex">
                                <div style={{display:'flex',alignItems:'center', flexDirection:'column'}} className="ui input w-100">
                                <label>Min</label>
                                    <Input elementType="input"
                                        //label={props.intl.formatMessage({ id: "settings.label.min" })}
                                        fluid
                                        type="number"
                                        elementConfig={{ min: 1, max: 99 }}
                                        containerClass="ui input w-100"
                                        className="my-2 rounded-input w-100 text-center"
                                        changed={(event: any) => setPwdMin(event.target.value)}
                                        value={pwdMin}
                                    />
                                </div>
                            </Col>

                            <Col md="2" className="justify-content-center _flex">
                                <div style={{display:'flex',alignItems:'center', flexDirection:'column'}} className="ui input w-100">
                                <label>Max</label>
                                    <Input elementType="input"
                                        //label={props.intl.formatMessage({ id: "settings.label.max" })}
                                        fluid
                                        type="number"
                                        elementConfig={{ min: 1, max: 99 }}
                                        containerClass="ui input w-100"
                                        className="my-2 rounded-input w-100 text-center"
                                        changed={(event: any) => setPwdMax(event.target.value)}
                                        value={pwdMax}
                                        disabled={pwdMaxFlag}
                                    />
                                </div>
                            </Col>
                            
                        </Row>

                    </div>
                </Panel>

                <Panel header={props.intl.formatMessage({ id: "settings.title.general" })} className="m-panel panel-custom" toggleable collapsed>
                    <div className="Settings" >
                        <Row className="px-4 m-custom">
                            <Col md="9" className="label"><FormattedMessage id="settings.email.contact.tech" defaultMessage="Contact Email Tech" /></Col>
                            <Col md="3" className="Contacts">
                                <div className="ui input w-100">
                                    <div className="_flex">
                                        <FormattedMessage id="biohazard.query.select" defaultMessage="Select User">
                                            {(placeholder: string) => (
                                                <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                                                    {(filterPlaceholder: string) => (
                                                        <div className="_flex">

                                                            <AutoComplete
                                                                className="_flex mr-3 "
                                                                value={userSearch}
                                                                dropdown={true}
                                                                field="fullname"
                                                                onChange={onUsersTechChange}
                                                                //onSelect={onUserTechSelection}
                                                                onSelect={(event: any) => { set(event.value, 'alert_contact'); onUserTechSelection(event) }}
                                                                suggestions={suggestionUserTech}
                                                                completeMethod={suggestUsersTech}
                                                                onDropdownClick={suggestUsersTech}
                                                                itemTemplate={itemTemplate}
                                                                //placeholder={filterPlaceholder}
                                                                placeholder={(!isNullOrUndefined(entrance.alert_contact)) ? entrance.alert_contact : filterPlaceholder}
                                                            />

                                                        </div>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr />

                        <Row className="px-4 m-custom">
                            <Col md="9" className="label"><FormattedMessage id="settings.email.contact.report" defaultMessage="Contact Email Report" /></Col>
                            <Col md="3" className="Contacts">
                                <div className="ui input w-100">
                                    <div className="_flex">
                                        <FormattedMessage id="biohazard.query.select" defaultMessage="Select User">
                                            {(placeholder: string) => (
                                                <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                                                    {(filterPlaceholder: string) => (
                                                        <div className="_flex">

                                                            <AutoComplete
                                                                className="_flex mr-3 "
                                                                value={userReportSearch}
                                                                dropdown={true}
                                                                field="fullname"
                                                                onChange={onUsersReportChange}
                                                                //onSelect={onUserReportSelection}
                                                                onSelect={(event: any) => { set(event.value, 'report_contact'); onUserReportSelection(event) }}
                                                                suggestions={suggestionUserReport}
                                                                completeMethod={suggestUsersReport}
                                                                onDropdownClick={suggestUsersReport}
                                                                itemTemplate={itemTemplate}
                                                                //placeholder={filterPlaceholder}
                                                                placeholder={(!isNullOrUndefined(entrance.report_contact)) ? entrance.report_contact : filterPlaceholder}
                                                            />

                                                        </div>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr />

                        <Row className="px-4 m-custom">
                            <Col md="9" className="label"><FormattedMessage id="settings.user.type" defaultMessage="Select User Type" /></Col>
                            <Col md="3" className="Contacts">
                                <div className="ui input w-100">

                                    <Dropdown
                                        value={userType}
                                        options={userTypeItems}
                                        //onChange={onSetUserType}
                                        onChange={(event: any) => { set(event.value, 'tagging_mode'); onSetUserType(event) }}
                                        placeholder={(!isNullOrUndefined(userType)) ? userType : props.intl.formatMessage({ id: "settings.user.type.search" })}
                                    />

                                </div>
                            </Col>
                        </Row>
                        <hr />

                        <Row className="px-4 m-custom">
                            <Col md="9" className="label"><FormattedMessage id="settings.login" defaultMessage="Login Type" /></Col>
                            <Col md="3" className="Contacts">
                                <div className="ui input w-100">
                                    <MultiSelect
                                        value={loginType ? loginType : []}
                                        optionLabel="text"
                                        optionValue="id"
                                        //value={this.state.cities}
                                        options={props.login_types}
                                        onChange={onSetLoginType}
                                        placeholder={(loginType.length != 0) ? loginType.toString() : formatMessage({ id: "settings.login.search" })}
                                    />

                                    {((userTypeError) ?
                                        (<div className="error">{props.intl.formatMessage({ id: "settings.login.type.alert" })}</div>)
                                        : null
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <hr />

                        <Row className="px-4 m-custom">
                            <Col md="9" className="label"><FormattedMessage id="settings.general.token.limit" defaultMessage="Select Token Expiration Limit" /></Col>

                            <Col md="3">
                                <div className="ui input w-100">
                                    <Input elementType="input"
                                        fluid
                                        type="number"
                                        elementConfig={{ min: 1, max: 720 }}
                                        containerClass="ui input w-100"
                                        className="my-2 rounded-input w-100 text-center"
                                        changed={(event: any) => { set(event.target.value, 'token_expiration_limit') }}
                                        //value={entrance.token_expiration_limit ? entrance.token_expiration_limit : bookingLimitMin}
                                        value={entrance.token_expiration_limit}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />

                    </div>
                </Panel>

                <Panel header={props.intl.formatMessage({ id: "settings.title.location" })} className="m-panel panel-custom" toggleable collapsed>
                    <div className="Settings" >
                        <Row className="px-4">
                            <Col md="9" className="label"><FormattedMessage id="settings.timezone" defaultMessage="Default" /></Col>
                            <Col md="3">

                                <div className="ui input w-100">

                                    <div className="detail-form">
                                        <div className="_flex w-100 flex-direction-column input-with-label mt-3">
                                            <label className="_flex">
                                                <FormattedMessage id="app.wsmanager.location.booking.timezones_selection" defaultMessage="Search Timezones" />
                                            </label>

                                            <AutoComplete className="_flex mr-3 "
                                                value={suggested}
                                                dropdown={true}
                                                field="timezone"
                                                onChange={onSelectChange}
                                                onSelect={(event: any) => { set(event.value, 'timezone'); onSuggestionSelection(event) }}
                                                suggestions={props.timezones}
                                                completeMethod={suggestTimezones}
                                                onDropdownClick={suggestTimezones}
                                                placeholder={(!isNullOrUndefined(timezoneSuggestion)) ? timezoneSuggestion : props.intl.formatMessage({ id: "timezone.query.search" })} //
                                            />

                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <hr />

                    </div>
                </Panel>

                {IsWsManager(props.activeApp) &&
                    <Panel header={props.intl.formatMessage({ id: "settings.title.booking" })} className="m-panel panel-custom" toggleable collapsed>
                        <div className="Settings" >
                            <Row className="px-4">
                                <Col md="9" className="label"><FormattedMessage id="location.entrance.first_entrance" defaultMessage="First Entrance" /></Col>
                                <Col md="3">

                                    <div className="ui input w-100">
                                        <TimeField
                                            className="my-2 rounded-input w-100 text-center"
                                            value={formatDateToTime(entrance.first_entrance)}
                                            onChange={(event: any, value: any) => set(value, 'first_entrance')}
                                            colon=":"
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <hr />
                            <Row className="px-4">
                                <Col md="9" className="label"><FormattedMessage id="location.entrance.last_entrance" defaultMessage="Last Entrance" /></Col>
                                <Col md="3">
                                    <div className="ui input w-100">
                                        <TimeField
                                            className="my-2 rounded-input w-100 text-center"
                                            value={formatDateToTime(entrance.last_entrance)}
                                            onChange={(event: any, value: any) => set(value, 'last_entrance')}
                                            colon=":"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />

                            <Row className="px-4">
                                <Col md="9" className="label"><FormattedMessage id="location.entrance.no_show_timeout" defaultMessage="No Show Timeout (minutes)" /></Col>
                                <Col md="3">
                                    <div className="ui input w-100">
                                        <Input elementType="input"
                                            fluid
                                            type="number"
                                            elementConfig={{ min: 0 }}
                                            containerClass="ui input w-100"
                                            className="my-2 rounded-input w-100 text-center"
                                            changed={(event: any) => { set(event.target.value, 'no_show_timeout') }}
                                            value={entrance.no_show_timeout} />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="px-4">
                                <Col md="9" className="label"><FormattedMessage id="location.entrance.auto_approve" defaultMessage="Auto Approve" /></Col>
                                <Col md="3" className="justify-content-center _flex">
                                    <Checkbox className="my-3" onChange={e => set(e.checked, 'auto_approve')} checked={entrance.auto_approve}></Checkbox>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="px-4">
                                <Col md="9" className="label"><FormattedMessage id="location.entrance.book_all_day" defaultMessage="All day booking" /></Col>
                                <Col md="3" className="justify-content-center _flex">
                                    <Checkbox className="my-3" onChange={e => set(e.checked, 'book_all_day')} checked={entrance.book_all_day} disabled={true}></Checkbox>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="px-4 m-custom">
                                <Col md="7" className="label" style={{marginRight: '40px'}}><FormattedMessage id="settings.booking.limit" defaultMessage="Select Booking Windows Limit" /></Col>

                                <Col md="2" className="justify-content-center _flex">
                                    <div style={{display:'flex',alignItems:'center', flexDirection:'column'}} className="ui input w-100">
                                    <label>Min</label>
                                        <Input elementType="input"
                                            //label={props.intl.formatMessage({ id: "settings.label.min" })}
                                            fluid
                                            type="number"
                                            elementConfig={{ min: 1, max: 365 }}
                                            containerClass="ui input w-100"
                                            className="my-2 rounded-input w-100 text-center"
                                            changed={(event: any) => { set(event.target.value, 'min_day_booking') }}
                                            value={entrance.min_day_booking ? entrance.min_day_booking : bookingLimitMin}
                                        />
                                    </div>
                                </Col>

                                <Col md="2" className="justify-content-center _flex">
                                    <div style={{display:'flex',alignItems:'center', flexDirection:'column'}} className="ui input w-100">
                                    <label>Max</label>
                                        <Input elementType="input"
                                            //label={props.intl.formatMessage({ id: "settings.label.max" })}
                                            fluid
                                            type="number"
                                            elementConfig={{ min: 1, max: 365 }}
                                            containerClass="ui input w-100"
                                            className="my-2 rounded-input w-100 text-center"
                                            changed={(event: any) => { set(event.target.value, 'max_day_booking') }}
                                            value={entrance.max_day_booking ? entrance.max_day_booking : bookingLimitMax}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Panel>

                }
                <div className="_flex justify-content-center m-custom">
                    <div className="_flex">
                        <Button btntype="detail-bg rounded-input" clicked={props.onCloseModal} >   <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" /></Button>
                        <Button btntype="green-bg rounded-input" clicked={onSave} loading={props.loading}>   <FormattedMessage id="app.generic.save" defaultMessage="Save" /></Button>
                    </div>
                </div>
            </div>
        }
    </Tab.Pane>
    </div>)
}

export default injectIntl(SettingsTab)