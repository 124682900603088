import { Toast } from "../../models/Toast";
import isObject from "lodash";

export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const REMOVE_ALL_TOASTS = "REMOVE_ALL_TOASTS";

export const AddError = (
  error?: any,
  toBeLocalized?: boolean,
  sticky?: boolean
) => {
  return (dispatch: any, getState: any) => {
    
    dispatch(removeAllToasts());
    if (!error || (!error.Error && !error.message)) {
      error = {
        message: "app.generic.error",
        Error: "app.generic.error",
      };

      dispatch({
        response: new Toast(error.Error).error().toJSON(),
        type: ADD_TOAST,
      });
    } else {
      if (!error.Error) {
        try {
          if (error.message.includes("{")) {
            let json = JSON.parse(error.message)
            error.Error = json.message ? json.message : json.Error
          } else {
            error.Error = error.message;
          }
        } catch (error) {
          error.Error = error.message;
        }
      }

      if (!toBeLocalized) {
        dispatch({
          response: new Toast(error.Error).error().notLocalized().toJSON(),
          type: ADD_TOAST,
        });
      } else {
        let toast = new Toast(error.Error);
        toast.type = "error";
        toast.title = "app.generic.error";
        toast.sticky = sticky ? sticky : false;
        dispatch({
          response: toast.toJSON(),
          type: ADD_TOAST,
        });
      }
    }
    console.log(new Toast(error.Error).error().toJSON());
  };
};

export const AddSuccess = (text: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(removeAllToasts());
    dispatch({
      response: new Toast(text).success().toJSON(),
      type: ADD_TOAST,
    });
    
  };
};

export const ChangePage = (text: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(removeAllToasts());
    dispatch({
      response: new Toast(text).info().toJSON(),
      type: ADD_TOAST,
    });
    
  };
};

export function removeAllToasts() {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: REMOVE_ALL_TOASTS,
    });
  };
}

export function removeToast(id: number) {
  return (dispatch: any, getState: any) => {
    dispatch({
      response: id,
      type: REMOVE_TOAST,
    });
  };
}
