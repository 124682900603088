import { Api } from "./Api";

export class BiohazardApi {
  static BASE_ENDP: string = "/contagion/report";
  static REPORT_ENDP: string = "/contagion/contact";
  static CONTAGION_REPORT: string = "/contagion/report/export";  
  static CONTACT_REPORT_EXPORT: string = "/contagion/contact/export";

  static GetBiohazardData = (...params: any) => {
    const [guid, startDate, endDate] = params;
    return Api.Post(
      BiohazardApi.BASE_ENDP,
      BiohazardApi.Request(guid, startDate, endDate)
    );
  };

  static GetContagionReport =  (params: any) => {
    return Api.Post(BiohazardApi.CONTAGION_REPORT, params);
  };

  static GetReportData = (...params: any) => {
    const [guid, startDate, endDate] = params;
    return Api.Post(BiohazardApi.REPORT_ENDP, {
      guid: guid,
      date_start: startDate,
      date_end: endDate,
    });
  };

  static GetContactReportData = (params: any) => {
    //console.log("[GET CONTACT REPORT - PARAMETERS] ", params)
    return Api.Post(BiohazardApi.CONTACT_REPORT_EXPORT, params);
  };

  static Request = (
    guid: string,
    date_range_start: string,
    date_range_end: string
  ) => {
    return {
      guid: guid,
      date_range_start: date_range_start,
      date_range_end: date_range_end,
    };
  };
}
