import React from 'react';
import { Switch, Router } from 'react-router-dom';
import RouteConfig from './RouteConfig'
import AppRoute from './AppRoute';
import { createBrowserHistory } from 'history';
import { Common } from '../utils/Common';
import HeaderContainer from '../containers/backoffice/HeaderContainer';

export const history = createBrowserHistory();


const AppRouter = (props: any) => {
    // console.log("[RouteConfig] ", RouteConfig)
    return (
        <Router history={history}>
            <HeaderContainer />
            <Switch>
                {RouteConfig.map((route, i) => {
                    // console.log("[AppRoute props] ", route, "  -   ", props);
                    return (
                        <AppRoute key={i} path={route.path} component={route.component} _private={route._private} redirectTo={route.redirectTo} exact={route.exact} />
                    )
                })}
            </Switch>
        </Router>
    )

}

export default AppRouter;
