import React, { useEffect } from 'react';
import { AppState } from '../../../interfaces/AppState';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SettingsTab from '../../../components/backoffice/Tabs/Common/Settings/Settings'
import { Entrance } from '../../../models/Entrance';
import { getEntrance, getLoginTypes, getRegexTypes, onUpdateEntrance } from '../../../store/actions/backoffice/settings';
import { getTimezones } from '../../../store/actions/backoffice/location';
import { getUsers } from '../../../store/actions/backoffice/users';


const SettingsTabContainer = (props: any) => {


    useEffect(() => {
        props.getEntrance()

        if (!props.users || props.users.length === 0) {
            props.getUsers({ per_page: 10, page: 0 })
        }

        props.onGetRegexType()
    }, [])

    return <div>
        <SettingsTab
            entrance={props.settings?.entrance}
            logins={props.settings?.entrance?.logins}
            loading={props.settings.loading}
            onUpdateEntrance={props.onUpdateEntrance}
            timezones={props.timezones}
            onSearchTimezones={(filter: any) => { props.onGetTimezones(filter) }}
            onSearchUsers={
                (filter: any) => {
                    props.getUsers({ per_page: 10, page: 0, filters: filter })
                }
            }
            users={props.users}
            onGetLoginTypes={() => props.onGetLoginTypes()}
            login_types={props.settings.login_types}
            onGetConfiguration={() => props.onGetConfiguration()}
            regex_types={props.settings.regex_types}
            regexes={props.settings?.entrance?.password_regex}
            activeApp = {props.activeApp}
        />
    </div>
}

const mapDispatchToProps = (dispatch: any) => ({
    getEntrance: () => { dispatch(getEntrance()) },
    onUpdateEntrance: (entrance: Entrance) => { dispatch(onUpdateEntrance(entrance)) },
    onGetTimezones: (data?: any) => { dispatch(getTimezones(data)) },
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    onGetLoginTypes: () => { dispatch(getLoginTypes()) },
    onGetRegexType: () => { dispatch(getRegexTypes()) }
})

const mapStateToProps = (state: AppState) => ({
    settings: state.settings,
    timezones: state.location.timezones,
    users: state.users.items,
    activeApp: state.app.activeApp,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SettingsTabContainer))