import { Motivation } from "../../../models/Motivation";
import { AppState } from "../../../interfaces/AppState";
import { SettingsAction } from "../../../models/actions/SettingsAction";
import { Api, HTTP_STATUS } from "../../../api/Api";
import { startLogout, uploadConfigurations } from "../auth";
import { AddError, AddSuccess } from "../toast";
import { SettingsApi } from "../../../api/SettingsApi";
import { Entrance } from "../../../models/Entrance";
import { Common } from "../../../utils/Common";

/**
 * @param  {Motivation} motivation
 * @param  {string} action
 */
export const onUpdateMotivation = (motivation: Motivation, action: string) => {
  return async (dispatch: any, getState: any) => {
    let motivations: Motivation[] = (getState() as AppState).settings
      .motivations;

    dispatch(SettingsAction.OnUpdateMotivationRequest(motivation, motivations));
    let response: Response | undefined;
    try {
      switch (action) {
        case Motivation.ADD:
          response = await addMotivation(motivation);
          break;
        case Motivation.TRASH:
          response = await trashMotivation(motivation);
          break;
        case Motivation.EDIT:
          response = await updateMotivation(motivation);
          break;
        default:
          response = undefined;
      }

      if (response && response.status === HTTP_STATUS.OK) {
        response.json().then(
          (json: any) => {
            if (action === Motivation.ADD && json.guid) {
              motivation.guid = json.guid;
            }
            dispatch(
              SettingsAction.OnUpdateMotivationSuccess(
                motivation,
                motivations,
                action
              )
            );
            dispatch(AddSuccess("app.operation.successful"));
          },
          (err: any) => {
            throw new Error(err);
          }
        );
      } else {
        let unauthorized = false;
        let error = "";
        Api.IsAuthorized(response)
          .then((text: any) => {
            error = text;
          })
          .catch((err: string) => {
            error = err;
            unauthorized = true;
          })
          .finally(() => {
            if (unauthorized) {
              dispatch(startLogout());
            } else {
              if (action === Motivation.ADD) {
                dispatch(
                  SettingsAction.OnAddMotivationError(motivation, motivations)
                );
              } else {
                dispatch(
                  SettingsAction.OnUpdateMotivationError(
                    motivation,
                    motivations
                  )
                );
              }
            }
            dispatch(AddError({ message: error }));
          });
      }
    } catch (error) {
      dispatch(AddError(error));
      if (action === Motivation.ADD) {
        dispatch(SettingsAction.OnAddMotivationError(motivation, motivations));
      } else {
        dispatch(
          SettingsAction.OnUpdateMotivationError(motivation, motivations)
        );
      }
    }
  };
};

export const onUpdateEntrance = (entrance: Entrance) => {
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.UpdateEntrance(entrance)
      .then((result: Response) => {
        response(
          result,
          dispatch,
          SettingsAction.OnUpdateEntranceSuccess,
          entrance
        ).catch((error: any) => {
          dispatch(AddError(error));
          dispatch(SettingsAction.OnGenericError());
        });
        dispatch(AddSuccess("app.operation.successful"));
        dispatch(getEntrance())
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(SettingsAction.OnGenericError());
      });
  };
};

export const getLoginTypes = () => {
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetLoginTypes()
    .then((response:any) => {
      console.log("RES", response);
      if (response.status === HTTP_STATUS.OK) {
        return response.json();
      } else {
        let unauthorized = false;
        let error = "";
        return Api.IsAuthorized(response)
          .then((text: any) => {
            error = text;
          })
          .catch((err: string) => {
            error = err;
            unauthorized = true;
          })
          .finally(() => {
            if (unauthorized) {
              dispatch(startLogout());
            }
            throw new Error(error);
          });
      }
    })
    .then((res:any) => {
      if (res) {
        console.log("[SUCCESS ON GET LOGIN TYPES DATA] ", res);
        dispatch(SettingsAction.OnGetLoginTypesSuccess(res));
      }
    })
    .catch((error: any) => {
      dispatch(AddError(error));
      dispatch(SettingsAction.OnGenericError());
    });
  };
}

export const getRegexTypes = () => {
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetRegexTypes()
    .then((response:any) => {
      console.log("RES", response);
      if (response.status === HTTP_STATUS.OK) {
        return response.json();
      } else {
        let unauthorized = false;
        let error = "";
        return Api.IsAuthorized(response)
          .then((text: any) => {
            error = text;
          })
          .catch((err: string) => {
            error = err;
            unauthorized = true;
          })
          .finally(() => {
            if (unauthorized) {
              dispatch(startLogout());
            }
            throw new Error(error);
          });
      }
    })
    .then((res:any) => {
      if (res) {
        console.log("[SUCCESS ON GET REGEX TYPES DATA] ", res);
        dispatch(SettingsAction.OnGetRegexTypesSuccess(res));
      }
    })
    .catch((error: any) => {
      dispatch(AddError(error));
      dispatch(SettingsAction.OnGenericError());
    });
  };
}


export const getMotivations = () => {
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetMotivations()
      .then((result: Response) => {
        response(result, dispatch, SettingsAction.OnGetMotivationSuccess).catch(
          (error: any) => {
            dispatch(AddError(error));
            dispatch(SettingsAction.OnGenericError());
          }
        );
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(SettingsAction.OnGenericError());
      });
  };
};

export const getEntrance = () => {
  
  console.log("[GET ENTRANCE]")
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetEntrance()
      .then((response:any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(SettingsAction.OnGetEntranceSuccess(response));
        dispatch(uploadConfigurations())
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(SettingsAction.OnGenericError());
      });
  };
};


export const getPublicConfigurations = () => {
  //console.log("[GET PUBLIC CONFIGURATIONS]")
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetPublicConfigurations()
      .then((response:any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        //console.log("[Public Configurations] ", response)
        dispatch(SettingsAction.OnGetPublicConfigurationSuccess(response));

      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(SettingsAction.OnGenericError());
      });
  };

};




export const getActiveTasks = (params: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.OnGenericRequest());
    SettingsApi.GetActiveTasks(params)
      .then((result: Response) => {
        response(result, dispatch, SettingsAction.OnGetTasksSuccess).catch(
          (error: any) => {
            dispatch(AddError(error));
            dispatch(SettingsAction.OnGenericError());
          }
        );
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(SettingsAction.OnGenericError());
      });
  };
};

const addMotivation = async (motivation: Motivation) => {
  return SettingsApi.AddMotivation(motivation);
};
const updateMotivation = async (motivation: Motivation) => {
  return SettingsApi.UpdateMotivation(motivation);
};
const trashMotivation = async (motivation: Motivation) => {
  return SettingsApi.DeleteMotivation(motivation);
};

const response = async (
  result: Response,
  dispatch: any,
  action: any,
  payload?: any
) => {
  if (result && result.status === HTTP_STATUS.OK) {
    return result
      .json()
      .then((json: any) => {
        dispatch(action(payload ? payload : json));
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  } else {
    let unauthorized = false;
    let error = "";
    return Api.IsAuthorized(result)
      .then((text: any) => {
        error = text;
      })
      .catch((err: string) => {
        error = err;
        unauthorized = true;
      })
      .finally(() => {
        if (unauthorized) {
          dispatch(startLogout());
        }
        throw new Error(error);
      });
  }
};


export const getImports = (data: any) => {
  console.log("[++++ ON GET IMPORTS ++++] ", data)
  return (dispatch: any, getState: any) => {
    dispatch(SettingsAction.onImportRequest());
    SettingsApi.GetImports(data)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        console.log("[GET USER IMPORTS] ", response);
        dispatch(SettingsAction.onImportSuccess(response));
      })
      .catch((error: Error) => {
        console.log("[GET USER IMPORTS ERROR] ", error);
        dispatch(AddError(error));
      });
  };
};
