import React, { CSSProperties, HtmlHTMLAttributes, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './ResetPasswordForm.scss';
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button';
import { Button as UIButton } from 'semantic-ui-react';
import logo from '../../assets/imgs/backoffice/logo-no-claim.png';
import { APP } from '../../interfaces/SwitchState';
import { ProgressSpinner } from 'primereact/progressspinner';
import { withRouter } from 'react-router-dom';


const ResetPasswordForm = (props: any) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [info, setInfo] = useState("");

    const { formatMessage } = props.intl;
    const LENGTH: string = "LENGTH";
    

    useEffect(() => {
        getInfo()
    })

    const getInfo = () => {
        let infoStr = formatMessage({ id: "reset.password.info.init" })
        infoStr = infoStr + "<ul>"

        props.public_configuration?.password_regex?.forEach((regex:any) => {
            let id =  "reset.password.error." + regex.description.toLowerCase()

            if(regex.description == LENGTH){             
                let arr = regex.text.replace(/[\.\{\}]/g, "").split(",").map((item:any) => item.trim());
                console.log(arr)
                let message = formatMessage({ id: "reset.password.info.requirements" }) + " " + formatMessage({ id: id }) + " ";
                if(arr[1] != ""){
                    message = message + formatMessage({ id: "reset.password.info.requirements.beetween" }) + " " + arr[0] +
                    " " + formatMessage({ id: "reset.password.info.requirements.and" }) + " " + arr[1]
                } else {
                    message = message + formatMessage({ id: "reset.password.info.requirements.min" }) + " " + arr[0]
                }
                infoStr = infoStr + "<li>" +  message + "</li>";
            } else {            
                console.log("[Regex description] ", regex.description)
                let message = formatMessage({ id: "reset.password.info.requirements" }) + " " + formatMessage({ id: id })
                infoStr = infoStr + "<li>" +  message + "</li>";
            }
        })

        infoStr = infoStr + "</ul>"

        setInfo(infoStr)
    }

    const block: CSSProperties = {
        display: 'block'
      }

      const none: CSSProperties = {
        display: 'none'
      }

    const onSubmit = (event: any) => {
        event.preventDefault();
        if(checkRegex()){
            props.onSubmit(password, passwordConfirm);
        }
    };

    const changeEmail = (event: any) => {
        setEmail(event.target.value.toLowerCase());
    };

    const changePassword = (event: any) => {
        setPassword(event.target.value);
    };

    const changePasswordConfirm = (event: any) => {
        setPasswordConfirm(event.target.value);
    };

    const onSetApp = (event: any) => {
        let buttons = document.getElementsByClassName("switch-button")
        for (var i in buttons) {
            buttons[i].classList?.remove("active");
        }
        props.onSetApp(event.target.id)
    }

    const checkRegex = () => {
        let regexFlag = true
        let errorString: string = formatMessage({ id: "reset.password.error.init" }) 
        errorString = errorString + "<ul>"

        props.public_configuration?.password_regex?.forEach((regex:any) => {
            //console.log("[ ", regex.id ," - Regex Constraint] ", regex.text)
            let re = new RegExp(regex.text)
            //console.log("[Regex description] ", regex.description)
            if( !re.test(password)){
                //console.log("[Regex description] ", regex.description)
                let id =  "reset.password.error." + regex.description.toLowerCase()
                errorString = errorString + "<li>" + formatMessage({ id: id }) + "</li>";
                regexFlag = false
            }                        
        })

        errorString = errorString + "</ul>"

        setErrorFlag(!regexFlag)
        setError(errorString)
        console.log("regexFlag: ", regexFlag, "  -- errorString: ", errorString)

        return regexFlag;
    }

    const checkPassword = () => {

        if( (password || password != "") && 
            (passwordConfirm || passwordConfirm != "") && 
            (password == passwordConfirm)) {  
            return false
        }

        return true
    }

    //console.log("[URL PATH] ", window.location.href)

    return (
    
        <div className="Forgot _flex flex-1 flex-direction-column h-100 justify-content-center align-items-center">
            { (props.loading || props.googleLoading) ? (
            <div className="row justify-content-center container-opaque">
                <div className="loader">
                </div>
            </div> 
            
            )
            : (
            <div >
                <div className="forgot-img"></div>
                <div className="_flex forgot-form flex-direction-column justify-content-between align-items-center px-4">
                    {/* <h1 className="_flex login-title"><FormattedMessage id="login.title" defaultMessage="Covid-19" /></h1> */}
                    <div className="_flex col-md-6 col-xs-12 flex-direction-column">
                        <img src={logo} className="w-100" />
                        <h2 className="_flex forgot-subtitle"><FormattedMessage id="reset.title" defaultMessage="Covid-19" /> <FormattedMessage id="reset.subtitle" defaultMessage="Tracker" /></h2>
                    </div>
                    
                    {/* Password Requirements */}
                    <div className="_flex w-100 justify-content-center">
                        <div className="info-container">   
                            <div className="info-text" dangerouslySetInnerHTML={{__html: info}}></div>
                            { (!errorFlag) ? null : (
                                // <div>
                                //     <div className="_flex w-100 justify-content-center">
                                //         <div className="error-label" >{error}</div>
                                //     </div>
                                // </div>
                                <div className="error-label" dangerouslySetInnerHTML={{__html: error}}></div>
                            )}
                        </div>
                    </div>

                    <form className="col-md-6 col-xs-12  _flex flex-direction-column justify-content-center">

                        <div className="_flex w-100">
                            <FormattedMessage
                                id="reset.placeholder.password"
                                defaultMessage="password">
                                {(placeholder: string) => (
                                    <Input elementType="input"
                                        fluid
                                        icon="icon icon-cv19-password"
                                        placeholder={placeholder}
                                        changed={changePassword}
                                        type="password"
                                        containerClass="ui left icon input w-100"
                                        className="my-2 rounded-input fat"
                                        value={password}></Input>

                                )}
                            </FormattedMessage>
                        </div>

                        <div className="_flex w-100">
                            <FormattedMessage
                                id="reset.placeholder.password.confirm"
                                defaultMessage="password">
                                {(placeholder: string) => (
                                    <Input elementType="input"
                                        fluid
                                        icon="icon icon-cv19-password"
                                        placeholder={placeholder}
                                        changed={changePasswordConfirm}
                                        type="password"
                                        containerClass="ui left icon input w-100"
                                        className="my-2 rounded-input fat"
                                        value={passwordConfirm}></Input>

                                )}
                            </FormattedMessage>
                        </div>

                        <div className="_flex w-100 justify-content-center">
                            <Button
                                btntype="OK green-bg px-4 rounded-input w-100 my-2"
                                clicked={onSubmit}
                                loading={props.loading}
                                disabled={checkPassword()}>
                                <FormattedMessage id="reset.button" defaultMessage="Reset" />
                            </Button>
                        </div>
                    </form> 


                </div>
            </div > )}
        </div>
    );
};

export default injectIntl(ResetPasswordForm);
