import { Api } from "./Api";
import { BaseMotivation } from "../models/Motivation";

export class SettingsApi {
  static BASE_ENDP: string = "/job_task";
  static ENTRANCE_BASE_ENDP: string = "/configuration";
  static LOGIN_BASE_ENDP: string = "/login_mode";
  static JOBTASK_BASE_ENDP: string = "/job_task/list";
  static ENTRANCE_CRE_ENDP: string = SettingsApi.ENTRANCE_BASE_ENDP + "/create";
  static ENTRANCE_UPD_ENDP: string = SettingsApi.ENTRANCE_BASE_ENDP + "/update";
  static MOTIVATIONS_BASE_ENDP: string = "/reason";
  static MOTIVATIONS_CRE_ENDP: string =
    SettingsApi.MOTIVATIONS_BASE_ENDP + "/create";
  static MOTIVATIONS_UPD_ENDP: string =
    SettingsApi.MOTIVATIONS_BASE_ENDP + "/update";
  static MOTIVATIONS_DEL_ENDP: string =
    SettingsApi.MOTIVATIONS_BASE_ENDP + "/delete";
  static MOTIVATIONS_LIST_ENDP: string =
    SettingsApi.MOTIVATIONS_BASE_ENDP + "/list";
  static GET_IMPORTS: string = SettingsApi.BASE_ENDP + "/imports";
  static GET_LOGIN_TYPES: string = SettingsApi.LOGIN_BASE_ENDP + "/list";
  static GET_PUBLIC_CONFIGURATION: string = SettingsApi.ENTRANCE_BASE_ENDP + "/public";
  static GET_REGEX_TYPES: string = "/regex/list";

  
  static GetConfiguration = (params?: any) => {
    return Api.Post(SettingsApi.GET_LOGIN_TYPES, params);
  };
  static GetLoginTypes = (params?: any) => {
    return Api.Post(SettingsApi.GET_LOGIN_TYPES, params);
  };
  static GetRegexTypes = (params?: any) => {
    return Api.Post(SettingsApi.GET_REGEX_TYPES, params);
  };
  static GetPublicConfigurations = (params?: any) => {
    return Api.Get(SettingsApi.GET_PUBLIC_CONFIGURATION, params);
  };
  static GetImports = (params: any) => {
    return Api.Post(SettingsApi.GET_IMPORTS, params);
  };
  static GetEntrance = (...params: any) => {
    return Api.Post(SettingsApi.ENTRANCE_BASE_ENDP, {});
  };
  static GetActiveTasks = (params: any) => {
    return Api.Post(SettingsApi.JOBTASK_BASE_ENDP, params);
  };

  static UpdateEntrance = (params: any) => {
    return Api.Put(SettingsApi.ENTRANCE_UPD_ENDP, params);
  };

  static GetMotivations = (...params: any) => {
    return Api.Post(SettingsApi.MOTIVATIONS_LIST_ENDP, {});
  };
  static AddMotivation = (params: any) => {
    let request: BaseMotivation = new BaseMotivation(params);
    delete request["guid"];
    return Api.Post(SettingsApi.MOTIVATIONS_CRE_ENDP, request);
  };
  static UpdateMotivation = (params: any) => {
    let request: BaseMotivation = new BaseMotivation(params);
    return Api.Put(SettingsApi.MOTIVATIONS_UPD_ENDP, request);
  };
  static DeleteMotivation = (params: any) => {
    let request: BaseMotivation = new BaseMotivation(params);
    return Api.Delete(SettingsApi.MOTIVATIONS_DEL_ENDP, request);
  };
}
