import { Action } from "./Action";
import { User } from "../User";

export class BiohazardAction extends Action {
  static GET_DATA = "GET_DATA";
  static GET_REPORT_DATA = "GET_REPORT_DATA";
  static REQUEST = "REQUEST";
  static REQUEST_FAILED = "REQUEST_FAILED";

  response: User[];

  constructor(type: string, response: User[]) {
    super(type, response);
    this.response = response;
  }

  static Request = () => {
    return new BiohazardAction(BiohazardAction.REQUEST, []).onRequest();
  };

  static onGetDataSuccess = (data: any[]) => {
    let newData: User[] = [];
    newData = data;
    return new BiohazardAction(BiohazardAction.GET_DATA, newData).onRequest();
  };

  static onGetReportDataSuccess = (data: any[]) => {
    let newData: User[] = [];
    newData = data;
    return new BiohazardAction(BiohazardAction.GET_REPORT_DATA, newData).onRequest();
  };

  static onRequestFailed = () => {
    return new BiohazardAction(BiohazardAction.REQUEST_FAILED, []).onRequest(); 
  }

}
