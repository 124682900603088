import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools

export default () => {
  const store = createStore (
    rootReducer,
    composeEnhancers (applyMiddleware (thunk))
  );

  return store;
};


