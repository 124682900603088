export const APP: any = {
  WSMANAGER: "WSMAN",
  COVID19: "COVID",
};

export const IsWsManager = (activeApp: string) => {
  return activeApp === APP.WSMANAGER;
};

export interface AppConfigState {
  activeApp: string;
  loading: boolean;
  caslInitialized: boolean;
  tabChange: boolean;
}
