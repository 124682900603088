import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LocationsTab from '../../../components/backoffice/Tabs/Common/Locations/Locations';
import { getLocations, update, trash, add, handleChange, print, uploadLocations, getTimezones, onMapSubmit, getMap } from '../../../store/actions/backoffice/location';
import { LocationProps } from '../../../interfaces/Location';
import { AppState } from '../../../interfaces/AppState';
import { Location } from '../../../models/Location';
import { LUActions } from '../../../utils/LocationUtils';
import { Motivation } from '../../../models/Motivation';
import { onUpdateMotivation, getEntrance, getMotivations, onUpdateEntrance, getActiveTasks, getImports } from '../../../store/actions/backoffice/settings';
import { SettingsType } from '../../../models/Settings';
import { Entrance } from '../../../models/Entrance';
import { getUsers } from '../../../store/actions/backoffice/users';
import { getTimetables, onBooking } from '../../../store/actions/backoffice/booking';
import { AddError } from '../../../store/actions/toast';
import { BaseReservation } from '../../../models/Reservation';



const LocationsTabContainer = (props: any) => {
    
    useEffect(() => { 
        props.getLocations(props.adminView)
        //console.log("[VIEW LOCATIONS]", props)

        // if (!props.timezones || props.timezones.length === 0 ) {
        //     props.onGetTimezones()
        // }

    }, [props.adminView, props.authState?.auth?.selectedCompany])

    const onNodeChange = (data: any, action: string, treeData: any, newData?: any, path?: any, deskUpdate?: boolean) => {
        console.log("[ON NODE CHANGE]", data, action)
        switch (action) {
            case LUActions.ADD:
            case LUActions.ADD_CHILD:
                props.add(data as Location, treeData, path)
                break
            case LUActions.UPDATE:
                props.update(data as Location, newData as Location, treeData, path, deskUpdate)
                break
            case LUActions.DELETE:
                props.trash(data as Location, treeData, path)
                break
            case LUActions.PRINT:
                props.print(data)
                break
            case LUActions.PRINT_ALL:
                props.print(props.treeData)
                break
        }
    }


    const getSettings = (type: string) => {
        switch (type) {
            case SettingsType.MOTIVATIONS:
                props.getMotivations()
                break
            case SettingsType.ENTRANCE:
                props.getEntrance()
                break
        }
    }


    return (
        <div className="h-100">
            <LocationsTab
                activeApp={props.activeApp}
                treeData={props.treeData}
                usersState={props.usersState}
                authState={props.authState}
                bookingState={props.bookingState}
                loading={props.loading}
                settings={props.settings}
                adminView={props.adminView}
                onBooking={props.onBooking}
                handleChange={props.handleChange}
                onNodeChange={onNodeChange}
                onImportFile={(file: File) => props.uploadLocations(file, props.adminView)}
                closeModal={props.closeModal}
                onUpdateMotivation={props.onUpdateMotivation}
                onUpdateEntrance={props.onUpdateEntrance}
                onGetSettings={getSettings}
                onGetUsers={props.getUsers}
                onGetTimetables={props.getTimetables}
                onGetActiveTasks={props.getActiveTasks}
                onError={props.onError}
                getTimezones={(filter: any) => {props.onGetTimezones(filter)}}
                timezones={props.timezones}
                onGetImports={(data: any) => { props.onGetImports(data) }}
                imports={props.settings}
                onMapSubmit={(guid: any, image: any, format: any) => props.onMapSubmit(guid, image, format)}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getLocations: (disabled: boolean) => { dispatch(getLocations(disabled, "", "", 1)) },
    getActiveTasks: (disabled: boolean) => { dispatch(getActiveTasks(disabled)) },
    getEntrance: () => { dispatch(getEntrance()) },
    getMotivations: () => { dispatch(getMotivations()) },
    handleChange: (treeData: any) => { dispatch(handleChange(treeData)) },
    add: (location: Location, treeData: any, path: any) => { dispatch(add(location, treeData, path)) },
    update: (location: Location, newLocation: Location, treeData: any, path: any, deskUpdate: boolean) => { dispatch(update(location, newLocation, treeData, path, deskUpdate)) },
    trash: (location: Location, treeData: any, path: any) => { dispatch(trash(location, treeData, path)) },
    print: (data?: any) => { dispatch(print(data)) },
    uploadLocations: (file: File, disabled: boolean) => { dispatch(uploadLocations(file, disabled)) },
    onUpdateMotivation: (motivation: Motivation, action: string) => { dispatch(onUpdateMotivation(motivation, action)) },
    onUpdateEntrance: (entrance: Entrance) => { dispatch(onUpdateEntrance(entrance)) },
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    getTimetables: (booking_datetime: string, location_guid: string, book_all_day?: boolean) => { dispatch(getTimetables(booking_datetime, location_guid, book_all_day)) },
    onError: (error?: any) => { dispatch(AddError(error)) },
    onBooking: (reservation: BaseReservation, email_array: any, motivation: string, location: string) => { dispatch(onBooking(reservation, email_array, motivation, location)) },
    onGetTimezones: (data?: any) => { dispatch(getTimezones(data)) },
    onGetImports: (data: any) => { dispatch(getImports(data)) },
    onMapSubmit: (guid: any, image: any, format: any) => {dispatch(onMapSubmit(guid, image, format))},
})

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loading: state.location.loading,
    treeData: state.location.treeData,
    closeModal: state.location.closeModal,
    activeApp: state.app.activeApp,
    settings: state.settings,
    authState: state.authReducer,
    usersState: state.users,
    bookingState: state.booking,
    adminView: ownProps.adminView,
    timezones: state.location.timezones,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocationsTabContainer))