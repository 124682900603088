import React from 'react';

import './Input.scss';

const input = (props: any) => {
    let inputElement = null;
    let inputClasses = props.className.split(" ")
    if(!inputClasses){
        inputClasses = []
    }

    inputClasses.push("InputElement")

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push("Invalid");
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <div className={props.containerClass}>
                <label>{props.label}</label>
                <input 
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    onChange={props.changed}
                    placeholder={props.placeholder}
                    value={props.value}
                    type={props.type} 
                    readOnly={props.readonly ? "readonly" : false}
                    disabled={props.disabled}
                    />
                    
                <i aria-hidden="true" className={props.icon}></i>
            </div>
                ;
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map((option: any) => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        <div className={"Input"}>
            <label className={"Label"}>{props.label}</label>
            {inputElement}
        </div>
    );

};

export default input;