import React, { CSSProperties, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './LoginForm.scss';
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button';
import { Button as UIButton, Form, Modal } from 'semantic-ui-react';
import logo from '../../assets/imgs/backoffice/logo-no-claim.png';
import { APP } from '../../interfaces/SwitchState';
import GoogleButton from '../../assets/imgs/backoffice/google-logo-g.png';
import MicrosoftButton from '../../assets/imgs/backoffice/microsoft-logo-square.png';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
import ModalLogout from '../UI/ModalLogout/ModalLogout';


const LoginForm = (props: any) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openModalNewPassword, setOpenModalNewPassword] = useState(false)
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        if (props.passwordNew === true) {
            setOpenModalNewPassword(true)
        } else {
            setOpenModalNewPassword(false)
        }

    }, [props.passwordNew])


    const onChangeNewPassword = (e: any) => {        
        setNewPassword(e.target.value)

    }

    const enterPasswordNew = () => {
        if(props.err && props.microsoftLoginEnterPasswordNew){
            props.microsoftLoginEnterPasswordNew(props.err, newPassword)
        }        
    }

    const block: CSSProperties = {
        display: 'block'
    }

    const none: CSSProperties = {
        display: 'none'
    }


    const onSubmit = (event: any) => {
        event.preventDefault();
        props.onSubmit(email, password);

    };

    const changeEmail = (event: any) => {
        setEmail(event.target.value.toLowerCase());
    };

    const changePassword = (event: any) => {
        setPassword(event.target.value);
    };

    const onSetApp = (event: any) => {
        let buttons = document.getElementsByClassName("switch-button")
        for (var i in buttons) {
            buttons[i].classList?.remove("active");
        }
        props.onSetApp(event.target.id)
    }

    const googleLogin = () => {
        props.onGoogleLogin()
    }

    const microsoftLogin = () => {
        let tenantId = '';
        for(let i = 0; i < props.public_configuration?.logins?.length; i++){
            if(props.public_configuration.logins[i].text === "Microsoft"){
                tenantId = props.public_configuration.logins[i].tenant_id;
            }
        }
        console.log("tenantId ", tenantId)
        props.onMicrosoftLogin(tenantId)
    }

    const checkLoginMethod = (index: any) => {
        let flag: boolean = false
        console.log('tipi di login: ', props.public_configuration?.logins)
        props.public_configuration?.logins?.forEach((type: any) => {
            if (type.id == index)
                flag = true
        })
        return flag
    }

    //console.log("[URL PATH] ", window.location.href)

    return (

        <div className="Login _flex flex-1 flex-direction-column h-100 justify-content-center align-items-center">
            { (props.loading || props.googleLoading) ? (
                <div className="row justify-content-center container-opaque">
                    <div className="loader">
                    </div>
                </div>

            )
                : (
                    <div >
                        <div className="login-img"></div>
                        <div className="_flex login-form flex-direction-column justify-content-between align-items-center px-4">
                            {/* <h1 className="_flex login-title"><FormattedMessage id="login.title" defaultMessage="Covid-19" /></h1> */}
                            <div className="_flex col-md-6 col-xs-12 flex-direction-column">
                                <img src={logo} className="w-100" />
                                <h2 className="_flex login-subtitle"><FormattedMessage id="login.title" defaultMessage="Covid-19" /> <FormattedMessage id="login.subtitle" defaultMessage="Tracker" /></h2>
                            </div>


                            {!checkLoginMethod(1) ? null : (
                                <form className="col-md-6 col-xs-12  _flex flex-direction-column justify-content-center">
                                    <div className="_flex w-100">
                                        <FormattedMessage id="login.placeholder.email" defaultMessage="email">
                                            {(placeholder: string) => (
                                                <Input elementType="input"
                                                    fluid
                                                    icon="icon icon-cv19-user"
                                                    placeholder={placeholder}
                                                    changed={changeEmail}
                                                    type="email"
                                                    containerClass="ui left icon input w-100"
                                                    className="my-2 rounded-input w-100 fat"
                                                    value={email}></Input>
                                            )}
                                        </FormattedMessage>
                                    </div>

                                    <div className="_flex w-100">
                                        <FormattedMessage
                                            id="login.placeholder.password"
                                            defaultMessage="password">
                                            {(placeholder: string) => (
                                                <Input elementType="input"
                                                    fluid
                                                    icon="icon icon-cv19-password"
                                                    placeholder={placeholder}
                                                    changed={changePassword}
                                                    type="password"
                                                    containerClass="ui left icon input w-100"
                                                    className="my-2 rounded-input fat"
                                                    value={password}></Input>

                                            )}
                                        </FormattedMessage>
                                    </div>

                                    <div className="_flex w-100 justify-content-center">
                                        <Button
                                            btntype="OK green-bg px-4 rounded-input w-100 my-2"
                                            clicked={onSubmit}
                                            loading={props.loading}
                                            disabled={(!email || email === "") || (!password || password === "")}>
                                            <FormattedMessage id="login.button" defaultMessage="Login" />
                                        </Button>
                                    </div>

                                    <div className="_flex w-100 justify-content-center">
                                        <Link className="forgot-link" to="/forgot" replace ><FormattedMessage id="login.forgot.message" defaultMessage="forgot password?" /></Link>
                                    </div>
                                </form>


                            )}

                            {!checkLoginMethod(2) ? null : (
                                <div className="col-md-6 col-xs-12">
                                    <Button
                                        btntype="OK white-bg px-4 w-100 rounded-input my-2 _flex align-items-center justify-content-center"
                                        clicked={googleLogin}
                                        loading={props.googleLoading}>
                                        <img src={GoogleButton} className="" style={{ maxHeight: '20px', marginRight: '.5em', display: props.googleLoading ? 'none' : 'block' }} /> <span><FormattedMessage id="login.google" defaultMessage="Login With Google" /></span>
                                    </Button>
                                </div>)}

                            {!checkLoginMethod(3) ? null : (
                                <div className="col-md-6 col-xs-12">
                                    <Button
                                        btntype="OK white-bg px-4 rounded-input w-100 my-2 _flex align-items-center justify-content-center"
                                        clicked={microsoftLogin}
                                        loading={props.microsoftLoading}>
                                        <img src={MicrosoftButton} className="" style={{ maxHeight: '20px', marginRight: '.5em', display: props.microsoftLoading ? 'none' : 'block' }} /> <span><FormattedMessage id="login.microsoft" defaultMessage="Login With Microsoft" /></span>
                                    </Button>
                            </div>)}


                        </div>
                    </div >)}

            {

                <Modal
                    size="mini"
                    open={openModalNewPassword}
                >
                    <Modal.Header>
                        <FormattedMessage id="app.generic.passwordNew" defaultMessage="" />
                    </Modal.Header>
                    <Modal.Content>
                        {/*<div style={{ fontSize: '18px' }}>
                            <FormattedMessage id="app.generic.passwordNew" defaultMessage="" />
                        </div>*/}
                        <Form.Input  type='password' onChange={onChangeNewPassword}/>
                        
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="containerButton" style={{ marginBottom: '10px' }}>
                            <UIButton loading={props.inlineLoading} className='OK grey-bg px-4 w-100 rounded-input my-3'
                                onClick={() => setOpenModalNewPassword(false)}>
                                <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                            </UIButton>
                            <UIButton loading={props.inlineLoading} className='OK green-bg px-4 w-100 rounded-input my-3'
                                onClick={() => enterPasswordNew()}>
                                <FormattedMessage id="login.button" defaultMessage="Login" />
                            </UIButton>
                        </div>
                    </Modal.Actions>
                </Modal>

            }

        </div >
    );
};

export default LoginForm;
