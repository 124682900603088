import { BaseModel } from "./Base";

export class Entrance extends BaseModel {
  guid: string;
  first_entrance: string;
  last_entrance: string;
  number_of_employees: string;
  slot_duration: string;
  auto_approve: boolean;
  no_show_timeout: string;
  book_all_day: boolean;

  constructor() {
    super();
    this.guid = "";
    this.first_entrance = "2020-05-01 09:00";
    this.last_entrance = "2020-05-01 19:00";
    this.number_of_employees = "5";
    this.slot_duration = "480";
    this.auto_approve = false;
    this.no_show_timeout = "60";
    this.book_all_day = false;
  }
}

export class DefaultEntrance extends Entrance {
  default: boolean = true;
}
