import { AppAction } from "../../../models/actions/AppAction";

/**
 *
 * @param app
 */
export const setApp = (app: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(AppAction.SwitchAppSuccess(app));
  };
}

/**
 *
 * @param app
 */
export const switchApp = (app: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(AppAction.SwitchAppRequest(app));
    dispatch(AppAction.SwitchAppSuccess(app));
  };
}

// export const switchTab = () => {
//   return (dispatch: any, getState: any) => {
//     dispatch(AppAction.SwitchTabRequest());
//     dispatch(AppAction.SwitchTabSuccess());
//   };
// }

export const switchTabRequest = () => {
  return (dispatch: any, getState: any) => {
    dispatch(AppAction.SwitchTabRequest());
  };
}

export const switchTabSuccess = () => {
  return (dispatch: any, getState: any) => {
    dispatch(AppAction.SwitchTabSuccess());
  };
}

export const setCaslStatus = (initialized: boolean) => {
    return (dispatch: any, getState: any) => {
      dispatch(AppAction.SetCaslStatusSuccess(initialized))
    }
}