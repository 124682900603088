import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import { getUsers } from '../../../store/actions/backoffice/users';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import LogTab from '../../../components/backoffice/Tabs/Common/Log/Log';
import { exportData, getLogs } from '../../../store/actions/backoffice/log';



const LogTabContainer = (props: any) => {

    useEffect(() => {
        console.log("CREATO LOG TAB CONTAINER")
    }, [])


    return (
        <div className="h-100">
            <LogTab 
            data={props.logs} 
            total_items={props.total_items} 
            onGetData={props.getLogs} 
            loading={props.loading} 
            loading_export={props.loading_export}
            auth={props.auth} 
            onExportData={(data?: any) => props.onExportData(data)}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getLogs: (created_at_start: string, created_at_end: string, page: number, perPage:number) => { dispatch(getLogs(created_at_start, created_at_end, page, perPage)) },
    onExportData: (data?: any) => { dispatch(exportData(data)) },
})

const mapStateToProps = (state: AppState) => ({
    loading: state.log.loading,
    logs: state.log.logs,
    total_items: state.log.total_items,
    auth: state.authReducer.auth,
    loading_export: state.booking.loading_export,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LogTabContainer))