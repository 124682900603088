import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { startLogin, googleLogin, microsoftLogin, checkForRedirectLogin } from '../store/actions/auth';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AppState } from '../interfaces/AppState';
import { setApp } from '../store/actions/backoffice/app';
import { Common } from '../utils/Common';
import { isGoogleRedirecting, LOCAL_STORAGE_GOOGLE_REDIRECT } from '../store/localStorage';
import ForgotPasswordForm from '../components/ForgotPassword/ForgotPasswordForm';
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm';
import { resetForgottenPassword } from '../store/actions/backoffice/users';
import { getPublicConfigurations } from '../store/actions/backoffice/settings';


const ResetPasswordContainer = (props: any) => {

  useEffect(() => {
    console.log("[ Reset Password nonce ] ", props.match.params.nonce)

    props.onGetPublicConfigurations()
  }, [])

  const handleResetPassword = (password: string, passwordConfirm: string) => {
    props.resetPassword(password, passwordConfirm, props.match.params.nonce)
    //props.history.push('/login')
  }

  return (
    <div
      className="_flex flex-1 flex-direction-column h-100"
    >

      <ResetPasswordForm
        loading={props.loading}
        activeApp={props.activeApp}
        onSetApp={props.setApp}
        onSubmit={(password: any, passwordConfirm: any) => handleResetPassword(password, passwordConfirm)}
        public_configuration={props.public_configuration}
      />


    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.authReducer.loading,
  activeApp: state.app.activeApp,
  public_configuration: state.settings.public_configuration,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPassword: (password: string, passwordConfirm: string, nonce: string) => dispatch(resetForgottenPassword(password, passwordConfirm, nonce)),
  setApp: (app: string) => dispatch(setApp(app)),
  onGetPublicConfigurations: () => dispatch(getPublicConfigurations()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ResetPasswordContainer)));
