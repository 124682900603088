import { SettingsState } from "../../../interfaces/SettingsState";
import { Entrance, DefaultEntrance } from "../../../models/Entrance";
import { SettingsAction } from "../../../models/actions/SettingsAction";
import { BookingAction } from "../../../models/actions/BookingAction";
import { BookingState } from "../../../interfaces/BookingState";
import { BaseReservation, Reservation } from "../../../models/Reservation";

const defaultstate: BookingState = {
  loading: false,
  slots: [],
  list: [],
  total_items: 0,
  reservation: undefined,
  loading_export: false,
};

export default (state = defaultstate, action: BookingAction) => {
  switch (action.type) {
    case BookingAction.ON_EXPORT_BOOKING_REQUEST:
      return {
        ...state,
        loading_export: true,
      };
      case BookingAction.ON_EXPORT_BOOKING_SUCCESS:
        return {
          ...state,
          loading_export: false,
        };
    case BookingAction.ON_GENERIC_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BookingAction.ON_GENERIC_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingSuccess: true,
      };
    case BookingAction.ON_GETRESERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.response.items,
        total_items: action.response.total_items,
      };
    case BookingAction.ON_GETRESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: action.response,
      };
    case BookingAction.ON_SLOTS_SUCCESS:
      return {
        ...state,
        slots: action.response,
        loading: false,
      };
    case BookingAction.ON_GENERIC_BOOKING_ERROR:
      return {
        ...state,
        loading: false,
        bookingSuccess: false,
      };
    default:
      return state;
  }
};
