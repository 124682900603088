import { BaseModel } from "../Base";

export class Action extends BaseModel {
  type: string;
  response: any;

  constructor(type: string, response: any) {
    super();
    this.type = type;
    this.response = response;
  }

  onRequest() {
    return {
      type: this.type,
      response: this.response,
    };
  }
}
