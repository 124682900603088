import { UsersState } from "../../../interfaces/Users";
import { UserAction } from "../../../models/actions/UserAction";

const defaultstate: UsersState = {
  loading: false,
  items: [],
  closeModal: false,
  total_items: 0,
  roles: [],

};

export default (state = defaultstate, action: any) => {
  switch (action.type) {
    case UserAction.USER_REQUEST:
      return {
        ...state,
        items: action.response,
        closeModal: "loadingUser",
      };
    case UserAction.GENERIC_USER_REQUEST:
      return {
        ...state,
        closeModal: false,
        loading: true,
      };
    case UserAction.GET_USERS:
      return {
        loading: false,
        items: action.response.items,
        totalRecords: action.response.total_items,
        closeModal: true,
      };
    case UserAction.UPDATE_USER:
    case UserAction.CREATE_USER:
    case UserAction.DELETE_USER:
    case UserAction.UPLOAD_USERS:
      return {
        ...state,
        loading: false,
        closeModal: true,
      };
    case UserAction.USER_ERROR:
      return {
        ...state,
        items: action.response,
        loading: false,
        closeModal: false,
      };
      case UserAction.ROLES_SUCCESS:
        return {
          ...state,
          roles: action.response,
        };
    default:
      return state;
  }
};
