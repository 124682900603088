


export interface UsersLogState {
    loading: boolean;
    items: UserLog[];
  }


  export class UserLog {
    action_guid: number;
    user_guid: String;
    email: String;
    fe_view_guid: String;
    description: String;
    be_api_guid: String; //ForTestingPurpose

    constructor(data?: any) {
    
        this.action_guid = 0;
        this.user_guid = "";
        this.fe_view_guid = "";
        this.description = "";
        this.be_api_guid = ""; //ForTestingPurpose
        this.email = "";
 
        if (data) {
            this.action_guid = data.action_guid;
            this.user_guid = data.user_guid;
            this.fe_view_guid = data.fe_view_guid;
            this.description = data.description;
            this.be_api_guid = data.be_api_guid; //ForTestingPurpose
            this.email = data.email;
        }
      }

  }