import React, { useState, useEffect } from 'react'
import './Header.scss'
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { LANG_SUPPORT } from '../../../utils/localization/supported-languages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlContext } from '../../../utils/IntlContext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { APP } from '../../../interfaces/SwitchState';
import { IsAdmin } from '../../../models/User';
import { isEmpty } from "lodash";
import logo from '../../../assets/imgs/backoffice/logo-no-claim.png';
import { ImWindows } from 'react-icons/im';
import Button from '../../UI/Button/Button';
import { history } from '../../../routing/AppRouter';
import { Link } from 'react-router-dom';


const Header = (props: any) => {
    const [switchPanel, setSwitchPanel] = useState<OverlayPanel>()
    const { switchToLanguage, locale } = React.useContext(IntlContext);
    const [currentLang, setCurrentLang] = useState(locale)
    const APPS: any = {
        COVID: 'Contact Tracker',
        WSMAN: 'Workplace Manager',
    }

    const onSetApp = (app: string) => {
        switchPanel?.hide();
        window.location.reload();
        props.onSetApp(app);
    }

    useEffect(() => {
        if (props.auth && props.auth.companies && props.auth.companies.length == 0 && !props.loadingCompanies) {
            props.onGetCompanies()
        }
    }, [props.auth])


    const hasMoreThanOneCompany = () => {
        return props.auth && props.auth.companies && props.auth.companies.length > 1
    }
    //TODO: traduzioni

    const routeChange = () => {
        history.push("/list");
    }
    const newreservationChange = () => {
        history.push("/newreservation");
    }
    const homeChange = () => {
        history.push("/");
    }
    return (<div className="Header green-bg">
        <div className="h-100 _flex justify-content-between align-items-center px-2 py-2 ">
            <div className="h-100 _flex justify-content-between align-items-center px-2 py-2 ">
                <div className="logo-container">
                    <img src={logo} className="logo" />
                    <div className="sub-logo">
                        {
                            props.activeApp === APP.WSMANAGER ?
                                <FormattedMessage id="app.wsmanager.title" defaultMessage="WS Manager" />
                                :
                                <FormattedMessage id="app.covid.title" defaultMessage="Covid-19 Tracker" />
                        }
                        {" " + process.env.REACT_APP_ENV}
                    </div>
                </div>

                <div></div>
                {/* <div className="button-container">
                    <Button
                        label
                        clicked={homeChange}
                    >
                        <Link to="/" className='headerLink' replace ><FormattedMessage id="headerTitle.bookingold" defaultMessage="Prenotazione V2" /></Link>

                    </Button>
                </div> */}
                {/* <div className="button-container">
                    <Button
                        label
                        clicked={newreservationChange}
                    >
                        <Link to="/newreservation" className='headerLink' replace ><FormattedMessage id="headerTitle.bookingnew" defaultMessage="Prenotazione V3" /></Link>

                    </Button>
                </div> */}

                {/* <div className="button-container">

                    <Button
                        label
                        clicked={routeChange}
                    >
                        <Link to="/list" className='headerLink' replace ><FormattedMessage id="app.tab.reservations" defaultMessage="Le mie prenotazioni" /></Link>
                    </Button>
                </div> */}
            </div>
            <div className="_flex justify-content-end align-items-center">
                {/* 
                {
                    hasMoreThanOneCompany() ?
                        <div className="item-menu">
                            <PrimeDropdown
                                value={props.auth.selectedCompany}
                                options={props.auth.companies}
                                appendTo={document.body}
                                optionLabel="name"
                                optionValue="guid"
                                className="my-2 mr-2"
                                onChange={(e: any) => {
                                    console.log("COMPANY SELECTED", e)

                                    if (e.target.value && !isEmpty(e.target.value)) {
                                        props.onSwitchCompany(e.target.value)
                                    }
                                }}
                            />
                        </div>
                        :
                        ""
                } */}
                <div className="item-menu">
                    <PrimeDropdown
                        value={currentLang}
                        options={LANG_SUPPORT}
                        appendTo={document.body}
                        className="my-2 mr-2"
                        onChange={(e: any) => {
                            switchToLanguage(e.value)
                        }}
                    />
                </div>
                <h3 className="m-2 username">{props.auth.name} {props.auth.surname}</h3>
                <i className="icon-cv19-switcher mx-2" onClick={(e) => switchPanel?.toggle(e)} aria-controls="overlay_panel" aria-haspopup={true} ></i>
                <i className="icon-cv19-logout mx-2" onClick={props.onLogout}></i>
            </div>
        </div >
        <OverlayPanel ref={(el: OverlayPanel) => setSwitchPanel(el)} id="overlay_panel" >
            <div className="_flex justify-content-center align-items-center">
                {props.auth.roles.map((item: any, i: number) => (
                    (item.app === APP.COVID19 && IsAdmin(item.roles)) || item.app === APP.WSMANAGER ?
                        <div className="_flex justify-content-center align-items-center mx-4 flex-direction-column">
                            <div className={`circle _flex justify-content-center align-items-center my-2 ${props.activeApp === item.app ? 'cgreen-bg' : 'grey-bg'}`} onClick={(e: any) => { onSetApp(item.app) }}>
                                {item.app === APP.WSMANAGER ? <i className="icon-cv19-levels" ></i> : <i className="icon-cv19-virus" ></i>}
                            </div>
                            <div>{APPS[item.app]}</div>
                        </div>
                        :
                        ""
                ))}
            </div>
        </OverlayPanel>
    </div >)
}

export default injectIntl(Header) 