import { Action } from "./Action";
import { Motivation } from "../Motivation";

export class SettingsAction extends Action {
  static ON_GENERIC_REQUEST: string = "ON_GENERIC_REQUEST";
  static ON_GENERIC_SUCCESS: string = "ON_GENERIC_SUCCESS";
  static ON_GETMOTIVATION_SUCCESS: string = "ON_GETMOTIVATION_SUCCESS";
  static ON_GETENTRANCE_SUCCESS: string = "ON_GETENTRANCE_SUCCESS";
  static ON_GETTASKS_SUCCESS: string = "ON_GETTASKS_SUCCESS";
  static ON_GENERIC_ERROR: string = "ON_GENERIC_ERROR";
  static ON_MOTIVUPD_REQUEST: string = "ON_MOTIVUPD_REQUEST";
  static ON_MOTIVUPD_ERROR: string = "ON_MOTIVUPD_ERROR";
  static ON_MOTIVUPD_SUCCESS: string = "ON_MOTIVUPD_SUCCESS";
  static ON_ENTRAUPD_SUCCESS: string = "ON_ENTRAUPD_SUCCESS";
  static ON_ENTRAUPD_ERROR: string = "ON_ENTRAUPD_ERROR";
  static ON_GET_IMPORTS_REQUEST = "USER_IMPORTS_REQUEST";
  static ON_GET_IMPORTS_SUCCESS = "USER_IMPORTS_SUCCESS";
  static ON_GET_LOGIN_TYPES_SUCESS = "ON_GET_LOGIN_TYPES_SUCESS";
  static ON_GET_PUBLIC_CONFIGURATION_SUCESS = "ON_GET_PUBLIC_CONFIGURATION_SUCESS";
  static ON_GET_REGEX_TYPES_SUCESS = "ON_GET_REGEX_TYPES_SUCESS";

  

  constructor(type: string, response: any) {
    super(type, response);
    this.response = response;
  }

  static onImportRequest = (data?: any) => {
    return new SettingsAction(SettingsAction.ON_GET_IMPORTS_REQUEST, data).toJSON();
  };

  static onImportSuccess = (data?: any) => {
    return new SettingsAction(SettingsAction.ON_GET_IMPORTS_SUCCESS, data).toJSON();
  };

  static OnGenericRequest = () => {
    return new SettingsAction(SettingsAction.ON_GENERIC_REQUEST, {}).toJSON();
  };

  static OnGenericSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GENERIC_REQUEST,
      payload
    ).toJSON();
  };

  static OnGetMotivationSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GETMOTIVATION_SUCCESS,
      payload
    ).toJSON();
  };

  static OnGetLoginTypesSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GET_LOGIN_TYPES_SUCESS,
      payload
    ).toJSON();
  };

  static OnGetRegexTypesSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GET_REGEX_TYPES_SUCESS,
      payload
    ).toJSON();
  };

  static OnGetPublicConfigurationSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GET_PUBLIC_CONFIGURATION_SUCESS,
      payload
    ).toJSON();
  };

  static OnGetEntranceSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GETENTRANCE_SUCCESS,
      payload
    ).toJSON();
  };

  static OnGetTasksSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_GETTASKS_SUCCESS,
      payload
    ).toJSON();
  };

  static OnGenericError = () => {
    return new SettingsAction(SettingsAction.ON_GENERIC_ERROR, {}).toJSON();
  };

  static OnUpdateEntranceSuccess = (payload: any) => {
    return new SettingsAction(
      SettingsAction.ON_ENTRAUPD_SUCCESS,
      payload
    ).toJSON();
  };

  /**
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   */
  static OnUpdateMotivationRequest = (
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    let tmpMotivations = SettingsAction.SetMotivationLoading(
      true,
      motivation,
      motivations
    );
    return new SettingsAction(
      SettingsAction.ON_MOTIVUPD_REQUEST,
      tmpMotivations
    ).toJSON();
  };

  /**
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   * @param  {string} action
   */

  static OnUpdateMotivationSuccess = (
    motivation: Motivation,
    motivations: Motivation[],
    action: string
  ) => {
    let tmpMotivations = SettingsAction.SetMotivationLoading(
      false,
      motivation,
      motivations
    );

    switch (action) {
      case Motivation.TRASH:
        tmpMotivations = SettingsAction.RemoveMotivation(
          motivation,
          motivations
        );
        break;
      case Motivation.ADD:
      case Motivation.EDIT:
        motivation.loading = false;
        tmpMotivations = SettingsAction.ReplaceMotivation(
          motivation,
          motivations
        );
        break;
    }
    return new SettingsAction(
      SettingsAction.ON_MOTIVUPD_SUCCESS,
      tmpMotivations
    ).toJSON();
  };

  static OnUpdateMotivationError = (
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    let tmpMotivations = SettingsAction.SetMotivationLoading(
      false,
      motivation,
      motivations
    );

    return new SettingsAction(
      SettingsAction.ON_MOTIVUPD_ERROR,
      tmpMotivations
    ).toJSON();
  };
  static OnUpdateEntranceError = () => {
    return new SettingsAction(SettingsAction.ON_ENTRAUPD_ERROR, {}).toJSON();
  };

  /**
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   * @param  {string} action
   */

  static OnAddMotivationError = (
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    let tmpMotivations = SettingsAction.RemoveMotivation(
      motivation,
      motivations
    );
  };

  /**
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   */
  static ReplaceMotivation = (
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    let found: boolean = false;
    for (let i in motivations) {
      if (motivations[i].guid === motivation.guid) {
        motivations[i] = motivation;
        found = true;
        break;
      }
    }

    if (!found) {
      motivations.unshift(motivation);
    }
    return motivations;
  };

  /**
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   */
  static RemoveMotivation = (
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    for (let i in motivations) {
      if (motivations[i].guid === motivation.guid) {
        motivations.splice(parseInt(i), 1);
        break;
      }
    }
    return motivations;
  };

  /**
   * @param  {boolean} loading
   * @param  {Motivation} motivation
   * @param  {Motivation[]} motivations
   */
  static SetMotivationLoading = (
    loading: boolean,
    motivation: Motivation,
    motivations: Motivation[]
  ) => {
    let tmpMotivations = [...motivations];
    let found: boolean = false;
    for (var i in tmpMotivations) {
      if (tmpMotivations[i].guid === motivation.guid) {
        tmpMotivations[i].loading = loading;
        found = true;
      }
    }
    if (!found) {
      motivation.loading = true;
      tmpMotivations.unshift(motivation);
    }

    return tmpMotivations;
  };

  static IsRequesting = (state: string) => {
    return state.toUpperCase().includes("_REQUEST");
  };
}
