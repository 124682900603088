import { AppConfigState, APP } from "../../../interfaces/SwitchState";
import { AppAction } from "../../../models/actions/AppAction";
import _ from "lodash";
import { loadAppState, loadCaslState } from "../../localStorage";

const persistedData = loadAppState();
console.log("PERSISTED APP STATE", persistedData);
let defaultstate: AppConfigState = (persistedData && persistedData !== '')
  ? {
    activeApp: persistedData,
    caslInitialized: false,
    loading: false,
    tabChange: false,
  }
  : {
    activeApp: APP.WSMANAGER,
    caslInitialized: false,
    loading: false,
    tabChange: false,
  };

export default (state = defaultstate, action: AppAction) => {

  switch (action.type) {
    case AppAction.SWITCH_APP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AppAction.SWITCH_APP_SUCCESS:

      return {
        ...state,
        loading: false,
        activeApp: action.response,
      };
    case AppAction.SET_CASL_STATUS_SUCCESS:
      return {
        ...state,
        caslInitialized: action.response,
      };
    case AppAction.SWITCH_TAB_REQUEST:
      return {
        ...state,
        tabChange: true,
      };
    case AppAction.SWITCH_TAB_SUCCESS:
      return {
        ...state,
        tabChange: false,
      };
    default:
      return state;
  }

  
};
