import { BaseModel } from "./Base";

export class BaseMotivation extends BaseModel {
  guid: string;
  text: string;

  constructor(data?:any) {
    super();
    this.guid = "";
    this.text = "";

    if (data){
        this.guid = data.guid
        this.text = data.text
    }
  }
}

export class Motivation extends BaseMotivation {
  static ADD: string = "ADD";
  static EDIT: string = "EDIT";
  static TRASH: string = "TRASH";

  loading: boolean;

  constructor() {
    super();
    this.loading = false;
  }
}
