import { LogState } from "../../../interfaces/LogState";
import { LogAction } from "../../../models/actions/LogAction";

const defaultstate: LogState = {
  loading: false,
  logs: [],
  total_items: 0
};

export default (state = defaultstate, action: any) => {
  switch (action.type) {
    case LogAction.ON_GENERIC_LOG_REQUEST:
      return {
        ...state,
        loading:true,
      };
    case LogAction.ON_GENERIC_LOG_SUCCESS:
      
      return {
        ...state,
        logs: action.response.items,
        total_items: action.response.total_items,
        loading:false,
      };
    case LogAction.ON_GENERIC_EXPORT_LOG_SUCCESS:
          
        return {
          ...state,
          loading:false,
      };
    case LogAction.ON_GENERIC_LOG_ERROR:
      return {
        ...state,
        loading:false,
      };
    default:
      return state;
  }
};
