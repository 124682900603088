import React from 'react'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

const AppRoute = (props:any) => {
    const {key, _private, component, path, redirectTo } = props
    
    if(_private){
        return <PrivateRoute component={component} path={path} redirectTo={redirectTo}/>
    }

    //console.log("[Public Route] ",  props.computedMatch);
    return <PublicRoute component={component} path={path} {...props} />
}

export default AppRoute