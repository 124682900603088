import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import CheckInReportsTab from '../../../components/backoffice/Tabs/Covid/CheckInReports/CheckInReports';
import { CheckInProps } from '../../../interfaces/CheckInState';
import { exportData, getCheckInData } from '../../../store/actions/backoffice/checkin';

const CheckInReportsTabContainer = (props: CheckInProps) => {

    useEffect(() => {

    }, [])


    return (
        <div className="h-100">
            <CheckInReportsTab
                onSearchUsers={() => {/* TODO */}}
                checkinData={props.users} 
                onGetData={(data?: any) => { props.getCheckInData(data) }} 
                loading={props.loading} 
                onExportData={(data?: any) => { props.onExportData(data) }}
                />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getCheckInData: (data?: any) => { dispatch(getCheckInData(data)) },
    onExportData: (data?: any) => { dispatch(exportData(data)) },
})

const mapStateToProps = (state: AppState) => ({
    loading: state.checkin.loading,
    users: state.checkin,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckInReportsTabContainer))