import React, { useState, useEffect } from 'react';
import { Growl } from 'primereact/growl';
import { injectIntl } from 'react-intl';
import { Toast } from '../../../models/Toast';
import './Toast.scss';

const ToastComponent = (props: any) => {
    const [growl, setGrowl] = useState<Growl | null>()
    const { formatMessage } = props.intl;

    useEffect(() => {

        for (var i in props.toasts) {
            let toast: Toast = new Toast("").set(props.toasts[i])
            let title = formatMessage({ id: toast.title })
            let text = toast.text

            if (toast.toBeLocalized) {
                text = formatMessage({ id: toast.text })
            }

            growl?.show({ severity: toast.type, summary: title, detail: text, sticky: toast.sticky, closable: toast.sticky })
        }

    }, [props.toasts])

    return <Growl ref={(el) => setGrowl(el)} position="topright" ></Growl>
}

export default injectIntl(ToastComponent)