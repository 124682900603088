import {
  LOGGED_IN,
  LOGGED_OUT,
  REQUEST_LOGIN,
  REQUEST_LOGIN_GOOGLE,
  USER_LOGOUT,
  REQUEST_COMPANIES,
  GOT_COMPANIES,
  SWITCH_COMPANY, REQUEST_LOGIN_MICROSOFT,
  PASSWORD_NEW,
  PASSWORD_NEW_ENTERED,

} from "../actions/auth";
import { loadState } from "../localStorage";
import { history } from "../../routing/AppRouter";
import _ from "lodash";

const persistedData = loadState();
let defaultstate = _.isEmpty(persistedData)
  ? {
    state: "LOGGEDOUT",
    isUserAuthenticated: false,
    auth: persistedData,
    loading: false,
    googleLoading: false,
    microsoftLoading: false,
    loadingCompanies: false,
    passwordNew: false,
    err: ""
  }
  : {
    state: "LOGGEDIN",
    isUserAuthenticated: true,
    auth: persistedData,
    loading: false,
    googleLoading: false,
    microsoftLoading: false,
    loadingCompanies: false,
    passwordNew: false,
    err: ""
  };

export default (state = defaultstate, action: any) => {
  switch (action.type) {
    case LOGGED_IN:
      //history.push("/");
      return {
        auth: action.auth,
        state: "LOGGEDIN",
        loading: false,
        googleLoading: false,
        microsoftLoading: false,
        isUserAuthenticated: true,
      };
    case LOGGED_OUT:
      //history.push("/");
      return {
        auth: {},
        state: "LOGGEDOUT",
        loading: false,
        isUserAuthenticated: false,
      };
    case REQUEST_LOGIN:
      // history.push("/");
      return {
        ...state,
        state: "LOGGING",
        loading: true,
        isUserAuthenticated: false,
      };
    case REQUEST_COMPANIES:
      // history.push("/");
      return {
        ...state,
        loadingCompanies: true
      };
    case SWITCH_COMPANY:
    case GOT_COMPANIES:
      // history.push("/"); 
      return {
        ...state,
        loadingCompanies: false,
        auth: action.auth
      };
    case REQUEST_LOGIN_GOOGLE:
      // history.push("/");
      return {
        ...state,
        state: "LOGGING",
        googleLoading: true,
        isUserAuthenticated: false,
      };
    case REQUEST_LOGIN_MICROSOFT:
      // history.push("/");
      return {
        ...state,
        state: "LOGGING",
        microsoftLoading: true,
        isUserAuthenticated: false,
      };
    case USER_LOGOUT:
      // history.push("/");
      return {
        ...state,
        state: "LOGGEDOUT",
        loading: false,
        isUserAuthenticated: false,
      };
    case PASSWORD_NEW:
      return {
        ...state,
        passwordNew: true,
        err: action.err,

      }
    case PASSWORD_NEW_ENTERED:
      return {
        ...state,
        passwordNew: false,
      }
    default:
      return state;
  }
};
