import React, { useState, useEffect } from 'react'
import { User } from '../../../../../../models/User'
import { Modal, Header, Icon, Dropdown, Form } from 'semantic-ui-react'
import Button from '../../../../../UI/Button/Button'
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from '../../../../../UI/Input/Input';
import { MultiSelect } from 'primereact/multiselect';
import './ReservationModal.scss';
import LocationReservation from '../../LocationBooking/LocationReservation';
import { SettingsType } from '../../../../../../models/Settings';
import { BaseReservation } from '../../../../../../models/Reservation';

const ReservationModal = (props: any) => {

    const { formatMessage } = props.intl;

    useEffect(() => {
        console.log("PROPS", props)
        // console.log("LOADING USERS", props.loading)
        if (props.reservation) {
        }
    }, [])



    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.onCloseModal()}
            basic
            size='small'
            className="ReservationModal"
            centered={true}
            closeIcon={true}
        >
            <Header><i className="icon-cv19-tag"></i><FormattedMessage id="reservations.modal.title" defaultMessage="Edit Reservation"></FormattedMessage></Header>
            <Modal.Content>
                <LocationReservation
                    wrapperClass="wrapper"
                    reservation={props.reservation}
                    onGetTimetables={props.onGetTimetables}
                    bookingState={props.bookingState}
                    selectedNode={props.selectedNode}
                    settings={props.settings}
                    onGetMotivations={props.onGetMotivations}
                    onGetEntrance={props.onGetEntrance}
                    onGetUsers={props.onGetUsers}
                    usersState={props.usersState}
                    authState={props.authState}
                    onBooking={props.onBooking}
                    onClearNode={props.onCloseModal}
                    onError={props.onError}
                />
            </Modal.Content>
        </Modal>
    )
}

export default injectIntl(ReservationModal)  