



export class UsersLog {

    /********************************************/
    /* CONSTS DECLARATION FOR USERS LOG PURPOSE */
    /********************************************/

    /* action_guid - List of all the actions type */
    static AUTH_LOGIN: number = 1; //"AUTH_LOGIN"
    static AUTH_LOGOUT: number = 2; //"AUTH_LOGOUT"
    static ADD_LOCATION_ACTION: number = 3;
    static UPDATE_LOCATION_ACTION: number = 4;
    static DELETE_LOCATION_ACTION: number = 5;
    static QRCODE_CHECKIN_ACTION: number = 6;
    static AUTH_LOGIN_FAILED: number = 7; //"AUTH_LOGIN"
    static AUTH_RESET_PASSWORD:number = 8;
    static AUTH_FORGOT_PASSWORD:number = 9;

    /*  fe_view_guid - List of all the views  */
    // Common
    static LOGIN_PAGE_VIEW: String = "LOGIN_PAGE";
    static HEADER_TAB_VIEW: string = "HEADER_TAB";
    static FORGOT_PAGE_VIEW:string = "FORGOT_PAGE";
    static RESET_PAGE_VIEW:string = "RESET_PAGE";
    // Desktop Version
    static HOME_PAGE_VIEW: String = "HOME_PAGE";
    static LOCATIONS_PAGE_VIEW: String = "LOCATIONS_PAGE";
    static BOOKING_PAGE_VIEW: String = "BOOKING_PAGE";
    //Mobile Version
    static HOME_PAGE_MOBILE_VIEW: String = "HOME_PAGE";

    /*  be_api_guid - JUST FOR TEST PURPOSE, IT MUST BE PASSED BACKEND SIDE  */
    static LOGIN_FIREBASE_API: String = "LOGIN_FIREBASE_API";
    static LOGIN_MICROSOFT_API: String = "LOGIN_MICROSOFT_API";
    static LOGIN_API: String = "LOGIN_API";
    static LOGOUT_API: String = "LOGOUT_API";
    static ADD_LOCATION_API: String = "ADD_LOCATION_API";
    static UPDATE_LOCATION_API: String = "UPDATE_LOCATION_API";
    static DELETE_LOCATION_API: String = "DELETE_LOCATION_API";
    static QRCODE_CHECKIN_API: String = "QRCODE_CHECKIN_API";
    static RESET_PASSWORD_REQUEST_API: string = "RESET_PASSWORD_REQUEST_API"
    static RESET_PASSWORD_API: string = "RESET_PASSWORD_API"

}