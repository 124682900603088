import React, { useEffect } from 'react';
import { AppState } from '../../../interfaces/AppState';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReportsTab from '../../../components/backoffice/Tabs/Common/Reports/Reports'
import { getReports, getLocationReports, exportData } from '../../../store/actions/backoffice/reports';
import { IsWsManager } from '../../../interfaces/SwitchState';
import { getLocations } from '../../../store/actions/backoffice/location';

const ReportsTabContainer = (props: any) => {


    useEffect(() => {
        props.getLocations(0)
    }, [props.auth?.selectedCompany])

    return <div className="h-100">
        <ReportsTab
            loading_export={props.loading_export}
            data={props.reports}
            auth={props.auth}
            locations={props.locations}
            onGetData={(locations: any, startDate: any, endDate: any) => {
                IsWsManager(props.activeApp) ?
                    props.getLocationReports(locations, startDate, endDate)
                    :
                    props.getReports(locations, startDate, endDate)
            }}
            loading={props.loading}
            exportLoading={props.exportLoading}
            activeApp={props.activeApp}
            onExportData={(data?: any) => props.onExportData(props.isModerator, props.activeApp, data)}
        />
    </div>
}


const mapDispatchToProps = (dispatch: any) => ({
    getReports: (guids: any[], date_start: any, date_end: any) => { dispatch(getReports(guids, date_start, date_end)) },
    getLocationReports: (guids: any[], date_start: any, date_end: any) => { dispatch(getLocationReports(guids, date_start, date_end)) },
    getLocations: (disabled: boolean) => { dispatch(getLocations(disabled, "", "", 1)) },
    onExportData: (isModerator: boolean, activeApp: string, data?: any) => { dispatch(exportData(isModerator, activeApp, data)) },
})

const mapStateToProps = (state: AppState) => ({
    loading: state.reports.loading,
    reports: state.reports.reports,
    locations: state.location.list,
    activeApp: state.app.activeApp,
    auth: state.authReducer.auth,
    loading_export: state.booking.loading_export,
    exportLoading: state.reports.exportLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportsTabContainer))