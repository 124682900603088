import { Api, HTTP_STATUS } from "../../../api/Api";
import { CommonApi } from "../../../api/CommonApi";
import { startLogout } from "../auth";

export function createUsersLog(data: any) {
    
    let authStr: any = localStorage.getItem("auth");
    if(authStr != ("" || null || undefined) ) { //Check If exists LocalStorage auth data
        let auth: any = JSON.parse(authStr);
        data.user_guid = auth.guid;
    } else {
        data.user_guid = "0000-0000-0000-0000"
    }

    console.log("[---- CREATE USER LOG ----] ", data);

    CommonApi.CreateUserLog(data)
        .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
            return response.json();
        } })
        .then((response: any) => {
            console.log("[USERS LOG SUCCESS] ", response);
        })
        .catch((error) => {
            console.log("[USERS LOG ERROR] ", error);
        });       


}

export function getUsersLog(data?: any) {

}