import React, { useState, useEffect } from 'react'
import { Tab, Dropdown, Icon, Loader } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../UI/Button/Button';
import { Button as SUIButton } from 'semantic-ui-react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';


import './CheckInReports.scss';
import { GetDateAndTimeByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IntlContext } from '../../../../../utils/IntlContext';
import { AutoComplete } from 'primereact/autocomplete';


const CheckInReportsTab = (props: any) => {


  const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD').split("T")[0])
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD').split("T")[0])
  const [range, setRange] = useState([moment().startOf('day').toDate(), moment().endOf('day').toDate()])
  const [eventFilter, setEventFilter] = useState<any>()
  const [table, setTable] = useState<DataTable | undefined>()
  const { formatMessage } = props.intl;
  const moreOptions: any[] = [{ label: 'app.generic.sendmail', value: 'mail' }]
  const { locale, calendar } = React.useContext(IntlContext);

  const [firstIndex, setFirstIndex] = useState(0)
  const [perPage, setPerPage] = useState<any>(10)


  useEffect(() => {

  }, [])


  const onReload = () => {
    props.onGetData({page: (firstIndex / perPage) + 1, per_page: perPage, startDate, endDate})
  }

  const checkAppPermission = () => {
    let appPermission: any = localStorage.getItem("appPermission");
    if(appPermission === "ALL")
      return true;
    
    return false;
  }

  const checkApp = () => {
    let app: any = localStorage.getItem("app");
    if(app === "WSMAN")
      return true;
    
    return false;
  }

  const onSearch = () => {
    console.log("[COMPONENT DATA ] startDate: ", startDate, " - endDate: ", endDate);
    props.onGetData({page: (firstIndex / perPage) + 1, per_page: perPage, startDate, endDate})
  }
  
  const setDates = (e: any) => {
    console.log("RANGE", e)
    setRange(e.value)

    if (e.value.length > 1) {
      let tmp: any = e.value[0]
      setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])
      let tmp2: any = e.value[1]
      console.log("TMP", tmp, tmp2)
      setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])


    }
  }

  const enableSearchButton = () => {
    return endDate && startDate
  }

  const enableExportButton = () => {
    return endDate && startDate
  }


  const moreOptionsTemplate = (option: any) => {
    return (
      <FormattedMessage id={option.label} defaultMessage={option.label} />
    )
  }

  const sendMail = (mail: string) => {
    window.location.href = 'mailto:' + mail;
  }

  const actionTemplate = (rowData: any, column: any) => {
    return <div>
      {rowData.loading ?
        <Loader active inline size="mini" />
        :
        <FormattedMessage id="app.generic.more" defaultMessage="More">
          {(placeholder: string) => (
            <PrimeDropdown options={moreOptions} placeholder={placeholder}
              onChange={(e: any) => {
                switch (e.value) {
                  case 'mail':
                    sendMail(rowData.email)
                    break
                }
              }}
              appendTo={document.body}
              itemTemplate={moreOptionsTemplate}

            />
          )}
        </FormattedMessage>

      }
    </div>;
  }

  const onExport = () => {
    props.onExportData({ startDate: startDate, endDate: endDate});
  }

  const headerName = (field: string) => {
    return formatMessage({ id: "checkin.table." + field })
  }

  const onPage = (event: any) => {
    setFirstIndex(event.first)
    props.onGetData({ page: (event.first / perPage) + 1, per_page: perPage, startDate: startDate, endDate: endDate})

  } 


  const dateTemplate = (rowData: any, column: any) => {
    if (!rowData || !rowData[column.field]) return 'NONE'
    return GetDateAndTimeByLocale(locale, rowData[column.field])

  }

  const getFilterPlaceholder = (field: string) => {
    return formatMessage({ id: "checkin.filters." + field })
  }


  const onEventFilter = (event: any) => {
    let filter = moment(event.value, 'YYYY-MM-DD').format().split("T")[0]
    table?.filter(filter, 'event_timestamp', 'contains')
    setEventFilter(event.value)
  }

  const calendarFilter =
    <Calendar inputClassName="filterCalendar" inputStyle={{ width: '100%', borderRadius: '0px' }} selectionMode="single" placeholder={getFilterPlaceholder('event_timestamp')} style={{ width: '100%' }} value={eventFilter} onChange={onEventFilter} appendTo={document.body} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />


  return (
    <Tab.Pane className="Contacts">
      <div>
        <div className="_flex flex-direction-row justify-content-between my-3">

          <div className="_flex">

            <FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates">
              {(placeholder: string) => (
                <Calendar selectionMode="range" className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} value={range} onChange={(e: any) => setDates(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)}></Calendar>
              )}
            </FormattedMessage>
            <Button
              btntype="OK green-bg px-4 w-100 rounded-input"
              clicked={onSearch}
              loading={props.loading}
              disabled={!enableSearchButton()}>
              <FormattedMessage id="app.generic.search" defaultMessage="Search" />
            </Button>

          </div>

          <SUIButton onClick={onExport} basic loading={props.loading} disabled={!enableExportButton()}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>


        </div>

        <DataTable  value={props.checkinData?.list}
                    totalRecords={props.checkinData?.total_items} 
                    paginator={true} 
                    rows={perPage} 
                    autoLayout  
                    lazy={true}
                    loading={props.loading} 
                    first={firstIndex}
                    id="checkin-table" 
                    ref={(el: any) => { setTable(el); }} 
                    sortMode="multiple"
                    onPage={onPage}
                    className="mt-3"
                    >

            <Column
              field="user.name"
              header={headerName('name')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('name')}
              sortable={true}
            />

            <Column
              field="user.surname"
              header={headerName('surname')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('surname')}
              sortable={true}
            />

            <Column
              field="user.email"
              header={headerName('email')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('email')}
              sortable={true}
            />

            {/* <Column
              field="counter"
              header={headerName('counter')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('counter')}
              sortable={true}
            /> */}

            <Column
              field="date"
              header={headerName('date')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('date')}
              sortable={true}
            />  

          { ( checkApp() ? (
            <Column
              field="bookingFlag"
              header={headerName('bookingFlag')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('bookingFlag')}
              sortable={true}
            />  ) : null )}


          {/* <Column 
          body={actionTemplate} header={headerName('actions')} style={{ textAlign: 'center', width: '8em' }} /> */}

        </DataTable>

      </div>
    </Tab.Pane >
  )
}

export default injectIntl(CheckInReportsTab)