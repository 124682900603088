import { Route } from "../models/Route";

const RouteConfig = [
    Route.DefaultRoute(),
    Route.LoginRoute(),
    Route.ForgotPasswordRoute(),
    Route.ResetPasswordRoute(),
    Route.ListReservationRoute(),
    Route.NewReservationRoute(),
    Route.NotFoundRoute(),
  ];

  export default RouteConfig