import { BaseModel } from "./Base";
import { Desk } from "./Desk";

export class BaseLocation extends BaseModel {
  guid: any;
  code: number;
  name: string;
  company_code: any;
  risk_percentage: any;
  parent_location_guid: any;
  estimated_distance_time: any;
  available: number;
  deleted: number;
  seats_available: number;
  seats_busy: number;
  seats_total: number;
  desks_properties?: Desk[];
  desk: number;

  constructor(data?: any) {
    super();
    this.guid = "";
    this.code = -1;
    this.company_code = "-1";
    this.risk_percentage = 0;
    this.parent_location_guid = "0";
    this.name = "";
    this.estimated_distance_time = 0;
    this.available = 0;
    this.deleted = 0;
    this.seats_available = 0;
    this.seats_busy = 0;
    this.seats_total = 0;
    this.desks_properties = [];
    this.desk = 0;

    if (data) {
      this.guid = data.guid;
      this.code = data.code;
      this.name = data.name;
      this.company_code = data.company_code;
      this.risk_percentage = data.risk_percentage;
      this.parent_location_guid = data.parent_location_guid;
      this.estimated_distance_time = data.estimated_distance_time;
      this.available = data.available;
      this.deleted = data.deleted;
      this.seats_available = data.seats_available;
      this.seats_busy = data.seats_busy;
      this.seats_total = data.seats_total;
      this.desks_properties = data.desks_properties;
      this.desk = data.desk;
    }
  }
}

export class Location extends BaseLocation {
  title: string;
  subtitle: any;
  loading: boolean;
  expanded: boolean;
  path: any;
  deskLoading: any;
  timezone: any;
  timezone_code: any;

  constructor(data?: any) {
    super();
    this.title = this.name;
    this.subtitle = this.estimated_distance_time;
    this.loading = false;
    this.expanded = false;
    this.timezone = ""; //default timezone object
    this.timezone_code = ""; // default timezone code

    if (data) {
      this.set(data);
      this.title = this.name;
      // this.subtitle = this.estimated_distance_time
      this.setSubtitle();
    }
  }

  setGuid = (guid: any) => {
    this.guid = guid;
    return this;
  };

  setName = (name: string) => {
    this.title = this.name = name;
    return this;
  };

  setParentLocationGuid = (parentLocationGuid: string) => {
    this.parent_location_guid = parentLocationGuid;
    return this;
  };

  setEstimatedDistanceTime = (estimatedDistanceTime: number) => {
    this.estimated_distance_time = estimatedDistanceTime;
    return this;
  };

  setRiskPercentage = (risk_percentage: number) => {
    this.risk_percentage = risk_percentage;
    return this;
  };

  setSubtitle() {
    this.subtitle = "<span><b>Risk: </b></span>" + this.risk_percentage + "%";
    return this;
  }

  setCompanyCode(company_code: string) {
    this.company_code = company_code;
    return this;
  }

  setPath(path: any) {
    this.path = path;
    return this;
  }
}
