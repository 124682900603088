import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-sortable-tree/style.css';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorker';
import { serviceWorkerInizialized, serviceWorkerUpdate } from './store/actions/service-worker';
import { IntlProviderWrapper } from './utils/IntlContext';
import ability, { store } from './utils/permissions/ability';
import { AbilityContext } from './utils/permissions/Can';


// WebFont.load({
//   google: {
//     families: ['Poppins:wght@100;200;300;400;500;600;700;800;900', 'sans-serif']
//   }
// });

if (process.env.REACT_APP_SENTRY_ENV === 'master'){
  Sentry.init({
    dsn: "https://d0ae49c681984184ac25b9cc4aea0f8b@o733109.ingest.sentry.io/5784545",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const jsx = (
  <Provider store={store}>
    <IntlProviderWrapper>
      <AbilityContext.Provider value={ability}>
        <App />
      </AbilityContext.Provider>
    </IntlProviderWrapper>
  </Provider>
);
ReactDOM.render(jsx, document.getElementById("root"));
// registerServiceWorker();
//unregister()
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch(serviceWorkerInizialized()),
  onUpdate: (registration) => store.dispatch(serviceWorkerUpdate(registration)),
})
