import React, { useState, useEffect } from 'react'
import { User } from '../../../../../../models/User'
import { Modal, Header, Icon, Dropdown, Form } from 'semantic-ui-react'
import Button from '../../../../../UI/Button/Button'
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from '../../../../../UI/Input/Input';
import { MultiSelect } from 'primereact/multiselect';
import './UserModal.scss';
import { LOCALE_SUPPORT } from '../../../../../../utils/localization/supported-languages';
import _ from 'lodash'

const UserModal = (props: any) => {

    const { formatMessage } = props.intl;

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [userType, setUserType] = useState('')
    const [locale, setLocale] = useState('')
    const [companyCode, setCompanyCode] = useState('')
    const [fiscalCode, setFiscalCode] = useState('')
    const [password, setPassword] = useState('')
    const [roles, setRoles] = useState<any[]>([])
    const [companies, setCompanies] = useState([])

    const [tmproles, setTmpRoles] = useState([])
    const [loading, setLoading] = useState(props.user.loading)

    useEffect(() => {

        if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log("PROPS", props)
        }
        console.log("LOADING USERS", props.loading, "USERS ", props.user)
        if (props.user && props.user.name !== "") {
            setName(props.user.name)
            setSurname(props.user.surname)
            setUserType(props.user.user_type)
            setFiscalCode(props.user.fiscal_code)
            setLocale(props.user.locale)
            setEmail(props.user.email)
            setRoles(props.user.roles)
            setTemporaryRoles(props.user.roles)
        }
        setCompanyCode(props.user?.company_code)
        setLoading(props.user.loading)

    }, [props.user, props.userRoles])


    const filterRoles = (roles: any[]) => {
        let array = []
        for (var i in roles) {
            let role = roles[i]
            if (role.app === props.activeApp) {
                array.push(role)
            }
        }
        return array
    }



    const header: any = props.user.guid === "" ? <FormattedMessage id="users.new" defaultMessage="New User" /> : <FormattedMessage id="users.edit" defaultMessage="Edit User" />

    const userTypeOptions = [
        { key: 0, text: formatMessage({ id: 'users.table.internal' }), value: 'internal' },
        { key: 1, text: formatMessage({ id: 'users.table.external' }), value: 'external' },
    ]


    const clearUserModalData = () => {
        //Clear User Data
        setName("")
        setSurname("")
        setUserType("")
        setFiscalCode("")
        setLocale("")
        setEmail("")
        setRoles([])
        setTemporaryRoles([])
        setCompanyCode("")
        setLoading("")
    }


    const onCancel = () => {
        props.onCloseModal()
        clearUserModalData()

    }


    const onSave = () => {
        let user = new User(props.user).set({
            name: name,
            surname: surname,
            // password: password,
            locale: locale,
            email: email,
            user_type: userType,
            fiscal_code: fiscalCode,
            company_code: companyCode,
            roles: roles
        })

        if (user.guid && user.guid !== "") {
            user.password_hash = password
            user.password = ""
        }
        setLoading(true)
        props.onUserSave(user)
        clearUserModalData()
    }

    const disableSaveButton = () => {
        return name === "" || surname === "" || email === "" || userType === "" || fiscalCode === "" || tmproles.length === 0 || locale === ""
    }


    const roleTemplate = (option: any) => {
        if (option) {
            return <div>{option.app} - {option.text}</div>
        } else {
            return <div>
                <FormattedMessage id="users.table.roles" defaultMessage={'Roles'} />
            </div>
        }
    }

    const setTemporaryRoles = (roles: any[]) => {
        let _tmproles: any = []

        for (var i in roles) {
            for (var x in roles[i].roles) {
                for (var y in props.userRoles) {
                    if (roles[i].app === props.userRoles[y].app && props.userRoles[y].text === roles[i].roles[x]) {
                        _tmproles.push(props.userRoles[y])
                    }
                }
            }
        }
        console.log("_TMP ROLES", _tmproles)
        setTmpRoles(_tmproles)
    }

    const setSelectedRoles = (e: any) => {

        console.log("Selected roles", tmproles)
        let newRoles: any = []
        for (var i in e.value) {

            let appindex: any = -1
            for (var x in newRoles) {
                if (newRoles[x].app === e.value[i].app) {
                    appindex = x
                    break
                }
            }
            if (appindex === -1) {
                newRoles.push({ app: e.value[i].app, roles: [] })
                appindex = newRoles.length - 1
            }

            if (newRoles[appindex].roles.indexOf(e.value[i].text) === -1) {
                newRoles[appindex].roles.push(e.value[i].text)
            }
        }

        for (var i in roles) {
            if (roles[i].app !== props.activeApp) {
                newRoles.push(roles[i])
                break
            }
        }

        console.log("TMP roles", newRoles)

        setRoles(newRoles)
    }

    const selectedRolesTemplate = (option: any) => {
        console.log("OPTION", option)
        if (option) {
            let icon = option.app === 'COVID' ? "icon-cv19-virus" : "icon-cv19-levels"
            return <span className="ruoli"> <i className={icon} ></i> {option.text}</span>
        } else {
            return <span className="placeholder"><FormattedMessage id="users.table.roles" defaultMessage="Roles" /></span>
        }
    }

    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.onCloseModal()}
            basic
            size='small'
            className="UserModal"
            centered={true}
        >
            <Header><i className="icon-cv19-user"></i>{header}</Header>
            <Modal.Content>
                <div className="_flex flex-direction-row flex-wrap" >
                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.name" defaultMessage="Name">
                            {(placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    label = {placeholder}
                                    placeholder={placeholder}
                                    changed={(event: any) => { setName(event.target.value) }}
                                    type="text"
                                    containerClass="ui input w-100 flex-direction-column"
                                    className="my-2 rounded-input w-100"
                                    value={name}></Input>
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="w-10"></div>

                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.surname" defaultMessage="Last name">
                            {(placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    label = {placeholder}
                                    placeholder={placeholder}
                                    changed={(event: any) => { setSurname(event.target.value) }}
                                    type="text"
                                    containerClass="ui input w-100 flex-direction-column"
                                    className="my-2 rounded-input"
                                    value={surname}></Input>

                            )}
                        </FormattedMessage>
                    </div>
                    <div className="_flex w-45" style={{marginBottom: '20px'}}>
                        <FormattedMessage id="users.table.email" defaultMessage="Email">
                            {(placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    label = {placeholder}
                                    placeholder={placeholder}
                                    changed={(event: any) => { setEmail(event.target.value) }}
                                    type="email"
                                    containerClass="ui input w-100 flex-direction-column"
                                    className="my-2 rounded-input"
                                    value={email}></Input>

                            )}
                        </FormattedMessage>
                    </div>
                    <div className="w-10"></div>
                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.fiscal_code" defaultMessage="Fiscal Code">
                            {(placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    label = {placeholder}
                                    placeholder={placeholder}
                                    changed={(event: any) => { setFiscalCode(event.target.value) }}
                                    type="text"
                                    containerClass="ui input w-100 flex-direction-column"
                                    className="my-2 rounded-input"
                                    value={fiscalCode}></Input>

                            )}
                        </FormattedMessage>
                    </div>

                    <div className="_flex w-45" style={{marginBottom: '20px'}}>
                        <FormattedMessage id="users.table.type" defaultMessage="Tipologia">
                            {(placeholder: string) => (
                                <div className="ui input w-100 flex-direction-column">
                                <label>{placeholder}</label>
                                <Dropdown
                                    placeholder={placeholder}
                                    selection
                                    className="my-2 rounded-input w-100"
                                    options={userTypeOptions}
                                    onChange={(e: any, data: any) => {
                                        console.log(e, data)
                                        setUserType(data.value)
                                    }}
                                    value={userType}
                                />
                                </div>

                            )}
                        </FormattedMessage>
                    </div>
                    <div className="w-10"></div>
                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.language" defaultMessage="Language">
                            {(placeholder: string) => (
                                <div className="ui input w-100 flex-direction-column">
                                <label>{placeholder}</label>
                                <Dropdown
                                    placeholder={placeholder}
                                    selection
                                    className="my-2 rounded-input w-100"
                                    options={LOCALE_SUPPORT}
                                    onChange={(e: any, data: any) => {
                                        console.log(e, data)
                                        setLocale(data.value)
                                    }}
                                    value={locale}
                                />
                                </div>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.roles" defaultMessage={'Roles'} >
                            {(placeholder: string) => (
                                <div className="ui input w-100 flex-direction-column">
                                    <label>{placeholder}</label>
                                    <MultiSelect className="my-2 rounded-input w-100" appendTo={document.body} optionLabel="text" value={filterRoles(tmproles)} options={filterRoles(props.userRoles)} onChange={(e) => { setTmpRoles(e.value); setSelectedRoles(e) }} itemTemplate={roleTemplate} selectedItemTemplate={selectedRolesTemplate} />
                                </div>
                            )}
                        </FormattedMessage>           
                    </div>
                    <div className="w-10"></div>
                    <div className="_flex w-45">
                        <FormattedMessage id="users.table.company" defaultMessage={'Company'} >
                            {(placeholder: string) => (
                                <div className="ui input w-100 flex-direction-column">
                                    <label>{placeholder}</label>
                                    <Dropdown
                                        selection
                                        options={(props.companies) && _.map(props.companies, (company, index) => ({
                                            key: index,
                                            text: company.name,
                                            value: company.guid,
                                          }))}
                                        placeholder={placeholder}
                                        value = {companyCode}
                                        className="my-2 rounded-input w-100 upward"
                                        onChange={(e: any, data: any) => {
                                            console.log("COMPANY SELECTED", data)
                                            setCompanyCode(data.value)
                                        }}
                                    />
                                </div>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button btntype="detail-bg" clicked={onCancel} >   <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" /></Button>
                <Button btntype="green-bg" disabled={disableSaveButton()} clicked={onSave} loading={loading}>   <FormattedMessage id="app.generic.save" defaultMessage="Save" /></Button>
            </Modal.Actions>
        </Modal>
    )
}

export default injectIntl(UserModal)