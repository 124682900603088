import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LUActions } from '../../../../../utils/LocationUtils';
import Button from '../../../../UI/Button/Button';
import Input from '../../../../UI/Input/Input';
import { Location } from '../../../../../models/Location';
import "./LocationDetail.scss";

const CovidLocationDetail = (props: any) => {

    const [title, setTitle] = useState(props.selectedNode.title)
    const [riskPercentage, setRiskPercentage] = useState(props.selectedNode.risk_percentage)
    const [eda, setEda] = useState(props.estimated_distance_time)
    const { formatMessage } = props.intl;


    useEffect(() => {


        setTitle(props.selectedNode.title)
        setRiskPercentage(props.selectedNode.risk_percentage)
        setEda(props.selectedNode.estimated_distance_time)

    }, [props.selectedNode])


    const save = () => {
        let newNode: Location = props.selectedNode
        newNode.title = newNode.name = title
        newNode.estimated_distance_time = eda
        newNode.risk_percentage = riskPercentage
        props.onSave(newNode)
    }


    const disableEditButton = (node: any) => {
        return title === "" || node.estimated_distance_time === "" || node.risk_percentage === ""
    }

    return (
        <div className={`_flex h-100 detail-tab flex-direction-column ${props.selectedNode.guid !== "" ? "active" : ""}`} style={{ background: '#efeeef' }}>
            <div className="detail-title">
                <h2><FormattedMessage id="location.detail.title" defaultMessage="Location Detail" /></h2>
                <div className="detail-title-buttons _flex align-items-center">
                    <Button btntype="rounded-input print" clicked={props.onPrint}>
                        <i className="icon-cv19-print mr-2"></i>
                        <FormattedMessage id="location.printQr" defaultMessage="Print Qr" />
                    </Button>
                    {/* <Icon className="close" name='times circle outline' onClick={(e: any) => { setSelectedNode(new Location()) }} /> */}
                </div>
            </div>
            <div className="detail-form justify-content-center">
                <label className="_flex">
                    <FormattedMessage id="app.generic.title" defaultMessage="Title" />
                </label>
                <FormattedMessage id="app.generic.title" defaultMessage="Title">
                    {
                        (placeholder: string) => (
                            <Input elementType="input"
                                fluid
                                icon="icon map marker"
                                placeholder={placeholder}
                                changed={(event: any) => { setTitle(event.target.value) }}
                                type="text"
                                containerClass="ui left icon input w-100"
                                className="my-2 rounded-input w-100"
                                value={title} />
                        )
                    }
                </FormattedMessage>
                <label className="_flex">
                    <FormattedMessage id="location.riskPercentage" defaultMessage="Risk Percentage" />
                </label>
                <FormattedMessage id="location.riskPercentage" defaultMessage="Risk Percentage">
                    {
                        (placeholder: string) => (
                            <Input elementType="input"
                                fluid
                                icon="icon percent"
                                placeholder={placeholder}
                                changed={(event: any, value: any) => { setRiskPercentage(Number(event.target.value)) }}
                                type="text"
                                containerClass="ui left icon input w-100"
                                className="my-2 rounded-input w-100"
                                value={riskPercentage} />
                        )
                    }
                </FormattedMessage>
                <label className="_flex">
                    <FormattedMessage id="location.eda" defaultMessage="Estimated Distance Time" />
                </label>
                <FormattedMessage id="location.eda" defaultMessage="Estimated Distance Time">
                    {
                        (placeholder: string) => (
                            <Input elementType="input"
                                fluid
                                icon="icon time"
                                placeholder={placeholder}
                                changed={(event: any, value: any) => { setEda(Number(event.target.value)) }}
                                type="text"
                                containerClass="ui left icon input w-100"
                                className="my-2 rounded-input w-100"
                                value={eda} />
                        )
                    }
                </FormattedMessage>

                <div className="_flex w-100" style={{ margin: '0 auto' }}>
                    <Button
                        btntype="OK detail-bg px-4 w-100 rounded-input my-3"
                        clicked={(e: any) => { props.onClearNode() }}
                    >
                        <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button
                        btntype="OK green-bg px-4 w-100 rounded-input my-3"
                        clicked={(e: any) => { save() }}
                        loading={props.selectedNode.loading}
                        disabled={disableEditButton(props.selectedNode)}>
                        <FormattedMessage id="app.generic.save" defaultMessage="Save" />
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default injectIntl(CovidLocationDetail)