import { SettingsState } from "../../../interfaces/SettingsState";
import { Entrance, DefaultEntrance } from "../../../models/Entrance";
import { SettingsAction } from "../../../models/actions/SettingsAction";

const defaultstate: SettingsState = {
  loading: false,
  motivations: [],
  login_types: [],
  tasks: [],
  entrance: {}, //new DefaultEntrance(),
  list: [],
  imports_loading: false,
  total_items: 0,
  public_configuration: [],
  regex_types: [],
};

export default (state = defaultstate, action: SettingsAction) => {
  switch (action.type) {
    case SettingsAction.ON_GENERIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SettingsAction.ON_GENERIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SettingsAction.ON_GENERIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SettingsAction.ON_ENTRAUPD_SUCCESS:
      return {
        ...state,
        entrance: action.response,
        loading: false,
      };
    case SettingsAction.ON_ENTRAUPD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SettingsAction.ON_GETENTRANCE_SUCCESS:
      return {
        ...state,
        entrance: action.response,
        loading: false,
      };
    case SettingsAction.ON_GETTASKS_SUCCESS:
      return {
        ...state,
        tasks: action.response,
        loading: false,
      };
    case SettingsAction.ON_MOTIVUPD_REQUEST:
      return {
        ...state,
        motivations: action.response,
        loading: true,
      };
    case SettingsAction.ON_MOTIVUPD_SUCCESS:
      return {
        ...state,
        motivations: action.response,
        loading: false,
      };
    case SettingsAction.ON_MOTIVUPD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SettingsAction.ON_GETMOTIVATION_SUCCESS:
      return {
        ...state,
        motivations: action.response,
        loading: false,
      };
    case SettingsAction.ON_GET_IMPORTS_REQUEST:
      return {
        ...state,
        imports_loading: true,
      };
    case SettingsAction.ON_GET_IMPORTS_SUCCESS:
      return {
        ...state,
        imports_loading: false,
        list: action.response.items,
        total_items: action.response.total_items
      };
    case SettingsAction.ON_GET_LOGIN_TYPES_SUCESS:
      return {
        ...state,
        login_types: action.response,
        loading: false,
      } 
    case SettingsAction.ON_GET_REGEX_TYPES_SUCESS:
      return {
        ...state,
        regex_types: action.response,
        loading: false,
      }
    case SettingsAction.ON_GET_PUBLIC_CONFIGURATION_SUCESS:
      return {
      ...state,
      public_configuration: action.response,
      loading: false,
    }
    default:
      return state;
  }
};
