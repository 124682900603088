import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { startLogin, googleLogin, microsoftLogin, checkForRedirectLogin } from '../store/actions/auth';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AppState } from '../interfaces/AppState';
import { setApp } from '../store/actions/backoffice/app';
import { Common } from '../utils/Common';
import { isGoogleRedirecting, LOCAL_STORAGE_GOOGLE_REDIRECT } from '../store/localStorage';
import ForgotPasswordForm from '../components/ForgotPassword/ForgotPasswordForm';
import { forgotPassword } from '../store/actions/backoffice/users';


const ForgotPasswordContainer = (props: any) => {

  useEffect(() => {
  }, [])

  const handleForgotPassword = (email: string) => {
    props.forgotPassword(email)
    //props.history.push('/login')
  }

  return (
    <div
      className="_flex flex-1 flex-direction-column h-100"
    >

      <ForgotPasswordForm
        loading={props.loading}
        activeApp={props.activeApp}
        onSetApp={props.setApp}
        onSubmit={(email: any) => handleForgotPassword(email)}
      />


    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.authReducer.loading,
  activeApp: state.app.activeApp,
});

const mapDispatchToProps = (dispatch: any) => ({
  forgotPassword: (email: string) => dispatch(forgotPassword(email)),
  setApp: (app: string) => dispatch(setApp(app)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ForgotPasswordContainer)));
