import { BaseModel } from "./Base";
import { Location } from "./Location";

export class Desk extends Location {
  opened: boolean;
  constructor(data?: any) {
    super(data);
    this.opened = false;
    this.desk = 1;
    this.available = 1
    if (data) {
      this.set(data);
    }
  }
}
