import React, { useState, useEffect } from 'react'
import { Tab, Dropdown, Loader, Button, Icon } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UserAction } from '../../../../../models/actions/UserAction';
import UserModal from './UserModal/UserModal';
import { User } from '../../../../../models/User';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import ImportModal from '../../../../Modals/ImportModal/ImportModal';


const UsersTab = (props: any) => {

    const [globalFilter, setGlobalFilter] = useState("")
    const [user, setUser] = useState(new User())
    const [modalOpen, setModalOpen] = useState(false)
    const [importModalOpen, setImportModalOpen] = useState(false)
    const [companyCode, setCompanyCode] = useState("")
    const [table, setTable] = useState<DataTable | undefined>()
    const [firstIndex, setFirstIndex] = useState(0)
    const [selected, setSelected] = useState<User | any>([])
    const [selectedType, setSelectedType] = useState<any>()
    const [order, setOrder] = useState<any>()
    const [orderBy, setOrderBy] = useState<any>()
    const [filters, setFilters] = useState<any>()
    const [perPage, setPerPage] = useState<any>(10)

    const moreOptions: any[] = [{ label: 'app.generic.edit', value: 'edit' }, { label: 'users.reset', value: 'reset' }, { label: 'app.generic.delete', value: 'delete' }]
    const genericMoreOptions: any[] = [{ label: 'users.new', value: 'new' }, { label: 'app.generic.import', value: 'import' }]

    const { formatMessage } = props.intl;

    const userTypeOptions = [
        { label: formatMessage({ id: 'users.table.internal' }), value: 'internal' },
        { label: formatMessage({ id: 'users.table.external' }), value: 'external' },
    ]


    useEffect(() => {

        if (props.closeModal === true) {
            setModalOpen(false)
            setImportModalOpen(false)
        } else {
            let newUser: User = JSON.parse(JSON.stringify(user))
            if (props.closeModal === "loadingUser") {
                console.log("USER LOADING", props.usersState)
                newUser.loading = true
            } else {
                newUser.loading = false
            }
            setUser(newUser)

        }
    }, [props.closeModal])

    const onExport = () => {
        table?.exportCSV();
    }

    const onReload = () => {
        props.onUserChange({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters }, UserAction.GET_USERS)
    }

    const onUserSave = (user: User) => {
        let action = user.guid ? UserAction.UPDATE_USER : UserAction.CREATE_USER
        props.onUserChange(user, action)
    }

    const onNewUser = () => {
        onEditUser(new User({ company_code: props.auth.selectedCompany }))
    }

    const onEditUser = (rowData: User) => {
        setUser(rowData)
        props.onGetRoles()
        setModalOpen(true)
    }

    const onImportUsers = () => {
        setImportModalOpen(true)
    }

    const moreOptionsTemplate = (option: any) => {
        return (
            <FormattedMessage id={option.label} defaultMessage={option.label} />
        )
    }

    const resetPassword = (data: any, type: string) => {
        let array: any[] = []

        if (type === 'single') {
            let user = new User({ ...data })
            array = [user.toJSON()]
        } else {
            for (var i in data) {
                let user = new User({ ...data[i] })
                array.push(user.toJSON())
            }
        }

        props.onUsersResetPassword(array)

    }


    const actionTemplate = (rowData: any, column: any) => {
        return <div>
            {rowData.loading ?
                <Loader active inline size="mini" />
                :
                <FormattedMessage id="app.generic.more" defaultMessage="More">
                    {(placeholder: string) => (
                        <PrimeDropdown options={moreOptions} placeholder={placeholder}
                            onChange={
                                (e: any) => {
                                    if (e.value === 'edit') {
                                        onEditUser(rowData)
                                    } else if (e.value === 'delete') {
                                        props.onUserChange(rowData, UserAction.DELETE_USER)
                                    } else if (e.value === 'reset') {
                                        resetPassword(rowData, 'single')
                                    } else if (e.value === 'mail') {
                                        // sendMail(rowData)
                                    }
                                }
                            }
                            appendTo={document.body}
                            itemTemplate={moreOptionsTemplate}

                        />
                    )}
                </FormattedMessage>

            }
        </div>;
    }

    const getFilterPlaceholder = (field: string) => {
        return formatMessage({ id: "users.table.filters." + field })
    }

    const onTypeFilter = (event: any) => {
        // table?.filter(event.value, 'user_type', 'equals')
        console.log(filters, event)
        let _filters = { ...filters }
        if (!_filters) {
            _filters = {}
        }

        _filters.user_type = { value: event.value }
        setFilters(_filters)
        setSelectedType(event.value)
        props.onUserChange({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: _filters }, UserAction.GET_USERS)

    }

    const typeFilter = <PrimeDropdown appendTo={document.body} style={{ width: '100%' }} placeholder={getFilterPlaceholder('type')} value={selectedType} options={userTypeOptions} onChange={onTypeFilter} showClear />;


    const onPage = (event: any) => {
        setFirstIndex(event.first)
        props.onUserChange({ page: (event.first / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters }, UserAction.GET_USERS)
    }
    const onSort = (event: any) => {
        setOrder(event.sortOrder)
        setOrderBy(event.sortField)
        props.onUserChange({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: filters }, UserAction.GET_USERS)
    }
    const onFilter = (event: any) => {
        setFilters(event.filters)
        props.onUserChange({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: event.filters }, UserAction.GET_USERS)
    }


    return (
        <Tab.Pane className="Users">
            <div>
                <div className="_flex flex-direction-row justify-content-between">

                    <h3 className="_flex"><FormattedMessage id="users.title" defaultMessage="Users" /></h3>
                    <div className="_flex justify-content-end align-items-center">
                        {selected.length > 0 ? <Button onClick={(e: any) => resetPassword(selected, 'multiple')} basic loading={props.loading}><FormattedMessage id="users.invites" defaultMessage="Send Invites" /> </Button> : ""}
                        <Button onClick={onReload} basic loading={props.loading}><Icon name='redo alternate' /></Button>
                        <FormattedMessage id="app.generic.more" defaultMessage="More">
                            {(placeholder: string) => (
                                <PrimeDropdown
                                    placeholder={placeholder}
                                    options={genericMoreOptions}
                                    appendTo={document.body}
                                    className="my-2 mr-2"
                                    itemTemplate={moreOptionsTemplate}
                                    onChange={(e: any) => {
                                        switch (e.value) {
                                            case 'new':
                                                onNewUser()
                                                break
                                            case 'import':
                                                onImportUsers()
                                                break
                                        }

                                    }}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                </div>

                <DataTable
                    value={props.usersState.items}
                    paginator={true}
                    rows={perPage}
                    autoLayout
                    loading={props.loading}
                    ref={(el: any) => { setTable(el); }}
                    selection={selected}
                    onSelectionChange={e => { console.log('selection', e); setSelected(e.value) }}
                    totalRecords={props.usersState.totalRecords}
                    lazy={true}
                    first={firstIndex}
                    onPage={onPage}
                    sortField={orderBy}
                    sortOrder={order}
                    onSort={onSort}
                    filters={filters}
                    onFilter={onFilter}
                >

                    <Column
                        selectionMode="multiple"
                        style={{ width: '2em' }}
                    />

                    <Column
                        field="name"
                        header={<FormattedMessage id="users.table.name" defaultMessage="Name" />}
                        filter={true}
                        sortable={true}
                        filterPlaceholder={getFilterPlaceholder('name')}
                    />

                    <Column
                        field="surname"
                        header={<FormattedMessage id="users.table.surname" defaultMessage="Surname" />}
                        filter={true}
                        sortable={true}
                        filterPlaceholder={getFilterPlaceholder('surname')}
                    />

                    <Column
                        field="fiscal_code"
                        header={<FormattedMessage id="users.table.fiscal_code" defaultMessage="Fiscal Code" />}
                        filter={true}
                        sortable={true}
                        filterPlaceholder={getFilterPlaceholder('fiscal_code')}
                    />

                    <Column
                        field="email"
                        header={<FormattedMessage id="users.table.email" defaultMessage="Email" />}
                        filter={true}
                        sortable={true}
                        filterPlaceholder={getFilterPlaceholder('email')}
                    />

                    <Column
                        field="user_type"
                        header={<FormattedMessage id="users.table.type" defaultMessage="Type" />}
                        filter={true}
                        sortable={true}
                        filterElement={typeFilter}
                    />

                    <Column
                        body={actionTemplate}
                        header={<FormattedMessage id="users.table.actions" defaultMessage="Actions" />}
                        style={{ textAlign: 'center', width: '8em' }}
                    />
                </DataTable>

            </div>

            <UserModal
                user={user}
                companies={props.auth.companies}
                userRoles={props.userRoles}
                activeApp={props.activeApp}
                modalOpen={modalOpen}
                onUserSave={onUserSave}
                onCloseModal={() => { setModalOpen(false) }}
            />

            <ImportModal
                template={process.env.REACT_APP_USER_TEMPLATE}
                importLoading={props.loading}
                file={undefined}
                headerText="users.import"
                onFileChange={(file: File) => {
                    props.onImportFile(file, { page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters })
                }}
                onGetActiveTasks={props.onGetActiveTasks}
                settings={props.settings}
                modalOpen={importModalOpen}
                onCloseModal={() => { setImportModalOpen(false) }}
                onGetImports={(data: any) => { props.onGetImports(data) }}
                imports={props.imports}
            />

        </Tab.Pane >
    )
}


export default injectIntl(UsersTab)