import { combineReducers } from 'redux';
import toastReducer from './reducers/toast';
import authReducer from '../store/reducers/auth';
import locationReducer from '../store/reducers/backoffice/location';
import biohazardReducer from '../store/reducers/backoffice/biohazard';
import usersReducer from '../store/reducers/backoffice/users';
import logReducer from './reducers/backoffice/log';
import appStateReducer from './reducers/backoffice/app';
import reportsReducer from '../store/reducers/backoffice/reports';
import settingsReducer from '../store/reducers/backoffice/settings';
import bookingReducer from '../store/reducers/backoffice/booking';
import trackerUserReducer from '../store/reducers/tracker/users'
import checkinReducer from '../store/reducers/backoffice/checkin'
import locationReducerV2 from '../store/reducers/backoffice/locationV2';
import serviceWorkerReducer from '../store/reducers/service-worker';


const appReducer = combineReducers({
  authReducer,
  toast: toastReducer,
  location: locationReducer,
  users: usersReducer,
  biohazard: biohazardReducer,
  log: logReducer,
  app: appStateReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  booking: bookingReducer,
  trackerUser: trackerUserReducer,
  checkin: checkinReducer,
  locationV2: locationReducerV2,
  serviceWorkerReducer: serviceWorkerReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
