import React, { useState, useEffect, Children, useRef } from 'react'
import { Tab, Dropdown, Icon, Form, Input, Divider, Checkbox } from 'semantic-ui-react'
import { injectIntl } from 'react-intl';
import Button from '../../../../UI/Button/Button';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './Form.scss';
import { IntlContext } from '../../../../../utils/IntlContext';
import { GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isMobile } from 'react-device-detect';


const FormTab = (props: any) => {
  const [error, setError] = useState<boolean>(false)
  const [dateError, setDateError] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)


  const { locale, calendar } = React.useContext(IntlContext);
  let auth: any = localStorage.getItem("auth")
  let userInfo = JSON.parse(auth)

  // useEffect(() => {
  //   if (!showForm && props.setSelectedLocation) {
  //     props.setSelectedLocation(undefined)
  //   }
  // }, [showForm])

  const checkDate = () => {
    if (!moment(props.formDetail.endDate).isValid()) {
      props.setEndDate(props.formDetail.startDate)
    }
    if (!moment(props.formDetail.startDate).isValid() || !moment(props.formDetail.endDate).isValid()) {
      setDateError(true)
    }
    else {
      setDateError(false)
    }
  }

  useEffect(() => {
    checkDate()
    checkError()
  }, [props.formDetail])

  useEffect(() => {
    if(props.selectedLocation) {
      setShowForm(true)
    }
  }, [])

  useEffect(() => {
    if(props.successBooking) {
      setShowForm(false)
    }
  }, [props.successBooking])


  const checkError = () => {
    // console.log("props.formDetail.reason", props.formDetail.reason, (!props.formDetail.reason || props.formDetail.reason.length === 0))
    // console.log("props.formDetail.area", props.formDetail.area, (!props.formDetail.area || props.formDetail.area.length === 0))
    // console.log("props.formDetail.room", props.formDetail.room, (!props.formDetail.room || props.formDetail.room.length === 0))
    // console.log("props.formDetail.floor", props.formDetail.floor, (!props.formDetail.floor || props.formDetail.floor.length === 0))
    // console.log("props.formDetail.desk", props.formDetail.desk, (!props.formDetail.desk || props.formDetail.desk.length === 0))
    // console.log("checkDate", !checkDate())
    // console.log("props.formDetail.listUser", props.formDetail.listUser.length < 1)
    // console.log("formDetail form final", (!checkDate() || !props.formDetail.reason || props.formDetail.reason.length === 0
    //   || !props.formDetail.area || props.formDetail.area.length === 0
    //   || !props.formDetail.room || props.formDetail.room.length === 0
    //   || !props.formDetail.floor || props.formDetail.floor.length === 0
    //   || !props.formDetail.desk || props.formDetail.desk.length === 0
    //   || props.formDetail.listUser.length < 1))
    if (dateError
      || !props.formDetail.reason || props.formDetail.reason.length === 0
      || (props.visibleItem.area && (!props.formDetail.area || props.formDetail.area.length === 0))
      || (props.visibleItem.room && (!props.formDetail.room || props.formDetail.room.length === 0))
      || (props.visibleItem.floor && (!props.formDetail.floor || props.formDetail.floor.length === 0))
      || !props.formDetail.desk || props.formDetail.desk.length === 0
      || props.formDetail.listUser.length < 1) {
      setError(true)
      return true
    }
    else {
      setError(false)
      return false
    }

  }
  // const checkNumber = () => {
  //   console.log("props.formDetail.startDate || !props.formDetail.endDate", props.formDetail.startDate, props.formDetail.endDate)
  //   if (!moment(props.formDetail.startDate).isValid() || !moment(props.formDetail.endDate).isValid()) {
  //     return true
  //   }
  //   else {
  //     return false
  //   }
  // }
  const handleStepConfirmForm = (value: any) => {
    if (value == 1) {
      if (checkError()) {
        setError(true)
      }
      else {
        setError(false)
        props.setStepConfirmForm(value)
      }
    }

  }

  const handleVisibility = (e: any) => {
    var elements = document.getElementsByClassName("p-input-overlay-visible");
    console.log("element", elements)

  };

  const handleVisibleChange = (e: any) => {
    console.log(e);
  };

  const minDate = () => {
    var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate()+1);
  return tomorrow
  }

  const nextForm = () => {
    props.nextForm()
    setShowForm(true)
  }

  return (
    <Tab.Pane className="Contacts">
      <div className="container">
        {/* <div className="_flex flex-direction-row justify-content-between my-3">

          <div className="_flex">

            <FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates">
              {(placeholder: string) => (
                <Calendar className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} value={range} onChange={(e: any) => setDates(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)}></Calendar>
              )}
            </FormattedMessage>
            <Button
              btntype="OK green-bg px-4 w-100 rounded-input"
              clicked={onSearch}
              loading={props.loading}
              disabled={!enableSearchButton()}>
              <FormattedMessage id="app.generic.search" defaultMessage="Search" />
            </Button>

          </div>

          <SUIButton onClick={onExport} basic loading={props.loading} disabled={!enableExportButton()}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>


        </div> */}
        {props.loading ? (<ProgressSpinner />)
          : (
            <div><h1 className='title'>Prenota la tua postazione di lavoro</h1>
              <Form className='formStyle'>
                <Form.Group className='formGroup' widths='equal'>
                  <Form.Field label='Quando'
                    control='calendar'
                    error={dateError ? {
                      content: 'Please insert a valid date',
                    } : null}>
                    <Calendar
                      id="myDIV"
                      // className={isVisible ? 'visibleCalendar' : 'notVisibleCalendar'}
                      disabled={showForm}
                      className='calendarNewForm'
                      minDate={minDate()}
                      footerTemplate={() => <div>
                        <div style={{ marginBottom: '10px' }}>
                          <Icon name='lightbulb outline' />Seleziona le date di inizio e fine per prenotare più giorni
                        </div>
                        {/* <Button clicked={(e: any) => { handleVisibility(e) }} className="confirmButton" >Prosegui</Button> */}
                      </div>}
                      showIcon numberOfMonths={isMobile ? 1 : 2} showButtonBar selectionMode="range" placeholder={"Inserisci Data"}  value={props.range} onChange={(e: any) => props.setDates(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)}></Calendar>
                  </Form.Field>
                  <Form.Field
                    label='Sede'
                    control='dropdown'
                  >
                    <Dropdown
                      search
                      disabled={showForm}
                      selection
                      wrapSelection={false}
                      options={props.optionsLocations}
                      value={props.formDetail.location}
                      onChange={(e: any, { value }) => {
                        props.setLocation(value)
                      }}
                      placeholder='Scegli una opzione'
                    >
                      {/* <Dropdown.Menu>
                  {options && options.map((element) => {
                    return <Dropdown.Item onClick={(e: any, { value }) => {
                      console.log("location", value)
                      setLocation(value)
                    }}
                      description={element.freeSeats} value={element.value} text={element.value} />
                  })}
                </Dropdown.Menu> */}

                    </Dropdown>

                  </Form.Field>
                  <Form.Field label='Numero massimo di postazioni' control={Input}>
                    <Input className="multiselect" value={props.formDetail.numberPeople} type='text' placeholder='Search...' action
                      disabled={showForm}
                      onChange={(e: any, { value }) => +value > 0 ? props.setNumberPeople(+value) : console.log("not valid")}
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        else {
                          console.log("props.formDetail.numberPeople", props.formDetail.numberPeople)

                        }
                      }}>
                      <Button disabled={showForm} icon clicked={(e: any) => { props.lessSeats() }}>
                        <Icon name='minus' />
                      </Button>
                      <input />
                      <Button disabled={showForm} icon clicked={(e: any) => { props.plusSeats() }}>
                        <Icon name='plus' />
                      </Button>
                    </Input>
                  </Form.Field>
                </Form.Group>
                <Divider />
                {showForm && (
                  <div>
                    <Form.Group className='formGroup' widths='equal'>
                      {props.visibleItem && props.visibleItem.area &&

                        <Form.Field
                          label='Area'
                          control='Dropdown'
                          className='fieldDropdown'
                        >
                          <Dropdown
                            search
                            selection
                            wrapSelection={false}
                            options={props.optionsAreas}
                            value={props.formDetail.area}
                            onChange={(e: any, { value }) => {
                              console.log("area", value)
                              props.setAreas(value)
                            }}
                            placeholder='Seleziona area'
                          >
                          </Dropdown>

                        </Form.Field>
                      }
                      {props.visibleItem && props.visibleItem.floor &&

                        <Form.Field
                          label='Piano'
                          control='Dropdown'
                          className='fieldDropdown'
                        >
                          <Dropdown
                            search
                            selection
                            wrapSelection={false}
                            options={props.optionsFloors}
                            value={props.formDetail.floor}
                            onChange={(e: any, { value }) => {
                              console.log("piano", value)
                              props.setFloor(value)
                            }}
                            placeholder='Seleziona un piano'
                          >
                          </Dropdown>

                        </Form.Field>
                      }
                      {props.visibleItem && props.visibleItem.room &&

                        <Form.Field
                          label='Stanza'
                          control='Dropdown'
                          className='fieldDropdown'
                        >
                          <Dropdown
                            search
                            selection
                            wrapSelection={false}
                            options={props.optionsRooms}
                            value={props.formDetail.room}
                            onChange={(e: any, { value }) => {
                              console.log("room", value)
                              props.setRoom(value)
                            }}
                            placeholder='Seleziona una stanza'
                          >
                          </Dropdown>

                        </Form.Field>
                      }
                      <Form.Field
                        label='Postazione'
                        control='Dropdown'
                        className='fieldDropdown'
                      >
                        <Dropdown
                          search
                          selection
                          wrapSelection={false}
                          options={props.optionsDesks}
                          value={props.formDetail.desk}
                          onChange={(e: any, { value }) => {
                            console.log("Desk", value)
                            props.setDesks(value)
                          }}
                          placeholder='Seleziona una Postazione'
                        >
                        </Dropdown>

                      </Form.Field>


                    </Form.Group>
                    <Form.Group className='formGroup' widths='equal' >

                      <Form.Field
                        label='Perchè vuoi prenotare?'
                        control='Dropdown'
                        className='fieldDropdown'
                      >
                        <Dropdown
                          search
                          selection
                          wrapSelection={false}
                          options={props.optionsMotivation}
                          value={props.formDetail.reason}
                          onChange={(e: any, { value }) => {
                            console.log("motivation", value)
                            props.setReason(value)
                          }}
                          placeholder='Perchè vuoi prenotare?'
                        >
                        </Dropdown>

                      </Form.Field>
                    </Form.Group>
                    {/* <Dropdown.Header className="headerDropdown" content='Inserisci i nominativi della prenotazione' /> */}
                    <Form.Group widths='equal' >
                      <Form.Field
                        label='Inserisci i nominativi della prenotazione'
                        control='Dropdown'
                        className='fieldDropdown'
                      >
                        <Dropdown
                          fluid
                          className='selectionUsers'
                          multiple
                          search
                          selection
                          options={props.optionsPeople}
                          value={props.formDetail.listUser}
                          onChange={(e: any, { value }) => {
                            console.log("selectionUsers", value)
                            props.setPeoples(e, value)
                          }}
                          onSearchChange={(e: any, data) => {
                            console.log("selectionUsers onSearchChange", data.searchQuery)
                            props.getUserList(1, 10, { fullname: { value: data.searchQuery } });
                          }}
                        >
                        </Dropdown>

                      </Form.Field>

                    </Form.Group>
                    <Form.Field
                      className='selectionUsers'
                      control='Checkbox'
                      label={<label>Includi me stesso</label>}
                    >
                      <Checkbox
                        onChange={(e, data) => props.setSelfReservation(data.checked)}
                        checked={props.formDetail.listUser.includes(userInfo.guid)}>
                      </Checkbox>
                    </Form.Field>
                  </div>)}


                {/* <Form.Checkbox label='I agree to the Terms and Conditions' /> */}
                {/* <Form.Button className="backButton" onClick={() => { handleStepConfirmForm(0) }} >Indietro</Form.Button> */}
                {!showForm && (
                  <Form.Group widths='equal'>

                    <Form.Button className="confirmButton" disabled={!props.step ? true : false} onClick={() => { nextForm() }} >Prosegui</Form.Button>
                  </Form.Group>

                )}
                {showForm && (
                  <Form.Group className='formGroupButton' widths='equal' >
                    <Form.Button className="backButton" onClick={() => { setShowForm(false) }}>Indietro</Form.Button>
                    <Form.Button className="confirmButton" disabled={error ? true : false} onClick={() => { handleStepConfirmForm(1) }} >Vai al riepilogo</Form.Button>


                  </Form.Group>
                )}

              </Form></div>
          )
        }
      </div>
    </Tab.Pane >
  )
}

export default injectIntl(FormTab)