import LoginContainer from "../containers/LoginContainer";
import NotFoundContainer from "../containers/NotFoundContainer";
import HomeContainer from "../containers/backoffice/HomeContainer";
import MobileHomeContainer from "../containers/tracker/HomeContainer";
import { Common } from "../utils/Common";
import ForgotPasswordContainer from "../containers/ForgotPasswordContainer";
import ResetPasswordContainer from "../containers/ResetPasswordContainer";
import ReservationsTabContainer from "../containers/backoffice/WsManager/Reservations";
import NewReservationContainer from "../containers/backoffice/NewReservationContainer";

export class Route {
  path: string;
  component: any;
  _private: boolean;
  children: Route[];
  redirectTo: string;
  exact:boolean

  constructor(
    path: string,
    component: any,
    _private: boolean,
    redirectTo: string,
    children: Route[],
    exact?:boolean
  ) {
    this.path = path;
    this.component = component;
    this._private = _private;
    this.children = children;
    this.redirectTo = redirectTo;
    this.exact = exact ? exact : false
  }

  static DefaultRoute() {
    return new Route("/", HomeContainer, true, "/login", [], true);
  }

  static LoginRoute() {
    return new Route("/login", LoginContainer, false, "", []);
  }

  static ResetPasswordRoute() {
    return new Route("/reset/:nonce", ResetPasswordContainer, false, "", [], true);
  }

  static ForgotPasswordRoute() {
    return new Route("/forgot", ForgotPasswordContainer, false, "", [], true);
  }

  static ListReservationRoute() {
    return new Route("/list", ReservationsTabContainer, true, "", [], true);
  }

    static NewReservationRoute() {
    return new Route("/newreservation", NewReservationContainer, true, "", [], true);
  }

  static NotFoundRoute() {
    return new Route("", NotFoundContainer, false, "", []);
  }

  child(child: Route) {
    this.children.push(child);
    return this;
  }

  _children(children: Route[]) {
    this.children.concat(children)
    return this;
  }
}
