import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginForm from '../components/Login/LoginForm';
import { startLogin, googleLogin, microsoftLogin, checkForRedirectLogin, microsoftLoginEnterPasswordNew } from '../store/actions/auth';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AppState } from '../interfaces/AppState';
import { setApp } from '../store/actions/backoffice/app';
import { Common } from '../utils/Common';
import { isGoogleRedirecting, LOCAL_STORAGE_GOOGLE_REDIRECT } from '../store/localStorage';
import { getPublicConfigurations } from '../store/actions/backoffice/settings';

interface LoginState {
  errorsReducer: any,
  authReducer: any
}

interface LoginProps {
  loading: boolean,
  googleLoading: boolean,
  resetLoginError: any
  startLogin: any
  intl: any
  loginError: any,
  activeApp: any,
  setApp: any,
  googleLogin: any,
  microsoftLogin: any,
  checkForRedirectLogin: any,

}

const LoginContainer = (props: any) => {
  // login user


  useEffect(() => {
    if (Common.IsMobile() && isGoogleRedirecting()) {
      localStorage.removeItem(LOCAL_STORAGE_GOOGLE_REDIRECT)
      props.checkForRedirectLogin()
    }

    props.onGetPublicConfigurations()
  }, [])

  useEffect(() => {
    console.log("public_configuration ", props.public_configuration)
  },[props.public_configuration])

  const handleLogin = (email: any, password: any) => {
    // reset error
    props.startLogin(email, password);
  };

  return (
    <div
      className="_flex flex-1 flex-direction-column h-100"
    >
      {/* <Dimmer active={props.loading}>
        <Loader size="large">
          {props.intl.formatMessage({ id: 'root.loading.title' })}
        </Loader>
      </Dimmer> */}
      {/* <Image src={Logo} size="medium" centered /> */}

      <LoginForm
        loading={props.loading}
        googleLoading={props.googleLoading}
        activeApp={props.activeApp}
        onSetApp={props.setApp}
        onGoogleLogin={props.googleLogin}
        onMicrosoftLogin={props.microsoftLogin}
        onSubmit={(email: any, password: any) => handleLogin(email, password)}
        public_configuration={props.public_configuration}
        passwordNew={props.passwordNew}
        err={props.err}
        microsoftLoginEnterPasswordNew={props.microsoftLoginEnterPasswordNew}

      />


    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.authReducer.loading,
  activeApp: state.app.activeApp,
  googleLoading: state.authReducer.googleLoading,
  public_configuration: state.settings.public_configuration,
  passwordNew: state.authReducer.passwordNew,
  err: state.authReducer.err,
});

const mapDispatchToProps = (dispatch: any) => ({
  startLogin: (email: any, password: any) => dispatch(startLogin(email, password)),
  googleLogin: () => dispatch(googleLogin()),
  microsoftLogin: (tenantId:any) => dispatch(microsoftLogin(tenantId)),
  microsoftLoginEnterPasswordNew: (err: any, password: any) => dispatch(microsoftLoginEnterPasswordNew(err, password)),
  checkForRedirectLogin: () => dispatch(checkForRedirectLogin()),
  setApp: (app: string) => dispatch(setApp(app)),
  onGetPublicConfigurations: () => dispatch(getPublicConfigurations()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginContainer)));
