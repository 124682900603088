import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from "../actions/toast";
import { ToastState } from "../../interfaces/Toast";

export default (
  state: ToastState = {
    toasts: [],
  },
  action: any
) => {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.response],
      };
    case REMOVE_TOAST:
      return state.toasts.filter((toast) => toast.id !== action.response);
    case REMOVE_ALL_TOASTS:
      return {
        ...state,
        toasts: []
      }

    default:
      return state;
  }
};
