import React, { useState, useEffect } from 'react';
import AppRouter from './routing/AppRouter';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import { Button, Modal } from "semantic-ui-react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { AppState } from './interfaces/AppState';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';
import './App.scss'
import ToastContainer from './containers/ToastContainer';
import HeaderContainer from './containers/backoffice/HeaderContainer';
import { Common } from './utils/Common';
import 'bootstrap/dist/css/bootstrap.min.css';
import ability  from './utils/permissions/ability';


(global as any).appVersion = packageJson.version

const App = (props: any) => {
  const [visible, setVisible] = useState(false)
  const [serviceWorkerUpdate, setServiceWorkerUpdate] = useState(false)

  useEffect(() => {
    const rules = localStorage.getItem('rules');
    if (rules) ability.update(JSON.parse(rules));
    console.log('rules: ',rules)
  }, [])

  useEffect(() => {
    if(props.serviceWorkerReducer !== undefined){
      console.log('serviceWorkerUpdate: ', props.serviceWorkerReducer.serviceWorkerUpdated)
      if(props.serviceWorkerReducer.serviceWorkerUpdated === true){
        console.log('serviceWorkerUpdate: ', props.serviceWorkerReducer.serviceWorkerUpdated)
        setServiceWorkerUpdate(props.serviceWorkerReducer.serviceWorkerUpdated)
        setVisible(true)
      }
    }
  }, [props.serviceWorkerReducer])

  const onHide = () => {
    setVisible(false)
    window.location.reload();
    window.location.href = window.location.href;
  }

  const updateServiceWorker = () => {
    window.location.reload()
    let registrationWaiting = props.serviceWorkerReducer.serviceWorkerRegistration.waiting

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
          window.location.href = window.location.href;
        }
      })
    }
    window.location.reload();
    window.location.href = window.location.href;
    onHide()
  }

  console.log("WORKSPACE MANAGER V" + packageJson.version)
  console.log("visible: ", visible)
  console.log("serviceWorkerUpdate: ", serviceWorkerUpdate)

  return (
    <div className="App flex flex-direction-column w-100">
      {/* <CacheBuster /> */}
      <ToastContainer />
      {/* {(Common.IsMobile() || Common.CheckMobileUrl()) ? <></> : <HeaderContainer />} */}
      <AppRouter />
      {serviceWorkerUpdate ? (
          <Modal
            open={false}
            onClose={onHide}
            size="mini"
            closeIcon
          >
            <Modal.Header><FormattedMessage id="app.generic.new_version_available" defaultMessage="New version available!" /></Modal.Header>
            <Modal.Content>
              <FormattedMessage id="app.generic.updating_form" defaultMessage="A new version of website is available, please update" />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={updateServiceWorker}><FormattedMessage id="app.generic.update" defaultMessage="Update" /></Button>
            </Modal.Actions>
          </Modal>
        ) : null}
    </div>
  );
  
}

const mapStateToProps = (state: AppState) => ({
    serviceWorkerReducer: state.serviceWorkerReducer
});

function mapDispatchToProps(dispatch: any) {
  
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(App));
