import { LogState } from "../../../interfaces/LogState";
import { LogAction } from "../../../models/actions/LogAction";
import { ReportState } from "../../../interfaces/ReportState";
import { ReportAction } from "../../../models/actions/ReportAction";

const defaultstate: ReportState = {
  loading: false,
  reports: [],
  exportLoading: false,
};

export default (state = defaultstate, action: any) => {
  switch (action.type) {
    case ReportAction.ON_GENERIC_REPORT_REQUEST:
      return {
        ...state,
        loading:true,
      };
    case ReportAction.ON_GENERIC_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.response,
        loading:false,
      };
    case ReportAction.ON_GENERIC_REPORT_ERROR:
      return {
        ...state,
        loading:false,
      };
      case ReportAction.ON_GENERIC_REPORT_EXPORT_REQUEST:
        return {
          ...state,
          exportLoading:true,
        };
        case ReportAction.ON_GENERIC_REPORT_EXPORT_SUCCESS:
          return {
            ...state,
            exportLoading:false,
          };
    default:
      return state;
  }
};
