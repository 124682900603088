export const LOCAL_STORAGE_AUTH_KEY = "auth";
export const LOCAL_STORAGE_SWITCH_KEY = "app";
export const LOCAL_STORAGE_CASL_KEY = 'casl'
export const LOCAL_STORAGE_GOOGLE_REDIRECT = 'google_redirect'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    if (serializedState == null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const loadCaslState = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_CASL_KEY);
    if (serializedState == null) {
      return false;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const loadAppState = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_SWITCH_KEY);
    if (serializedState == null) {
      return {};
    }
    return serializedState;
  } catch (err) {
    return err;
  }
};

export const isGoogleRedirecting = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_GOOGLE_REDIRECT);
    if (serializedState == null) {
      return false;
    }
    return serializedState === 'true';
  } catch (err) {
    return false;
  }
};
