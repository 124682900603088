import React, { useState, useEffect } from 'react'
import './Locations.scss'
import { Tab, Loader, Dropdown, Dimmer, Icon } from 'semantic-ui-react'
import SortableTree from 'react-sortable-tree';
import TreeNode from '../../../../UI/TreeNode/TreeNode'
import Button from '../../../../UI/Button/Button';
import Input from '../../../../UI/Input/Input';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LocationUtils, LUActions } from '../../../../../utils/LocationUtils';
import { Location } from '../../../../../models/Location';
import { User, IsAdmin, IsAdminOfApp } from '../../../../../models/User';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import ImportModal from '../../../../Modals/ImportModal/ImportModal';
import CovidLocationDetail from '../../Covid/LocationDetail/LocationDetail';
import WsManagerLocationDetail from '../../WsManager/LocationDetail/LocationDetail';
import { APP, IsWsManager } from '../../../../../interfaces/SwitchState';
import MotivationsModal from '../../../../Modals/MotivationsModal/MotivationsModal';
import { Motivation } from '../../../../../models/Motivation';
import { SettingsType } from '../../../../../models/Settings';
import { Can } from '../../../../../utils/permissions/Can';
import LocationReservation from '../../WsManager/LocationBooking/LocationReservation';
import { BaseReservation } from '../../../../../models/Reservation';


const LocationsTab = (props: any) => {

    const [counter, setCounter] = useState(1)
    const [open, setOpen] = useState(false)


    const [selectedNode, setSelectedNode] = useState<Location | any>(new Location)
    const [selectedPath, setSelectedPath] = useState("")
    const [tmpData, setTmpData] = useState(props.treeData)
    const [importModalOpen, setImportModalOpen] = useState(false)
    const [motivModalOpen, setMotivModalOpen] = useState(false)
    const [showDetails, setShowDetails] = useState(false)

    const [linkToMap, setLinkToMap] = useState('');

    const covidOpts: any[] = [
        { label: 'location.print.all', value: 'print' },
        { label: 'app.generic.import', value: 'import' }
    ]
    const wsmanOpts: any[] = [
        { label: 'location.print.all', value: 'print' },
        { label: 'location.motivations.set', value: 'motivations' },
        { label: 'app.generic.import', value: 'import' }
    ]
    const { formatMessage } = props.intl;


    useEffect(() => {
        // action on update of movies

        // if (!companyCode) {
        //     let json = localStorage.getItem("auth")
        //     if (json) {
        //         let user: User = new User(JSON.parse(json))
        //         setCompanyCode(props.authState.company_code)
        //     }
        // }



        let result = LocationUtils.FindNodesForUpdate(props.treeData)
        if (result.matches && result.matches.length > 0) {


            let node = result.matches[0].node
            let path = result.matches[0].path
            props.onNodeChange(node, LUActions.ADD, props.treeData, null, path)
            for (var i in result.matches) {
                let node = result.matches[i].node
                let path = result.matches[i].path
            }
        }

        if (JSON.stringify(props.treeData) !== JSON.stringify(tmpData)) {
            selectedNode.loading = false
            setTmpData(props.treeData)
        }

        if (props.closeModal === true) {
            setImportModalOpen(false)
        }

    }, [counter, props.loading, props.treeData]);

    useEffect(() => {
        if(selectedNode !== undefined && selectedNode.guid !== undefined){
            console.log("LOCATION SELECTED: "+ String(selectedNode))
            console.log("LOCATION SELECTED GUID: "+ selectedNode.guid)
            console.log("LOCATION MAP_LINK: ", selectedNode.map_file_name)
            if(selectedNode.map_file_name !== null){
                setLinkToMap(selectedNode.map_file_name)
            }
        }
    }, [selectedNode])

    const addItem = (node: any, path: any, child?: boolean) => {
        setCounter(counter + 1)

        try {
            let result: any = {}
            let newNode = new Location()
                .setGuid("TEMPORARY_LOCATION_" + counter)
                .setName('New Location')
                .setEstimatedDistanceTime(counter)
                .setRiskPercentage(0)
                .setCompanyCode(props.authState?.auth?.selectedCompany)

                .setSubtitle()

            if (child) {
                newNode.setParentLocationGuid(node.guid);
                path.push(1)
                newNode.setPath(path)
                result = LocationUtils.AddChild(newNode, props.treeData, node.guid)
            } else {
                newNode.setPath([0])
                newNode.setParentLocationGuid('0');
                result = LocationUtils.AddItem(newNode, props.treeData, 0, 0)
            }
            if (result.treeData) {
                props.handleChange(result.treeData)
            }


        } catch (error) {

        }
    }



    const onDropdownOpen = (node: any, path: any) => {
        if( selectedNode!=node){
            setLinkToMap('')
        }
        setSelectedNode(node)
        setSelectedPath(path)
    }

    const onSave = (newNode: Location, deskUpdate?: boolean) => {
        console.log("[LOCATION NODE] ", newNode)
        let action = selectedNode.guid !== "" ? LUActions.UPDATE : LUActions.ADD
        props.onNodeChange(selectedNode, action, props.treeData, newNode, selectedPath, deskUpdate)
    }

    const onDelete = (node: any, path: any) => {
        props.onNodeChange(node, LUActions.DELETE, props.treeData, null, path, true)
    }

    const onEnableDisable = (node: any, path: any, enable: boolean) => {
        node.available = enable ? 1 : 0
        setSelectedNode(node)
        setSelectedPath(path)
        onSave(node, true)
    }


    const onAction = (node: any, path: any, action: any) => {
        setOpen(false)
        switch (action) {
            case LUActions.ADD:
                addItem(node, path)
                break
            case LUActions.ADD_CHILD:
                addItem(node, path, true)
                break
            case LUActions.UPDATE:
                onSave(node)
                break
            case LUActions.DELETE:
                onDelete(node, path)
                setSelectedNode(new Location())
                break
            case LUActions.ENABLE:
            case LUActions.DISABLE:
                onEnableDisable(node, path, !node.available)
                break
            case LUActions.PRINT:
                props.onNodeChange(node, LUActions.PRINT)
                break
            case LUActions.PRINT_ALL:
                props.onNodeChange(node, LUActions.PRINT_ALL)
                break
        }
    }

    const onMoveNode = (data: any) => {
        let newNode: Location = data.node
        if (data.nextParentNode !== null) {
            newNode.parent_location_guid = data.nextParentNode.guid
        } else {
            newNode.parent_location_guid = "0"
        }
        props.handleChange(data.treeData)
        props.onNodeChange(data.node, LUActions.UPDATE, data.treeData, newNode, data.nextPath)
    }


    const nodeEnabled = (node: any) => {
        return ((node.guid !== undefined && !node.guid.includes("TEMPORARY_LOCATION_"))
            || (node.node !== undefined && node.node.guid !== undefined && !node.node.guid.includes("TEMPORARY_LOCATION_")))
            && (node.available === 1
                || (node.node !== undefined && node.node.available === 1))
    }


    const onItemClick = (node: any, path: any) => {
        console.log("CLICK", node)
        if(node!=selectedNode){
        setLinkToMap("")
        }
        setSelectedNode(new Location())
        setSelectedNode(node)
        setSelectedPath(path)
        setShowDetails(true)
    }

    const getMainDropdownOptions = () => {
        return IsWsManager(props.activeApp) ? wsmanOpts : covidOpts
    }


    const mainOptTemplate = (option: any) => {
        return (
            <FormattedMessage id={option.label} defaultMessage={option.label} />
        )
    }

    const onMainDropdownChange = (e: any) => {
        switch (e.value) {
            case 'print':
                onAction(props.treeData, null, LUActions.PRINT_ALL)
                break
            case 'import':
                setImportModalOpen(true)
                break
            case 'motivations':
                if (!props.settings.motivations || props.settings.motivations.length === 0) {
                    props.onGetSettings(SettingsType.MOTIVATIONS)
                }
                setMotivModalOpen(true)
                break
        }
    }

    const nodeSelected = () => {
        return selectedNode && selectedNode.available && selectedNode.guid !== ""
    }

    const showDetail = () => {

        let show = false
        if (nodeSelected() && !IsWsManager(props.activeApp)) {
            show = true
        } else if (nodeSelected() && IsWsManager(props.activeApp) && (IsAdminOfApp(props.authState.auth.roles, props.activeApp) || selectedNode.desks_properties.length > 0)) {
            show = true
        }
        return show
    }

    const hideDetail = () => {
        //console.log("Hide Details")
        setShowDetails(false)
    }

    const getTotalSeatsAvailable = (node: any) => {
        let total = 0
        let desks = node.desks_properties
        for (var i in desks) {
            let desk = desks[i]
            total += desk.seats_available
        }

        return total
    }

    const onGetTimezones = (filter: any) => {
        props.getTimezones({ filters: filter })
    }

    return (<div className="_flex h-100">
        <Tab.Pane className={`Location tree-height _flex ${showDetails ? "" : "full"}`}>
            <Dimmer active={props.loading} inverted>
                <Loader size="large">
                    <FormattedMessage id="root.loading.title" defaultMessage="Loading" />
                </Loader>
            </Dimmer>
            {
                props.adminView ?
                    <div className="toolbox _flex justify-content-end" >
                        <Can I="manage" a="Location">
                            <Button
                                btntype="green-bg px-4 flex rounded-input"
                                clicked={() => { onAction(null, null, LUActions.ADD) }} >
                                <FormattedMessage id="location.add" defaultMessage="Add New Location" />
                            </Button>
                        </Can>
                        <Can I="manage" a="Location">
                            <FormattedMessage id="app.generic.more" defaultMessage="More">
                                {(placeholder: string) => (
                                    <PrimeDropdown
                                        key="genericOptions"
                                        placeholder={placeholder}
                                        options={getMainDropdownOptions()}
                                        appendTo={document.body}
                                        className="ml-2"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        itemTemplate={mainOptTemplate}
                                        onChange={onMainDropdownChange}
                                    />
                                )}
                            </FormattedMessage>
                        </Can>
                    </div>
                    :
                    ""
            }

            {
                props.treeData.length > 0 ?
                    <SortableTree
                        className={`${IsWsManager(props.activeApp) ? 'wsman' : ''}`}
                        treeData={props.treeData}
                        onChange={treeData => props.handleChange(treeData)}
                        onMoveNode={onMoveNode}
                        innerStyle={{ display: 'inline-block' }}
                        canNodeHaveChildren={(node: any) => { return nodeEnabled(node) }}
                        canDrag={(node: any) => { return props.adminView && nodeEnabled(node) }}
                        nodeContentRenderer={TreeNode}
                        generateNodeProps={({ node, path }) => ({
                            onClick: (e: any) => { onItemClick(node, path) },
                            className: !nodeEnabled(node) ? 'node_disabled' : '' + selectedNode.guid === node.guid ? 'active' : '',
                            buttons: [
                                <div>

                                    {
                                        props.adminView ?

                                            <Can I="manage" a="Location">
                                                <FormattedMessage id="app.generic.actions" defaultMessage="Actions">
                                                    {(placeholder: string) => (
                                                        <Dropdown text={placeholder} onOpen={() => { onDropdownOpen(node, path) }} >
                                                            < Dropdown.Menu >
                                                                <Dropdown.Item
                                                                    disabled={!nodeEnabled(node)}
                                                                    onClick={() => { onAction(node, path, LUActions.ADD_CHILD) }}>
                                                                    <FormattedMessage id="location.tree.child" defaultMessage="New Child" />
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    disabled={!nodeEnabled(node)}
                                                                    onClick={() => { onAction([node], path, LUActions.PRINT); }}>
                                                                    <FormattedMessage id="location.printQr" defaultMessage="Print Qr" />
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    // disabled={!nodeEnabled(node)}
                                                                    onClick={() => { onAction(node, path, node.available ? LUActions.DISABLE : LUActions.ENABLE) }}>
                                                                    {
                                                                        node.available ?
                                                                            <FormattedMessage id="app.generic.disable" defaultMessage="Disable" />
                                                                            :
                                                                            <FormattedMessage id="app.generic.enable" defaultMessage="Enable" />
                                                                    }
                                                                </Dropdown.Item>
                                                                {(linkToMap!='')? <Dropdown.Item
                                                                    onClick={() => {window.open(linkToMap)}}
                                                                    >
                                                                        <FormattedMessage id="app.generic.download_map" defaultMessage="Download Map"/>
                                                                </Dropdown.Item> :"" }
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item
                                                                    className="delete"
                                                                    disabled={nodeEnabled(node)}
                                                                    onClick={() => { node.deleted = 1; onAction(node, path, LUActions.DELETE) }}>
                                                                    <FormattedMessage id="app.generic.delete" defaultMessage="Delete" />
                                                                    {/* {JSON.stringify(path)} */}
                                                                </Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </FormattedMessage>
                                            </Can>
                                            :
                                            <Can I="create" a="Reservation">
                                                {

                                                    node.desks_properties.length > 0 ?
                                                        <span className="seats-available">
                                                            {getTotalSeatsAvailable(node)} <FormattedMessage id="app.wsmanager.location.detail.seats_total" defaultMessage="Total Seats" />
                                                            {/* <i className="icon-cv19-add"></i> */}
                                                        </span>
                                                        :
                                                        ""
                                                }
                                            </Can>
                                    }
                                </div>

                            ],
                        })}
                    />
                    : !props.loading ?
                        <div className="NoLocation">
                            <p>
                                <FormattedMessage id="location.nolocation" defaultMessage="Add a new location to start managing your workspace" />
                            </p>
                            <Icon onClick={() => { onAction(null, null, LUActions.ADD) }} name="add circle"></Icon>
                        </div>
                        :
                        <div></div>
            }


        </Tab.Pane >

        {
            showDetails ?


                IsWsManager(props.activeApp) ?
                    <div>
                        {
                            props.adminView ?
                                <Can I="manage" a="Location">
                                    <WsManagerLocationDetail
                                        selectedNode={selectedNode}
                                        desks={selectedNode.desks_properties}
                                        selectedPath={selectedPath}
                                        onClearNode={() => { setSelectedNode(new Location()); hideDetail() }}
                                        onSave={(newNode: Location) => { onSave(newNode, true) }}
                                        onPrint={() => { onAction([selectedNode], selectedPath, LUActions.PRINT); }}
                                        onPrintDesk={(data: any) => { onAction(data, selectedPath, LUActions.PRINT); }}
                                        onUpdateDesks={(node: Location) => { onSave(node, true) }}
                                        onDeleteDesk={(node: Location) => { onDelete(node, selectedPath) }}
                                        onSearchTimezones={(filter: any) => { onGetTimezones(filter)} }
                                        timezones={props.timezones}
                                        onCloseDetails={() => hideDetail()}
                                        onMapSubmit={(guid: any, image: any, format: any) => props.onMapSubmit(guid, image, format)}
                                        adminView={props.adminView}
                                        linkToMap={linkToMap}
                                        onError={props.onError}
                                        />
                                </Can>
                                :
                                <Can I="create" a="Reservation">
                                    <LocationReservation
                                        onGetTimetables={props.onGetTimetables}
                                        bookingState={props.bookingState}
                                        selectedNode={selectedNode}
                                        settings={props.settings}
                                        onGetEntrance={() => { props.onGetSettings(SettingsType.ENTRANCE) }}
                                        onGetMotivations={() => { props.onGetSettings(SettingsType.MOTIVATIONS) }}
                                        onGetUsers={props.onGetUsers}
                                        usersState={props.usersState}
                                        authState={props.authState}
                                        onBooking={props.onBooking}
                                        onClearNode={() => { setSelectedNode(new Location()); hideDetail() }}
                                        onCreateReservation={(reservation: BaseReservation) => { }}
                                        onError={props.onError}
                                        onCloseDetails={() => hideDetail()}
                                        linkToMap={linkToMap}
                                    />
                                </Can>
                        }
                    </div>
                    :
                    <Can I="manage" a="Location">
                        <CovidLocationDetail

                            selectedNode={selectedNode}
                            selectedPath={selectedPath}
                            onClearNode={() => { setSelectedNode(new Location()) }}
                            onSave={(newNode: Location) => { onSave(newNode) }}
                            onPrint={() => { onAction([selectedNode], selectedPath, LUActions.PRINT); }}
                        />
                    </Can>

                :
                ""

        }

        <ImportModal
            template={process.env.REACT_APP_LOCATION_TEMPLATE}
            importLoading={props.loading}
            file={undefined}
            headerText="location.import"
            onFileChange={props.onImportFile}
            modalOpen={importModalOpen}
            settings={props.settings}
            onGetActiveTasks={props.onGetActiveTasks}
            onCloseModal={() => { setImportModalOpen(false) }}
            onGetImports={(data: any) => { props.onGetImports(data) }}
            imports={props.imports}
        />
        <MotivationsModal
            motivations={props.settings?.motivations}
            modalOpen={motivModalOpen}
            loading={props.settings.loading}
            onCloseModal={() => setMotivModalOpen(false)}
            onUpdateMotivation={props.onUpdateMotivation}
        />

    </div >
    )


}

export default injectIntl(LocationsTab)