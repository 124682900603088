import { Action } from "./Action";
import { User } from "../User";

export class UserAction extends Action {
  static GET_USERS = "GET_USERS";
  static CREATE_USER = "CREATE_USER";
  static DELETE_USER = "DELETE_USER";
  static UPDATE_USER = "UPDATE_USER";
  static UPLOAD_USERS = "UPLOAD_USERS";
  static GENERIC_USER_REQUEST = "GENERIC_USER_REQUEST";
  static USER_REQUEST = "USER_REQUEST";
  static USER_ERROR = "USER_ERROR";
  static ROLES_SUCCESS = "ROLES_SUCCESS";

  response: User[];

  constructor(type: string, response: User[]) {
    super(type, response);
    this.response = response;
  }

  static GenericUserRequest = () => {
    return new UserAction(UserAction.GENERIC_USER_REQUEST, []).onRequest();
  };

  static onRoleSuccess = (roles: any[]) => {
    return new UserAction(UserAction.ROLES_SUCCESS, roles).toJSON();
  };

  static UserRequest = (user: User, users: User[]) => {
    let data: User[] = [];
    if (user.guid) {
      users = UserAction.setUserLoading(user, users, true);
    }
    data = users;
    return new UserAction(UserAction.USER_REQUEST, data).onRequest();
  };

  static UsersRequest = (loading: User[], users: User[]) => {
    let data: User[] = [];

    for (var i in loading) {
      let user = loading[i];
      if (user.guid) {
        users = UserAction.setUserLoading(user, users, true);
      }
    }

    data = users;
    return new UserAction(UserAction.USER_REQUEST, data).onRequest();
  };

  static onGetUsersSuccess = (response: any) => {
    let data: User[] = [];

    for (var i in response.items) {
      let user: User = new User().set(response.items[i]).setFullname();
      user.loading = false;
      data.push(user);
    }
    response.items = data;
    
    
    return new UserAction(UserAction.GET_USERS, response).onRequest();
  };

  static onCreateUserSuccess = (user: User, users: User[]) => {
    let data: User[] = [];
    user.setLoading(false);
    users.unshift(user);
    data = users;
    return new UserAction(UserAction.CREATE_USER, data).onRequest();
  };

  static onUpdateUserSuccess = (user: User, users: User[]) => {
    let data: User[] = [];
    data = UserAction.setUserLoading(user, users, false);
    return new UserAction(UserAction.UPDATE_USER, data).onRequest();
  };

  static onDeleteUserSuccess = (user: User, users: User[]) => {
    let data: User[] = [];
    let index: any = -1;
    for (var i in users) {
      if (users[i].guid === user.guid) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      users.splice(index, 1);
    }
    data = users;
    return new UserAction(UserAction.DELETE_USER, data).onRequest();
  };
  static onUploadUsersSuccess() {
    return new UserAction(UserAction.UPLOAD_USERS, []).onRequest();
  }

  static onUserError = (user?: User, users?: User[]) => {
    let data: User[] = [];

    if (user && users) {
      data = UserAction.setUserLoading(user, users, false);
    }
    return new UserAction(UserAction.USER_ERROR, data).onRequest();
  };

  static setUserLoading = (user: User, users: User[], loading: boolean) => {
    for (var i in users) {
      if (users[i].guid === user.guid) {
        user.loading = loading;
        users[i] = user;
        break;
      }
    }
    return users;
  };
}
