import { BaseModel } from "./Base";

export class BaseUser extends BaseModel {
  guid: string;
  email: string;
  password_hash: string;
  name: string;
  surname: string;
  fiscal_code: string;
  locale: string;
  company_code: string;
  user_type: string;
  password: string;
  roles: any[];

  constructor(data?: any) {
    super();
    this.guid = "";
    this.password = "";
    this.password_hash = "";
    this.email = "";
    this.name = "";
    this.surname = "";
    this.fiscal_code = "";
    this.company_code = "";
    this.user_type = "";
    this.locale = "en_EN";
    this.roles = [];
    if (data) {
      this.set(data);
    }
  }
}

export class User extends BaseUser {
  static COMMON_USER: string = "common_user";
  static ADMIN: string = "admin";
  static MODERATOR: string = "approver";
  static BOOKER: string = "booker";
  static MOBILE_USER: string = "mobile_user";

  access_token: string;
  language: string;
  loading: boolean;
  fullname: string;
  selectedCompany:string;
  companies:any[]

  constructor(data?: any) {
    super(data);

    this.fullname = "";
    this.access_token = "";
    this.language = "";
    this.loading = false;
    this.selectedCompany = ''
    this.companies = []

    if (data) {
      this.set(data);
      this.selectedCompany = this.company_code
    }
  }

  setFullname() {
    this.fullname = this.name + " " + this.surname;
    return this;
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setSelectedCompany = (company_code:string) => {
    this.selectedCompany = company_code
  }

  setCompanies = (companies:any[]) => {
    this.companies = companies
  }
}

const IsRoleOfApp = (apps: any[], app: string, role: string) => {
  for (var i in apps) {
    if (apps[i].app === app) {
      for (var x in apps[i].roles) {
        if (apps[i].roles[x] === role) {
          return true;
        }
      }
    }
  }
  return false;
};

export const IsAdmin = (roles: any[]) => {
  for (var i in roles) {
    if (roles[i] === User.ADMIN) {
      return true;
    }
  }
  return false;
};

export const IsAdminOfApp = (apps: any[], app: string) => {
  return IsRoleOfApp(apps, app, User.ADMIN);
};
export const IsModeratorOfApp = (apps: any[], app: string) => {
  return IsRoleOfApp(apps, app, User.MODERATOR);
};

export const IsBookerOfApp = (apps: any[], app: string) => {
  return IsRoleOfApp(apps, app, User.BOOKER);
};

export const IsMobileUserOfApp = (apps: any[], app: string) => {
  return IsRoleOfApp(apps, app, User.MOBILE_USER);
};
