import {
  GET_AVAILABLE_LOCATION,
  GET_PARENT_LOCATION,
  REQUEST_PARENT_LOCATION,
  REQUEST_AVAILABLE_LOCATION,
  GET_MAP_LOCATION,
  REQUEST_MAP_LOCATION,

} from "../../actions/backoffice/locationV2";

export default (state = {}, action: any) => {
  switch (action.type) {
    case GET_MAP_LOCATION:
      return {
        ...state,
        mapLocation: action.res,
        closeModal: true,
        loading: true,
      };
    case GET_AVAILABLE_LOCATION:
      return {
        ...state,
        listLocation: action.res,
        closeModal: true,
        loading: true,
      };
    case GET_PARENT_LOCATION:
      return {
        ...state,
        listParentLocation: action.res,
        closeModal: true,
        loading: true,
      };
    case REQUEST_MAP_LOCATION:
      return {
        ...state,
        closeModal: "loadingMapLocation",
        loading: true,
      };
    case REQUEST_AVAILABLE_LOCATION:
      return {
        ...state,
        closeModal: "loadingAvailableDesks",
        loading: true,
      };
    case REQUEST_PARENT_LOCATION:
      return {
        ...state,
        closeModal: "loadingParentLocation",
        loading: true,
      };
    default:
      return state;
  }
};
