import React, { useState, useEffect } from 'react'
import { Tab, Dropdown } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button as SUIButton } from 'semantic-ui-react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import Button from '../../../../UI/Button/Button'

import './Reports.scss';
import { MultiSelect } from 'primereact/multiselect';
import { IntlContext } from '../../../../../utils/IntlContext';
import { GetDateFormatByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IsWsManager } from '../../../../../interfaces/SwitchState';
import {useMediaQuery} from 'react-responsive';

const ReportsTab = (props: any) => {
  const { locale, calendar } = React.useContext(IntlContext);

  const [startDate, setStartDate] = useState<any>(moment().startOf('day').subtract(3, 'months').format('YYYY-MM-DD').split("T")[0])
  const [endDate, setEndDate] = useState<any>(moment().endOf('day').format('YYYY-MM-DD').split("T")[0])
  const [range, setRange] = useState([moment(new Date(), GetDateFormatByLocale(locale)).startOf('day').subtract(3, 'months').toDate(), moment().endOf('day').toDate()])
  const [table, setTable] = useState<DataTable | undefined>()
  const [locations, setLocations] = useState([])
  const { formatMessage } = props.intl;
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  useEffect(() => {

    
    if (enableSearchButton() && IsWsManager(props.activeApp)) {
      props.onGetData(locations, startDate, endDate)
    }

  }, [props.auth?.selectedCompany])

  const onSearch = () => {
    props.onGetData(locations, startDate, endDate)
    //console.log(locations)

  }
  const setDates = (e: any) => {
    //console.log("RANGE", e)
    setRange(e.value)

    if (e.value.length > 1) {
      let tmp: any = e.value[0]
      setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])
      let tmp2: any = e.value[1]
      if (tmp2) {
        //console.log("TMP", tmp, tmp2)
        setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])
      } else {
        setEndDate(undefined)
      }

    }
  }

  const enableSearchButton = () => {
    return endDate && startDate
  }

  const onExport = () => {
    props.onExportData({locations: locations, startDate: startDate, endDate: endDate});
  }

  const headerName = (field: string) => {
    return formatMessage({ id: "reports." + field })
  }
  const hoursTemplate = (rowData: any, column: any) => {
    if (!rowData.users_period_confirmed) return ''
    let hours: number = parseFloat(rowData.users_period_confirmed)
    return isNaN(hours) ? rowData.users_period_confirmed : hours.toFixed(2)
  }

  const percentageTemplate = (rowData: any, column: any) => {
    //console.log("column", column)
    if (rowData[column.field] === undefined || rowData[column.field] === null) return ''
    let tmp: number = parseFloat(rowData[column.field])
    return isNaN(tmp) ? rowData[column.field] : tmp === 0 ? tmp : tmp.toFixed(2) + "%"
  }



  return (
    <Tab.Pane className="Reports">
      <div>
        <div className="_flex flex-direction-row justify-content-between my-3">

          <div className={(isMobile) ? "wrapped" : "_flex"}>
            <FormattedMessage id="reports.locations.select" defaultMessage="Search Location">
              {(placeholder: string) => (
                <MultiSelect
                  className="my-2 rounded-input w-100"
                  appendTo={document.body}
                  value={locations}
                  options={props.locations}
                  onChange={(e) => { setLocations(e.value) }}
                  optionLabel="name"
                  optionValue="guid"
                  placeholder={placeholder}
                  maxSelectedLabels={1}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates">
              {(placeholder: string) => (
                <Calendar selectionMode="range" dateFormat={GetCalendarDateFormatByLocale(locale)} className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} value={range} locale={calendar} onChange={(e: any) => setDates(e)}></Calendar>
              )}
            </FormattedMessage>
            <Button
              btntype="OK green-bg px-4 w-100 rounded-input"
              clicked={onSearch}
              loading={props.loading}
              disabled={!enableSearchButton()}>
              <FormattedMessage id="app.generic.search" defaultMessage="Search" />
            </Button>

          </div>

          <SUIButton onClick={onExport} basic loading={props.loading_export} exportLoading={props.exportLoading}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>


        </div>
        {
          IsWsManager(props.activeApp) ?

            <DataTable value={props.data} sortMode="multiple" paginator={true} rows={10} autoLayout loading={props.loading} id="biohazard-table" ref={(el: any) => { setTable(el); }}>
              <Column field="name" header={headerName('name')} sortable={true} />
              <Column field="seats_percentage_busy_confirmed" header={headerName('seats_percentage_busy_confirmed')} body={percentageTemplate} sortable={true} />
              <Column field="seats_period_percentage_busy_confirmed" header={headerName('seats_period_percentage_busy_confirmed')} body={percentageTemplate} sortable={true} />
              <Column field="seats_period_busy_confirmed" header={headerName('seats_period_busy_confirmed')} sortable={true} />
              <Column field="seats_available" header={headerName('seats_available')} sortable={true} />
              <Column field="seats_busy_confirmed" header={headerName('seats_busy_confirmed')} sortable={true} />
            </DataTable>

            :

            <DataTable value={props.data} sortMode="multiple" paginator={true} rows={10} autoLayout loading={props.loading} id="biohazard-table" ref={(el: any) => { setTable(el); }}>
              <Column field="name" header={headerName('name')} sortable={true} />
              <Column field="users_period_confirmed" header={headerName('users_period_confirmed')} body={hoursTemplate} sortable={true} />
              <Column field="users_confirmed" header={headerName('users_confirmed')} sortable={true} />
            </DataTable>



        }
      </div>
    </Tab.Pane >
  )
}

export default injectIntl(ReportsTab)