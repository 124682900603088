import { Action } from "./Action";

export class ReportAction extends Action {
  static ON_GENERIC_REPORT_REQUEST: string = "ON_GENERIC_REPORT_REQUEST";
  static ON_GENERIC_REPORT_SUCCESS: string = "ON_GENERIC_REPORT_SUCCESS";
  static ON_GENERIC_REPORT_ERROR: string = "ON_GENERIC_REPORT_ERROR";
  static ON_GENERIC_REPORT_EXPORT_REQUEST: string = "ON_GENERIC_REPORT_EXPORT_REQUEST";
  static ON_GENERIC_REPORT_EXPORT_SUCCESS: string = "ON_GENERIC_REPORT_EXPORT_SUCCESS";

  constructor(type: string, response: any) {
    super(type, response);
    this.response = response;
  }

  static OnGenericRequest = () => {
    return new ReportAction(ReportAction.ON_GENERIC_REPORT_REQUEST, {}).toJSON();
  };

  static OnGenericSuccess = (data?: any) => {
    return new ReportAction(ReportAction.ON_GENERIC_REPORT_SUCCESS, data).toJSON();
  };

  static OnGenericError = () => {
    return new ReportAction(ReportAction.ON_GENERIC_REPORT_ERROR, {}).toJSON();
  };

  static OnGenericExportRequest = () => {
    return new ReportAction(ReportAction.ON_GENERIC_REPORT_EXPORT_REQUEST, {}).toJSON();
  };

  static OnGenericExportSuccess = (data?: any) => {
    return new ReportAction(ReportAction.ON_GENERIC_REPORT_EXPORT_SUCCESS, data).toJSON();
  };
}
