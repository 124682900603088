import {
    REQUEST_FAILED,
    REQUEST_DATA,
    USERS_LIST_DONE,
    CHECK_IN_DONE,
    REQUEST_DATA_BLOCK,
    USER_INFO_DONE,
    CERTIFICATION_LIST_DONE,
    ACCEPT_CERTIFICATION,
    IS_CERTIFICATION_DONE,
    CONFIGURATION_DONE
} from "../../actions/tracker/users"

let defaultState = {
    state: "NO_DATA",
    loading: false,
    loadingFullScreen: false,
    usersList: [],
    checkin: null,
    userInfo: null,
    certificationList: null,
    acceptCertification: null
};

export default (state = defaultState, action: any) => {
    switch (action.type) {
        case REQUEST_FAILED:
            return {
                ...state,
                state: "NO_DATA",
                loading: false,
                loadingFullScreen: false,
                checkin: null,
                userInfo: null,
                certificationList: null
            };
        case REQUEST_DATA:
            return {
                ...state,
                state: "NO_DATA",
                loading: true,
                usersList: [],
                checkin: null,
                userInfo: null,
                certificationList: null
            };
        case REQUEST_DATA_BLOCK:
            return {
                ...state,
                state: "NO_DATA",
                loadingFullScreen: true,
                checkin: null,
                userInfo: null,
                certificationList: null
            };
        case USERS_LIST_DONE:
            return {
                ...state,
                state: "USER_LIST_RECEIVED",
                loading: false,
                usersList: action.userList,
                checkin: null,
                userInfo: null,
                certificationList: null
            };
        case CHECK_IN_DONE:
            return {
                ...state,
                state: "CHECK_IN_DONE",
                loadingFullScreen: false,
                loading: false,
                checkin: action.response,
                userInfo: null,
                usersList: null,
                certificationList: null
            }
        case USER_INFO_DONE:
            return {
                ...state,
                state: "CHECK_IN_DONE",
                loadingFullScreen: false,
                checkin: action.response,
                usersList: null,
                userInfo: action.USERINFO,
                certificationList: null
            }
        case CERTIFICATION_LIST_DONE:
            return {
                ...state,
                state: "CERTIFICATION_LIST_DONE",
                loadingFullScreen: false,
                loading: false,
                checkin: null,
                userInfo: null,
                usersList: null,
                certificationList: action.response
            }
        case ACCEPT_CERTIFICATION:
            console.log("ACC", action.response)
            return {
                ...state,
                state: "ACCEPT_CERTIFICATION",
                loadingFullScreen: false,
                loading: false,
                checkin: null,
                userInfo: null,
                usersList: null,
                acceptCertification: action.response
            }
        case IS_CERTIFICATION_DONE:
            return {
                ...state,
                state: "IS_CERTIFICATION_DONE",
                loadingFullScreen: false,
                loading: false,
                checkin: null,
                userInfo: null,
                usersList: null,
                isCertificationDone: action.response
            }
        case CONFIGURATION_DONE:
            return {
                ...state,
                state: "CONFIGURATION_DONE",
                loadingFullScreen: false,
                loading: false,
                checkin: null,
                userInfo: null,
                usersList: null,
                configurationDone: action.response
            }
            return
        default:
            return state;
    }
};