import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_it from 'react-intl/locale-data/it';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import messages_it from './localization/it.json';
import messages_en from './localization/en.json';
import messages_es from './localization/es.json';
import { isSupportedLanguage, SET_CALENDAR_LOCALE, CALENDAR_EN } from './localization/supported-languages';

addLocaleData([...locale_en, ...locale_it, ...locale_es]);

interface LocaleState {
    locale: any
    messages: any
    switchToLanguage: any
    textComponent: any,
    calendar: any
}


let Context = React.createContext({
    switchToLanguage: (locale: string) => { },
    locale: "en",
    calendar: CALENDAR_EN
})

class IntlProviderWrapper extends React.Component<{}, LocaleState> {
    constructor(args: any) {
        super(args);
        let userLang = "en"
        let messages = messages_en
        let calendar = CALENDAR_EN
        try {
            userLang = navigator.language.split("-")[0]
            let supported = isSupportedLanguage(userLang)
            if (!supported) {
                userLang = 'en'
            }
            messages = this.setMessages(userLang)
            console.log("LANGUAGE", userLang)
        } catch (error) {
            userLang = "en"
            messages = messages_en
        }

        calendar = SET_CALENDAR_LOCALE(userLang)

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: userLang,
            messages: messages,
            switchToLanguage: this.switchToLanguage,
            textComponent: React.Fragment,
            calendar: calendar,
        };

        // Context = React.createContext(this.state)

    }

    setMessages = (locale: string) => {
        
        switch (locale) {
            case 'en':
                return messages_en
            case 'es':
                return messages_es
            case 'it':
                return messages_it
            default:
                return messages_en
        }
    }

    switchToLanguage = (locale: string) => {
        let calendar: any = SET_CALENDAR_LOCALE(locale)
        this.setState({ ...this.state, locale: locale, messages: this.setMessages(locale), calendar: calendar });

    };


    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        // console.log("NEW LOCALE", locale, messages)
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                    textComponent={React.Fragment}
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext }
